import { useState } from "react";

// Material UI
import Alert from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import SearchResultsTable from "./SearchResultsTable";

const headCells = [
  { label: "Partner", key: "partnerName", width: "15%" },
  { label: "Catalogue Item", key: "catalogueItemName", width: "25%" },
  { label: "Start Date", key: "startDate", width: "9.25em" },
  { label: "Duration", key: "cohortLength" },
  { label: "Status", key: "status", width: "12%" },
  { label: "Enrollments", key: "redemptionCount" },
];

const Results = function ({ data = [], handleAssign }) {
  const theme = useTheme();
  const [sortBy, setSortBy] = useState("startDate");
  const [ascending, setAscending] = useState(true);

  if (data?.length < 1)
    return (
      <Alert sx={{ mt: 1 }} severity="error">
        No results
      </Alert>
    );

  function changeSort(key) {
    if (sortBy === key) {
      setAscending((a) => !a);
    } else {
      setSortBy(key);
      setAscending(true);
    }
    return;
  }

  const sortedData = data.sort((a, b) => {
    let result = 0;
    if (a[sortBy] === b[sortBy]) {
      result = a.cohortLength > b.cohortLength ? 1 : -1;
    } else {
      result = a[sortBy] > b[sortBy] ? 1 : -1;
    }
    return ascending ? result : result * -1;
  });

  if (!sortedData?.length) {
    return null;
  }

  return (
    <Table size="small" sx={{ mt: 2 }}>
      <TableHead>
        <TableRow>
          {headCells.map((headCell, i) => (
            <TableCell
              key={i}
              align={headCell.label === "Enrollments" ? "center" : "left"}
              sx={{ width: headCell.width || "auto" }}
            >
              <TableSortLabel
                active={sortBy === headCell.key}
                direction={
                  sortBy !== headCell.key || ascending ? "asc" : "desc"
                }
                onClick={() => changeSort(headCell.key)}
                sx={{
                  fontSize: "1.1em",
                  width: "100%",
                  "&.Mui-active": {
                    position: "relative",
                  },
                  "&.Mui-active > *": {
                    position: "relative",
                    zIndex: 2,
                  },
                  "&.Mui-active:after": {
                    content: '""',
                    position: "absolute",
                    inset: "-2px -6px",
                    zIndex: 1,
                    backgroundColor: "#eee",
                    ...theme.applyStyles("dark", {
                      backgroundColor: "#333",
                    }),
                    borderRadius: "6px",
                  },
                }}
              >
                <span>{headCell.label}</span>
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell
            sx={{
              fontSize: "1.1em",
            }}
          >
            Mentors
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <SearchResultsTable
        inventoryItems={sortedData}
        handleAssign={handleAssign}
      />
    </Table>
  );
};

export default Results;
