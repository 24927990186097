import { centsToPrice, priceToCents } from "@/lib/helpers";
import requestOptions from "@/lib/request-options";

import routes from "./routes";

import type { TCatalogueItem } from "./types";

/* Helpers
/* ------------------------------------------------------------------ */

function formatForServer(data: TCatalogueItem) {
  return {
    ...data,
    msrp: priceToCents(data.msrp),
  };
}

function formatForClient(data: TCatalogueItem): TCatalogueItem {
  return {
    ...data,
    msrp: centsToPrice(data.msrp),
  };
}

function propertiesOrDefaults(data: Partial<TCatalogueItem>) {
  return {
    ...data,
    metadata: data.metadata || null,
    msrp: data.msrp || "100.00",
    type: data.type || "program",
  };
}

/* Finders
/* ------------------------------------------------------------------ */

const findAllByPartnerId = {
  queryFn: async function (id: string) {
    const url = routes.items(id);
    const resp = await window.auth.client.get(url).json<TCatalogueItem[]>();
    return resp.map(formatForClient);
  },
  queryKey(id: string) {
    return ["catalogue", id] as const;
  },
};

const findById = {
  queryFn: async function (id: string) {
    const url = routes.show(id);
    const resp = await window.auth.client.get(url).json<TCatalogueItem>();
    return formatForClient(resp);
  },
  queryKey(id: string) {
    return ["catalogue", id] as const;
  },
};

/* Actions
/* ------------------------------------------------------------------ */

async function saveItem(data: TCatalogueItem) {
  const { method, url } = requestOptions(routes, data._id);

  const resp = await window.auth.client[method](url, {
    json: formatForServer(data),
  }).json<TCatalogueItem>();

  return formatForClient(resp);
}

export default {
  findAllByPartnerId,
  findById,
  propertiesOrDefaults,
  saveItem,
};
