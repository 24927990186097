import { useLogin } from "@kaplan-labs/up-auth-api-client";
import { useEffect, useRef } from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CachedIcon from "@mui/icons-material/Cached";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import clientRoutes from "@/lib/client-routes";

export default function Login() {
  const emailRef = useRef(null);
  const {
    activeField,
    email,
    emailErrorMessage,
    isLoading,
    otpErrorMessage,
    onSubmit,
    onChange,
    reset,
  } = useLogin({
    onSuccess($auth) {
      const uri = $auth.uriBeforeFailure;
      window.location.href =
        !uri || uri.match(/login/) ? clientRoutes.inventorySearch.index() : uri;
    },
  });

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  const FF =
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';

  return (
    <div className="animation-fade-in-slow" style={{ opacity: 0 }}>
      <Box
        sx={{
          position: "absolute",
          inset: "0",
          overflow: "hidden",
        }}
      >
        <Box
          className="rays"
          sx={{
            position: "absolute",
            opacity: 0.5,
            inset: "-10px",
          }}
        />
      </Box>
      <Box className="login-form" as="form" onSubmit={onSubmit}>
        <Box
          as="div"
          sx={{
            position: "relative",
            flexDirection: "column",
            justifyContent: "center",
            zIndex: 2,
            padding: "2rem",
            width: "420px",
          }}
        >
          <Typography
            sx={{
              mb: 3,
              letterSpacing: "-0.035rem",
              fontFamily: FF,
            }}
            variant="h4"
          >
            Welcome to Back Office
          </Typography>
          {activeField === "email" && (
            <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
              <input
                type="email"
                name="email"
                placeholder="Email address"
                onChange={onChange}
                ref={emailRef}
              />
              <LoadingButton
                color="inherit"
                loading={isLoading}
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(255,255,255, 0.8)",
                    boxShadow: "none",
                  },
                  color: "rgb(8, 77, 66)",
                  fontFamily: FF,
                  backgroundColor: "rgba(255,255,255, 0.8)",
                }}
              >
                <ArrowForwardIcon />
              </LoadingButton>
            </Box>
          )}

          {activeField === "otp" ? (
            <>
              <div
                className="animation-fade-in"
                style={{
                  fontFamily: FF,
                }}
              >
                Check your email for a single-use password
              </div>
              <div
                className="animation-fade-in"
                style={{
                  fontFamily: FF,
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "2rem",
                  gap: "0.75rem",
                }}
              >
                {email}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    reset();
                  }}
                  type="button"
                  style={{
                    border: 0,
                    backgroundColor: "transparent",
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "inherit",
                    fontFamily: FF,
                    fontSize: "0.8rem",
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "0.25rem",
                    opacity: 0.75,
                  }}
                >
                  <CachedIcon />
                  restart login
                </button>
              </div>
              <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
                <input
                  disabled={activeField !== "otp"}
                  type="text"
                  name="otp"
                  placeholder="Single-use password"
                  onChange={onChange}
                />
                <LoadingButton
                  color="inherit"
                  loading={isLoading}
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(255,255,255, 0.8)",
                      boxShadow: "none",
                    },
                    color: "rgb(8, 77, 66)",
                    fontFamily: FF,
                    backgroundColor: "rgba(255,255,255, 0.8)",
                  }}
                >
                  <ArrowForwardIcon />
                </LoadingButton>
              </Box>
            </>
          ) : null}

          <input type="hidden" name="domain" value={window.location.href} />

          <div>
            {emailErrorMessage ? (
              <div className="animation-fade-in">{emailErrorMessage}</div>
            ) : null}
            {otpErrorMessage ? (
              <div className="animation-fade-in">
                {otpErrorMessage}. Please try again.
              </div>
            ) : null}
          </div>
        </Box>
      </Box>
    </div>
  );
}
