import { useQuery } from "@tanstack/react-query";

import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import EditIcon from "@mui/icons-material/Edit";
import MoveUp from "@mui/icons-material/MoveUp";
import Publish from "@mui/icons-material/Publish";
import { Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stack from "@mui/material/Stack";

import Inventory from "@/models/inventory";
import { InventoryStatus } from "@/models/inventory/constants";

import type { TInventory } from "@/models/inventory/types";

const isPublished = function (status: TInventory["status"]) {
  return (
    status !== InventoryStatus.PreMarket && status !== InventoryStatus.Canceled
  );
};

const ButtonStack = ({
  inventoryItem,
  handleCancel,
  setOpenDialog,
  handleEdit,
  courseSections,
}) => {
  const courseJob = useQuery({
    queryKey: Inventory.findCourseJob.queryKey(inventoryItem.data._id),
    queryFn() {
      return Inventory.findCourseJob.queryFn(inventoryItem.data._id);
    },
  });

  const CanvasButton = ({ disabled = null }) => (
    <Button
      color="secondary"
      onClick={() => setOpenDialog("migrate")}
      startIcon={<MoveUp />}
      variant="outlined"
      disabled={disabled}
    >
      Create in Canvas
    </Button>
  );
  return (
    <Stack sx={{ pt: 1 }} spacing={1} direction="row">
      <ButtonGroup variant="outlined">
        <Button
          disabled={inventoryItem.data.status === InventoryStatus.Canceled}
          color="secondary"
          onClick={() => {
            handleEdit(inventoryItem.data);
          }}
          startIcon={<EditIcon />}
          variant="outlined"
        >
          Edit
        </Button>
        <Button
          color="secondary"
          disabled={inventoryItem.data.status === InventoryStatus.Canceled}
          onClick={handleCancel}
          startIcon={<DoDisturbIcon />}
          variant="outlined"
        >
          Cancel
        </Button>
        {inventoryItem.data.status === "preMarket" ? (
          <Button
            color="secondary"
            onClick={() => setOpenDialog("promote")}
            startIcon={<Publish />}
            variant="outlined"
          >
            Promote to Market
          </Button>
        ) : null}
        {isPublished(inventoryItem.data.status) ? (
          courseSections.isError && courseJob.isError ? (
            <CanvasButton />
          ) : (
            <Tooltip
              title={
                courseSections.isLoading || courseJob.isLoading
                  ? "Fetching Canvas information, please wait..."
                  : "Canvas course or job already created"
              }
            >
              <div>
                <CanvasButton disabled={true} />
              </div>
            </Tooltip>
          )
        ) : null}
      </ButtonGroup>
      {courseJob.isSuccess &&
      courseJob.data?.migrationStatus !== "completed" ? (
        <span style={{ fontSize: ".8rem", alignSelf: "center" }}>
          Migration job is currently: {courseJob.data?.migrationStatus}
        </span>
      ) : null}
    </Stack>
  );
};

export default ButtonStack;
