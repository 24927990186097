import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

import Heading from "../Heading";

const StudentNameAndContact = ({ student }) => {
  return (
    <>
      <Typography component="h3" variant="h6" sx={{ mb: 2 }}>
        Student Name
      </Typography>
      <Grid container spacing={4}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Heading>First Name</Heading>
          {student.firstName}
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Heading>Middle Name</Heading>
          {student.middleName ?? "—"}
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Heading>Last Name</Heading>
          {student.lastName}
        </Grid>
      </Grid>

      <br />
      <br />

      <Typography component="h3" variant="h6" sx={{ mb: 2 }}>
        Student Contact
      </Typography>

      <Grid container spacing={4}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Heading>Student Email</Heading>
          {student.email}
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Heading>Student Phone Number</Heading>
          {student.phone}
        </Grid>
      </Grid>

      <br />
      <br />
    </>
  );
};

export default StudentNameAndContact;
