import { useQuery } from "@tanstack/react-query";

import Box from "@mui/material/Box";

import { Drawer, DrawerManager } from "@/components/Drawer";
import { UPTimeline } from "@/components/UPTimeline";
import Scholarship from "@/models/scholarship";

export const ApplicationTimelineDrawer = DrawerManager.create(function ({
  email,
  partnerId,
}: {
  email: string;
  partnerId: string;
}) {
  const timelineQuery = useQuery({
    queryKey: ["timeline", "application", email, partnerId],
    queryFn() {
      return Scholarship.timeline(email, partnerId);
    },
  });

  return (
    <Drawer loading={timelineQuery.isLoading} title="Timeline">
      <Box sx={{ p: 2, bgcolor: "background.paper" }}>
        <UPTimeline timelineQuery={timelineQuery} />
      </Box>
    </Drawer>
  );
});
