import Skeleton from "@mui/material/Skeleton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

function DeleteIcon() {
  return (
    <Skeleton
      animation={false}
      variant="circular"
      width={22}
      height={22}
      sx={{ float: "right" }}
    />
  );
}

export default function MentorsTableEmptyContent({ animation = false }) {
  return (
    <>
      <TableRow aria-hidden>
        <TableCell sx={{ width: "50%" }}>
          <Skeleton animation={animation} sx={{ width: "50%" }} />
          <Skeleton animation={animation} sx={{ width: "100%" }} />
        </TableCell>
        <TableCell padding="checkbox" sx={{ width: "50px" }}>
          <DeleteIcon />
        </TableCell>
      </TableRow>
    </>
  );
}
