import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { INVENTORY_STATUS_OPTIONS } from "@/constants";

import type { SelectProps } from "@mui/material/Select";

export default function StatusSelect({ value = "", ...rest }: SelectProps) {
  return (
    <FormControl>
      <InputLabel id="status-label">Status</InputLabel>
      <Select
        label="Status"
        labelId="status-label"
        defaultValue={value ?? ""}
        name="status"
        value={value}
        sx={{ width: "100%" }}
        {...rest}
      >
        {INVENTORY_STATUS_OPTIONS.map((stat) => {
          return (
            <MenuItem key={stat.label} value={stat.value}>
              {stat.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
