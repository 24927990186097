import Typography from "@mui/material/Typography";

import Coupon from "./Coupon";

export default function CouponList({ profileData }) {
  return (
    <>
      {profileData && Boolean(profileData.coupons?.length) ? (
        profileData.coupons.map((couponID, index) => {
          return <Coupon key={index} couponID={couponID} />;
        })
      ) : (
        <Typography display="block" variant="body1">
          <em>No coupons</em>
        </Typography>
      )}
    </>
  );
}
