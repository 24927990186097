import { LinearProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";

import GetMiniSearchedData from "@/lib/get-minisearched-data";
import { decorateSearchResults, flattenSearchResults } from "@/lib/helpers";

import Results from "./Results";

const Status = function ({ text }) {
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        borderBottomRightRadius: "0.5rem",
        borderBottomLeftRadius: "0.5rem",
        padding: 2,
        textAlign: "center",
      }}
    >
      {text}
    </Box>
  );
};

/**
 * @typedef {{
 *    catalogueItem: {
 *      name: string,
 *    }
 * }} SearchData
 *
 * @typedef {{
 *    close: () => void,
 *    search: {
 *      data: SearchData,
 *    }
 *  }
 * }} SearchResultsProps
 *
 * @param {SearchResultsProps} param0
 * @returns {React.Component}
 */
const SearchResults = function ({ handleAssign, search, queryParams }) {
  const LOADING = search.status === "pending";
  const FETCHING = search.isFetching || search.hasNextPage;
  const decoratedData = LOADING
    ? []
    : GetMiniSearchedData(
        decorateSearchResults(flattenSearchResults(search)),
        queryParams,
      );
  const HAS_RESULTS = decoratedData.length > 0;

  return (
    <>
      {LOADING || (FETCHING && !HAS_RESULTS) ? (
        <LinearProgress color="secondary" />
      ) : null}

      {search.isError && !search.hasNextPage ? (
        <Status text={`⚠️ ${search.error.message}`} />
      ) : null}
      {!FETCHING && !HAS_RESULTS ? (
        <Alert sx={{ mt: 1 }} severity="error">
          No results.
        </Alert>
      ) : null}
      {HAS_RESULTS && (
        <Results data={decoratedData} handleAssign={handleAssign} />
      )}
    </>
  );
};

export default SearchResults;
