const BASE = import.meta.env.VITE_API_ENDPOINT;

const routes = {
  items(partnerId: string) {
    return `${BASE}/catalogue/partner/${partnerId}`;
  },

  post() {
    return `${BASE}/catalogue/items`;
  },

  show(id: string) {
    return `${BASE}/catalogue/items/${id}`;
  },

  patch(id: string) {
    return `${BASE}/catalogue/items/${id}`;
  },
} as const;

export default routes;
