import createDate from "@/lib/create-date";
import { centsToPrice, priceToCents } from "@/lib/helpers";
import requestOptions from "@/lib/request-options";

import { CouponAmountType, CouponType } from "./constants";
import routes from "./routes";

import type { TCoupon } from "./types";

/* Helpers
/* ------------------------------------------------------------------ */

function formatForServer(data: TCoupon) {
  return {
    ...data,
    amount:
      data.amountType === CouponAmountType.Currency
        ? priceToCents(data.amount)
        : data.amount,
    maxUses: data.maxUses || 0,
  };
}

function formatForClient(data: TCoupon): TCoupon {
  return {
    ...data,
    amount:
      data.amountType === CouponAmountType.Currency
        ? centsToPrice(data.amount)
        : data.amount,
  };
}

function propertiesOrDefaults(data: Partial<TCoupon>) {
  return {
    ...data,
    code: data.code || "",
    hasCustomCode: data.hasCustomCode ?? true,
    type: data.type || CouponType.Promotional,
    amount: data.amount || "10",
    amountType: data.amountType || CouponAmountType.Percentage,
    expiration: data.expiration || createDate(0, 0, 1),
    maxUses: data.maxUses || "",
    partner: data.partner || {},
    scopedItems: data.scopedItems || [],
    scopedUsers: data.scopedUsers || [],
  };
}

/* Finders
/* ------------------------------------------------------------------ */

const findById = {
  queryFn: async function (id: string) {
    const url = routes.show(id);
    const resp = await window.auth.client.get(url).json<TCoupon>();
    return formatForClient(resp);
  },
  queryKey(id: string) {
    return ["coupon", id] as const;
  },
};

const findAll = {
  queryFn: async function () {
    const url = routes.index();
    const resp = await window.auth.client.get(url).json<TCoupon[]>();
    return resp.map(formatForClient);
  },
  queryKey() {
    return ["coupons"] as const;
  },
};

/* Actions
/* ------------------------------------------------------------------ */

export async function createCoupon(data: TCoupon) {
  const url = routes.post();
  const resp = await window.auth.client
    .post(url, {
      json: formatForServer(data),
    })
    .json<TCoupon>();
  return formatForClient(resp);
}

export async function saveCoupon(data: TCoupon) {
  const { method, url } = requestOptions(routes, data.code);
  const resp = await window.auth.client[method](url, {
    json: formatForServer(data),
  }).json<TCoupon>();
  return formatForClient(resp);
}

const deleteCoupon = async function (id) {
  const url = routes.delete(id);
  return await window.auth.client.delete(url).json();
};

export default {
  propertiesOrDefaults,

  findById,
  findAll,

  createCoupon,
  deleteCoupon,
  saveCoupon,
};
