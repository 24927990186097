import { motion } from "framer-motion";

import Box from "@mui/material/Box";

import type { IconProps } from "phosphor-react";

export default function SearchMessage({
  text,
  icon: Icon,
}: {
  text: string;
  icon?: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mt: 6,
      }}
    >
      <Box
        className="animation-fade-in"
        sx={(theme) => ({
          backgroundColor: "#eee",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          py: 2,
          px: 6,
          color: theme.palette.text.primary,
          fontSize: 18,
          borderRadius: "60px",
          ...theme.applyStyles("dark", {
            backgroundColor: "#444",
          }),
        })}
      >
        <motion.div
          animate={{
            opacity: 1,
            y: 0,
          }}
          initial={{
            opacity: 0,
            y: "-4rem",
          }}
          style={{ marginRight: "0.75rem" }}
        >
          {Icon ? <Icon weight="light" size={42} /> : null}
        </motion.div>

        <motion.div
          animate={{
            opacity: 1,
            y: 0,
          }}
          initial={{
            opacity: 0,
            y: "-4rem",
          }}
          transition={{ delay: 0.05 }}
        >
          {text}
        </motion.div>
      </Box>
    </Box>
  );
}
