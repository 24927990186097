import { useQuery } from "@tanstack/react-query";

import type { TProfile } from "./types";
import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import Profile from "./index";

export function useProfile(
  email: string,
  partnerId: string,
  options?: Pick<UseQueryOptions, "enabled" | "placeholderData">,
): UseQueryResult<TProfile, Error> {
  return useQuery({
    queryKey: Profile.find.queryKey(email, partnerId),
    queryFn() {
      return Profile.find.queryFn(email, partnerId);
    },
    ...(options ?? {}),
  });
}
