const PreMarket = "preMarket";
const OnMarketPublic = "onMarketPublic";
const OnMarketPrivate = "onMarketPrivate";
const Onboarding = "onboarding";
const Running = "running";
const Canceled = "canceled";
const Completed = "completed";

export const InventoryStatus = {
  PreMarket,
  OnMarketPublic,
  OnMarketPrivate,
  Onboarding,
  Running,
  Canceled,
  Completed,
} as const;
