import List from "@mui/material/List";
import Stack from "@mui/material/Stack";

import AppItemButton from "./AppItemButton";

import type { TApplicationFindAllByStatusResponse } from "@/models/application/types";
import type {
  InfiniteData,
  UseInfiniteQueryResult,
} from "@tanstack/react-query";

const SideBarList = ({
  applicationsQuery,
}: {
  applicationsQuery: UseInfiniteQueryResult<
    InfiniteData<TApplicationFindAllByStatusResponse, unknown>,
    Error
  >;
}) => {
  const apps = applicationsQuery.data.pages?.length
    ? [...applicationsQuery.data.pages.map((p) => p.results)].flat()
    : [];

  return (
    <Stack>
      {apps.length ? (
        <List sx={{ paddingTop: 1, paddingRight: 1.5, paddingLeft: 1.5 }}>
          {apps.map((app) => (
            <AppItemButton key={app._id} application={app} />
          ))}
        </List>
      ) : (
        <div style={{ textAlign: "center" }}>
          <em>No applications</em>
        </div>
      )}
    </Stack>
  );
};

export default SideBarList;
