import * as ReactDOM from "react-dom";

import type { PropsWithChildren } from "react";

export const DrawerPortal = function DrawerPortal({
  children,
}: PropsWithChildren) {
  return ReactDOM.createPortal(
    children,
    document.getElementById("drawer-portal-root"),
  );
};
