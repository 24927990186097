import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";

import FormErrorMessage from "@/components/FormErrorMessage";
import { FORM_PAPER_SX } from "@/constants";

const EnrollmentDropsForm = function DropEnrollmentsForm(props) {
  const theme = useTheme();
  const { isSaving, data, onSubmit } = props;
  const scopedEnrollments = data.scopedItems?.map((enrollment) => {
    return enrollment.lineItem;
  });
  const [showConfirmDrop, setShowConfirmDrop] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      enrollmentData:
        scopedEnrollments.map((item) => {
          return item._id;
        }) ?? [],
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper elevation={1} sx={FORM_PAPER_SX}>
        <Typography gutterBottom variant="body1" fontWeight="500">
          Scope Enrollments
        </Typography>
        <Controller
          control={control}
          name="enrollmentData"
          render={({ field }) => (
            <Select
              {...field}
              labelId="partner-select-field-label"
              multiple
              sx={{ width: "100%" }}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                  }}
                >
                  {selected.map((enrollmentID) => (
                    <Chip
                      key={enrollmentID}
                      label={
                        data.lineItems.find((item) => {
                          return item._id === enrollmentID;
                        })["catalogueItem"]["name"]
                      }
                    />
                  ))}
                </Box>
              )}
            >
              {data.lineItems.map((item) => {
                return (
                  <MenuItem key={item._id} value={item._id}>
                    {item.catalogueItem.name}
                  </MenuItem>
                );
              })}
            </Select>
          )}
          rules={{ required: "This field is required" }}
        />
        <FormErrorMessage error={errors.enrollmentData} />
      </Paper>
      <Box
        sx={{
          position: "sticky",
          width: "100%",
          bottom: 0,
          borderTop: "1px solid",
          borderColor: "#e7e5f5",
          paddingTop: 2,
          paddingRight: 2,
          paddingBottom: 2,
          paddingLeft: 2,
          backgroundColor: "background.default",
          zIndex: 2,
          ...theme.applyStyles("dark", {
            borderColor: "#333",
          }),
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          {showConfirmDrop ? (
            <LoadingButton
              color="primary"
              loading={isSaving}
              sx={{
                flexGrow: 1,
                marginRight: 2,
                minWidth: "7rem",
              }}
              type="submit"
              variant="contained"
              className="animation-fade-in-slow"
            >
              Confirm Drop
            </LoadingButton>
          ) : null}

          <Button
            onClick={() => {
              setShowConfirmDrop(true);
            }}
            sx={{ mr: 1 }}
            variant={showConfirmDrop ? "outlined" : "contained"}
          >
            Submit
          </Button>

          <Button
            color="neutral"
            onClick={props.cancel}
            sx={{
              marginRight: 2,
            }}
            type="button"
            variant="outlined"
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default EnrollmentDropsForm;
