import { forwardRef, useId } from "react";

import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import FormLabel from "@mui/material/FormLabel";

import type { ChangeEventHandler, ForwardedRef } from "react";

type BaseProps = {
  color?: "yellow" | "purple";
  title: string;
  value: string;
};

type Props =
  | (BaseProps & {
      disabled?: boolean;
      onChange: ChangeEventHandler<HTMLTextAreaElement>;
      readonly?: never;
    })
  | (BaseProps & {
      readonly: true;
      disabled?: never;
      onChange?: never;
    });

export const EditTextNotepad = forwardRef(function EditTextNotepad(
  {
    color = "yellow",
    disabled = false,
    onChange,
    readonly,
    title,
    value,
  }: Props,
  ref: ForwardedRef<HTMLTextAreaElement>,
) {
  const id = useId();

  return (
    <div className={`notepad ${color}`}>
      <FormLabel className="label" htmlFor={id}>
        {title}
      </FormLabel>
      {readonly ? (
        <div className="textarea">{value}</div>
      ) : (
        <TextareaAutosize
          className="textarea"
          disabled={disabled}
          id={id}
          minRows={4}
          name="notes"
          onChange={onChange}
          value={value ?? ""}
          ref={ref}
        />
      )}
    </div>
  );
});
