import { Ticket } from "phosphor-react";

import { EmptyIndexMessage } from "@/components/EmptyIndexMessage";

const Empty = function () {
  return (
    <EmptyIndexMessage
      icon={<Ticket size={100} weight="thin" />}
      text="Search for a coupon or create a new one"
    />
  );
};

export default Empty;
