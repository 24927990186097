import * as PropTypes from "prop-types";

import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

export const SortingTableHead = function ({
  columns,
  onRequestSort,
  order,
  orderBy,
}) {
  return (
    <TableHead>
      <TableRow>
        {columns.map((cell) => (
          <TableCell
            key={cell.id}
            align={cell.align}
            style={{ minWidth: cell.minWidth }}
            sortDirection={orderBy === cell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === cell.id}
              direction={orderBy === cell.id ? order : "asc"}
              onClick={() => onRequestSort(cell.id)}
            >
              {cell.label}
              {orderBy === cell.id ? <span></span> : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

SortingTableHead.propTypes = {
  columns: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};
