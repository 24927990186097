import { Drawer, DrawerManager } from "@/components/Drawer";

import MentorAssignmentForm from "./MentorAssignmentForm";

export default DrawerManager.create(function ({ cancel, inventoryItem }) {
  return (
    <Drawer title="Assigned Mentors">
      <MentorAssignmentForm inventoryItem={inventoryItem} cancel={cancel} />
    </Drawer>
  );
});
