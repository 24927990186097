const Promotional = "promotional";
const FullScholarship = "fullScholarship";
const PartialScholarship = "partialScholarship";

export const CouponType = {
  Promotional,
  FullScholarship,
  PartialScholarship,
} as const;

const Currency = "currency";
const Percentage = "percentage";

export const CouponAmountType = {
  Currency,
  Percentage,
} as const;
