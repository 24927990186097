import { Newspaper } from "phosphor-react";

import { EmptyIndexMessage } from "@/components/EmptyIndexMessage";

export default function Empty({ applicationsQuery }) {
  const { isLoading, isFetching, data } = applicationsQuery;

  const isEmpty =
    !isLoading &&
    !isFetching &&
    Boolean(data) &&
    Boolean(data.pages) &&
    ![...data.pages.map((p) => p.results)].flat().length;

  const text = (function () {
    if (isLoading || isFetching) {
      return "Inbox Loading";
    }
    if (isEmpty) {
      return "Inbox Empty!";
    }
    if (Boolean(data?.pages) && data?.pages.length) {
      return "Select an application";
    }

    return "Inbox";
  })();

  return (
    <EmptyIndexMessage
      celebrate={isEmpty}
      icon={<Newspaper size={100} weight="thin" />}
      text={text}
    />
  );
}
