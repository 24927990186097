import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { InputLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { CatalogueItemSelectMultiple } from "@/components/CatalogueItemSelectMultiple";
import EmptyBox from "@/components/EmptyBoxField/EmptyBox";
import LoadingSelect from "@/components/EmptyBoxField/LoadingSelect";
import { MultiSelect } from "@/components/MultiSelect/MultiSelect";
import { ALL_INVENTORY_DURATION_OPTIONS } from "@/constants";
import Catalogue from "@/models/catalogue";

const SearchForm = function ({ onSubmit, partners, queryParams }) {
  const [data, setData] = useState({ ...queryParams });

  const catalogueItems = useQuery({
    queryKey: ["catalogue", data.partner_ids],
    queryFn() {
      return Promise.all(
        data.partner_ids.map((id) => Catalogue.findAllByPartnerId.queryFn(id)),
      );
    },
    enabled: Boolean(data.partner_ids),
  });

  const onChange = function (e) {
    setData({
      ...data,
      [e.target.name]: e.target.value === "0" ? "" : e.target.value,
    });
  };

  const handleSubmit = function (e) {
    e.preventDefault();
    onSubmit(data);
  };

  useEffect(() => {
    if (typeof catalogueItems.data !== "undefined") {
      const flatMappedCatalogueItems = catalogueItems.data
        .flat()
        .map((item) => item._id);
      setData((data) => ({
        ...data,
        catalogueItem_ids: data.catalogueItem_ids.filter((id) =>
          flatMappedCatalogueItems.includes(id),
        ),
      }));
    }
  }, [catalogueItems.data]);

  useEffect(() => {
    setData({ ...queryParams });
  }, [queryParams]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(5, auto)",
            gap: ".5em .5em",
            alignItems: "start",
            px: 2,
            paddingTop: 2,
            paddingBottom: 1,
          }}
        >
          <TextField
            label="Keyword Search"
            name="keyword"
            onChange={onChange}
            sx={{ gridColumn: "span 2" }}
            value={data.name}
            variant="outlined"
          />
          <DesktopDatePicker
            inputFormat="MM/dd/yyyy"
            label="Start Range Begins"
            onChange={(value) => {
              onChange({ target: { name: "startDate", value: value } });
            }}
            renderInput={(params) => <TextField name="startDate" {...params} />}
            slotProps={{
              textField: {
                size: "small",
              },
            }}
            value={new Date(data.startDate)}
          />
          <DesktopDatePicker
            inputFormat="MM/dd/yyyy"
            label="Start Range Ends"
            onChange={(value) => {
              onChange({ target: { name: "endDate", value: value } });
            }}
            renderInput={(params) => <TextField name="endDate" {...params} />}
            slotProps={{
              textField: {
                size: "small",
              },
            }}
            value={new Date(data.endDate)}
          />
          <FormControl sx={{ gridColumn: "span 2" }}>
            <MultiSelect
              label="Partner(s)"
              name="partner_ids"
              onChange={onChange}
              options={partners.data}
              value={data.partner_ids}
            />
          </FormControl>
          <FormControl sx={{ gridColumn: "span 2" }}>
            {catalogueItems.isLoading ? (
              <LoadingSelect sx={{ py: 0.9 }}>
                Loading catalogue items...
              </LoadingSelect>
            ) : partners.data && catalogueItems.data ? (
              <CatalogueItemSelectMultiple
                items={catalogueItems.data}
                onChange={(catalogueItem_ids) => {
                  setData({
                    ...data,
                    catalogueItem_ids,
                  });
                }}
                value={data.catalogueItem_ids}
              />
            ) : (
              <EmptyBox sx={{ py: 0.9 }}>&nbsp;</EmptyBox>
            )}
          </FormControl>
          <FormControl sx={{ gridColumn: "span 1" }}>
            <InputLabel>Duration</InputLabel>
            <Select
              label="Duration"
              name="duration"
              value={data.duration || "0"}
              size={"small"}
              onChange={onChange}
            >
              <MenuItem value="0">Any Duration</MenuItem>
              {ALL_INVENTORY_DURATION_OPTIONS.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item.value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            color="neutral"
            sx={{ marginLeft: 1, gridColumn: 5, gridRow: 1 }}
            type="submit"
            variant="outlined"
          >
            Search
          </Button>
        </Box>
      </form>
    </LocalizationProvider>
  );
};

export default SearchForm;
