import { createContext, useCallback, useState } from "react";

import UPAlert from "@/components/UPAlert";

import type { UPAlertProps } from "@/components/UPAlert";

export const AlertContext =
  createContext<(options: UPAlertProps) => void | null>(null);

const AlertContextProvider = function AlertContextProvider({ children }) {
  const [alert, setAlert] = useState<UPAlertProps>({
    message: "",
    isOpen: false,
  });

  const showAlert = useCallback(function (options: UPAlertProps) {
    setAlert({
      ...options,
      isOpen: true,
    });
  }, []);

  const hideAlert = function () {
    setAlert({
      message: alert.message || "",
      isOpen: false,
    });
  };

  return (
    <AlertContext.Provider value={showAlert}>
      <>
        {children}
        <UPAlert {...alert} onClose={hideAlert} />
      </>
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;
