import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

import { Data } from "@/components/Data";

export const DataListLoading = () => {
  return (
    <Data>
      <Data.Item>
        <Data.Label>
          <Skeleton sx={{ width: "2rem" }} />
        </Data.Label>
        <Data.Content sx={{ width: "50%" }}>
          <Skeleton sx={{ width: "100%" }} />
        </Data.Content>
      </Data.Item>
      <Data.Item>
        <Data.Label>
          <Skeleton sx={{ width: "2rem" }} />
        </Data.Label>
        <Data.Content sx={{ width: "45%" }}>
          <Skeleton sx={{ width: "100%" }} />
        </Data.Content>
      </Data.Item>
      <Data.Item>
        <Data.Label>
          <Skeleton sx={{ width: "2rem" }} />
        </Data.Label>
        <Data.Content sx={{ width: "45%" }}>
          <Skeleton sx={{ width: "100%" }} />
        </Data.Content>
      </Data.Item>
    </Data>
  );
};

export const HeadingLoading = () => {
  return (
    <Box sx={{ flexGrow: 1, pr: 2 }}>
      <Typography sx={{ width: "25%", mb: 0 }}>
        <Skeleton sx={{ mb: 0 }} />
        <Skeleton />
      </Typography>
      <Typography sx={{ width: "50%" }} variant="h2">
        <Skeleton />
      </Typography>
    </Box>
  );
};
