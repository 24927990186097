import { useEffect, useState } from "react";

import HistoryIcon from "@mui/icons-material/History";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { Spinner } from "@/components/Spinner";
import Inventory from "@/models/inventory";
import Order from "@/models/order";

import Enrollment from "./Enrollment";

export default function EnrollmentsList({ enrollmentsList, email }) {
  const onMutationSuccess = function () {
    enrollmentsList.refetch();
  };

  const [isLoading, setIsLoading] = useState(false);
  const [enrollments, setEnrollments] = useState([]);

  useEffect(() => {
    const go = async function () {
      try {
        setIsLoading(true);

        const requests = enrollmentsList.data.map((e) => {
          const request = Promise.all([
            Order.findById.queryFn(e.order._id),
            Inventory.findById.queryFn(e.inventory._id),
          ]);
          return request.then((res) => {
            return {
              order: res[0],
              inventory: res[1],
              withdrawnAt: e.withdrawnAt,
            };
          });
        });

        const results = await Promise.all(requests);
        setEnrollments(results ?? []);
        setIsLoading(false);
      } catch (err) {
        console.log("error", err);
      }
    };

    if (enrollmentsList?.data) {
      go();
    }
  }, [enrollmentsList.data]);

  const IS_LOADING =
    enrollmentsList.isLoading || enrollmentsList.isRefetching || isLoading;

  const CURRENT = enrollments.filter((e) => {
    return new Date(e.inventory.endDate) > new Date();
  });

  const PAST = enrollments.filter((e) => {
    return new Date(e.inventory.endDate) < new Date();
  });

  return (
    <>
      {IS_LOADING ? (
        <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
          <Spinner loading sx={{ mr: 1 }} />
          Loading...
        </Box>
      ) : null}
      {!enrollments.length && !IS_LOADING ? (
        <Typography display="block" variant="body1">
          <em>No Enrollments</em>
        </Typography>
      ) : null}

      {enrollments.length ? (
        <>
          {CURRENT.map((e, index) => {
            return (
              <Enrollment
                key={index}
                enrollment={e}
                email={email}
                onMutationSuccess={onMutationSuccess}
              />
            );
          })}

          {PAST.length ? (
            <Typography
              display="flex"
              variant="overline"
              sx={{ alignItems: "center", paddingTop: "1rem" }}
            >
              <HistoryIcon sx={{ mr: 1 }} />
              Past Enrollments:
            </Typography>
          ) : null}
          {PAST.map((e, index) => {
            return (
              <Enrollment
                key={index}
                enrollment={e}
                email={email}
                onMutationSuccess={onMutationSuccess}
              />
            );
          })}
        </>
      ) : null}
    </>
  );
}
