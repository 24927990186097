import { useQuery } from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import CartModel from "@/models/cart";

import ActiveCoupon from "./ActiveCoupon";
import AddCoupon from "./AddCoupon";
import AddItemToCart from "./AddItemToCart";
import LineItem from "./LineItem";

export default function Cart({ profile }) {
  const theme = useTheme();

  const [showAddToCart, setShowAddToCart] = useState(false);
  const [addingToCart, setAddingToCart] = useState(false);

  const [savingCoupon, setSavingCoupon] = useState(false);
  const [removingCoupon, setRemovingCoupon] = useState(false);

  const partner = profile.partner;
  const owner = profile.email;

  const cart = useQuery({
    queryKey: CartModel.find.queryKey(partner._id, owner),
    queryFn() {
      return CartModel.find.queryFn(partner._id, owner);
    },
    refetchInterval(query) {
      return !query.state.data || query.state.data.status !== "ready"
        ? 1000
        : undefined;
    },
  });
  const isPolling = cart.isRefetching || cart.data?.status !== "ready";
  const pollCart = function () {
    return cart.refetch();
  };

  const waitForCart = async () => {
    let status;
    while (status !== "ready") {
      if (status) {
        await new Promise((resolve) => {
          setTimeout(resolve, 1000);
        });
      }
      const cartUpdate = await pollCart();
      status = cartUpdate.data.status;
    }
  };
  const addToCart = async ({ inventoryItemID, refetch = true }) => {
    setAddingToCart(true);
    await CartModel.addLineItem(partner._id, owner, inventoryItemID);
    setAddingToCart(false);
    setShowAddToCart(false);
    if (refetch) {
      pollCart();
    }
  };
  const removeFromCart = async ({ inventoryItemID, wait = false }) => {
    if (wait) {
      await waitForCart();
    }
    await CartModel.removeLineItem(partner._id, owner, inventoryItemID);
    pollCart();
  };
  const hasBlockingErrors =
    cart.data?.errors?.some((err) => err.level > 0) || false;

  return (
    <Box sx={{ position: "relative" }}>
      <Backdrop
        sx={{
          backgroundColor: "rgba(255,255,255, 0.75)",
          position: "absolute",
          zIndex: 100,
          ...theme.applyStyles("dark", {
            backgroundColor: "rgba(0,0,0, 0.75)",
          }),
        }}
        open={isPolling}
      >
        <CircularProgress color="primary" />
      </Backdrop>

      {hasBlockingErrors ? (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5">Checkout Validation Errors</Typography>
          <ul>
            {cart.data.errors.map((e, i) => {
              return <li key={i}>{e.message}</li>;
            })}
          </ul>
        </Box>
      ) : null}

      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography variant="h5">Line Items</Typography>
        <Button
          color="secondary"
          onClick={() => {
            setShowAddToCart(!showAddToCart);
          }}
          size="small"
          startIcon={<AddIcon />}
          sx={{ ml: 2 }}
          variant="text"
        >
          Add
        </Button>
      </Box>

      <AnimatePresence>
        {showAddToCart ? (
          <motion.div
            layout
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
          >
            <AddItemToCart
              addToCart={addToCart}
              cancel={() => {
                setShowAddToCart(false);
              }}
              cartLineItemIDs={cart.data?.lineItems.map((i) => i._id) ?? []}
              isSaving={addingToCart}
              partner={partner}
            />
          </motion.div>
        ) : null}
      </AnimatePresence>

      {cart.data?.lineItems.length ? (
        <AnimatePresence initial={false}>
          {cart.data.lineItems.map((i) => {
            return (
              <motion.div
                key={i._id}
                initial={{
                  height: 0,
                  opacity: 0,
                  backgroundColor: "rgba(215, 255, 166, 1)",
                  ...theme.applyStyles("dark", {
                    backgroundColor: "rgba(61, 81, 38, 1)",
                  }),
                }}
                animate={{
                  height: "auto",
                  opacity: 1,
                  backgroundColor: "rgba(215, 255, 166, 0)",
                }}
                exit={{ height: 0, opacity: 0 }}
                style={{ overflow: "hidden" }}
              >
                <LineItem item={i} remove={removeFromCart} add={addToCart} />
              </motion.div>
            );
          })}
        </AnimatePresence>
      ) : (
        <Typography
          className="animation-fade-in"
          sx={{
            border: "1px dashed rgba(153,153,153, 0.6)",
            borderRadius: 1.5,
            flexBasis: "50%",
            px: 2,
            py: 3,
            opacity: 0.8,
            fontStyle: "italic",
            mt: 1,
            mb: 2,
          }}
        >
          Cart is empty
        </Typography>
      )}

      <br />

      <Typography variant="h5" sx={{ mb: 2 }}>
        Coupon
      </Typography>

      <AddCoupon
        save={async (code) => {
          setSavingCoupon(true);
          await CartModel.applyCoupon(partner._id, owner, code);
          pollCart();
          setSavingCoupon(false);
        }}
        isSaving={savingCoupon}
      />

      <br />

      <Box sx={{ mb: 2, borderBottom: 6, borderColor: "divider", pb: 3 }}>
        {cart.data?.coupon ? (
          <div className="animation-fade-in">
            <Typography variant="body1" fontWeight="bold">
              Active coupon
            </Typography>
            <ActiveCoupon
              coupon={cart.data.coupon}
              isRemoving={removingCoupon}
              remove={async () => {
                setRemovingCoupon(true);
                await CartModel.removeCoupon(
                  partner._id,
                  owner,
                  cart.data.coupon.code,
                ).then(() => {
                  pollCart();
                  setRemovingCoupon(false);
                });
              }}
            />
          </div>
        ) : (
          <Typography
            sx={{
              border: "1px dashed rgba(153,153,153, 0.6)",
              borderRadius: 1.5,
              flexBasis: "50%",
              px: 2,
              py: 1,
              opacity: 0.8,
              fontStyle: "italic",
            }}
          >
            No coupon applied
          </Typography>
        )}
      </Box>

      <Typography variant="h5" sx={{ mb: 2 }}>
        Totals
      </Typography>
      {cart.data ? (
        <>
          <Typography variant="body1">
            Subtotal: {cart.data.summary.formattedRetailPrice}
          </Typography>
          {cart.data.summary.formattedDiscountAmount ? (
            <Typography variant="body1">
              Discount Amount: {cart.data.summary.formattedDiscountAmount}
            </Typography>
          ) : null}
          <Typography variant="body1">
            Total: {cart.data.summary.formattedPriceToPay}
          </Typography>
        </>
      ) : null}
    </Box>
  );
}
