import { formatDistanceToNowStrict } from "date-fns";
import { motion } from "framer-motion";

import { getBGColor } from "./helpers";

import type { Deployment } from "./types";

function _shortenText(text: string) {
  return text
    .replace("hours", "hrs")
    .replace("hour", "hr")
    .replace("minutes", "mins")
    .replace("minute", "min")
    .replace("seconds", "secs")
    .replace("second", "sec");
}

export const opacityFromIndex = (index: number) => {
  switch (index) {
    case 0:
      return 1;
    case 1:
      return 0.9;
    case 2:
      return 0.8;
    case 3:
      return 0.75;
    default:
      return 0.7;
  }
};

export default function LastDeploys({
  deployments,
  visible = false,
}: {
  deployments: Deployment[];
  visible: boolean;
}) {
  return (
    <div
      style={{
        position: "relative",
        display: "grid",
        gridTemplateColumns: "repeat(5, minmax(0, 1fr))",
        gap: "0.1rem",
        overflow: "hidden",
      }}
    >
      {deployments.slice(0, 5).map((d, index) => {
        return (
          <motion.div
            key={d.uid}
            variants={{
              visible: {
                top: 0,
                opacity: opacityFromIndex(index),
                transition: {
                  duration: 0.2,
                  delay: index * 0.065,
                  ease: "easeInOut",
                },
              },
              hidden: {
                opacity: 0,
                top: 30,
                transition: {
                  duration: 0.3,
                },
              },
            }}
            initial="hidden"
            animate={visible ? "visible" : "hidden"}
            style={{
              position: "relative",
              overflow: "hidden",
              textAlign: "center",
              paddingTop: "0.25rem",
              paddingBottom: "0.25rem",
              fontSize: "0.9rem",
              color: "#fff",
              backgroundColor: getBGColor(d.state),
              borderRadius: "0.25rem",
            }}
          >
            {_shortenText(formatDistanceToNowStrict(new Date(d.createdAt)))}
            <br />
            ago
          </motion.div>
        );
      })}
    </div>
  );
}
