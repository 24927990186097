import type { CSSProperties } from "react";

const style1 = { "--index": 0 } as CSSProperties;
const style2 = { "--index": 1 } as CSSProperties;
const style3 = { "--index": 2 } as CSSProperties;

export default function Glitch({
  active = true,
  text,
  ...rest
}: {
  [x: string]: unknown;
  active?: boolean;
  text: string;
}) {
  return active ? (
    <span className="stack" {...rest}>
      <span style={style1}>{text}</span>
      <span style={style2} aria-hidden>
        {text}
      </span>
      <span style={style3} aria-hidden>
        {text}
      </span>
    </span>
  ) : (
    <span {...rest}>{text}</span>
  );
}
