import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import IconButton from "@mui/material/IconButton";

import { CopyToClipboardController } from "./CopyToClipboardController";

import type { CSSProperties } from "react";

export const CopyToClipboardButton = function CopyToClipboardButton({
  className,
  style,
  text,
}: {
  className?: string;
  style?: CSSProperties;
  text: string;
}) {
  return (
    <CopyToClipboardController text={text}>
      {(copied) => (
        <IconButton
          className={className}
          size="small"
          style={style}
          aria-label="copy to clipboard"
        >
          {copied && <AssignmentTurnedInIcon />}
          {!copied && <AssignmentIcon />}
        </IconButton>
      )}
    </CopyToClipboardController>
  );
};
