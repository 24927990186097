import { Controller } from "react-hook-form";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import { MentorAvailability } from "@/models/mentor/constants";

export const ActiveField = function ActiveField(props) {
  return (
    <Controller
      name="onPayroll"
      control={props.control}
      render={({ field: { onChange, value, name, ref } }) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              color="primary"
              inputRef={ref}
              name={name}
              onChange={(e) => onChange(e.target.checked)}
            />
          }
          label="On Payroll"
          labelPlacement="end"
        />
      )}
    />
  );
};

export const NotesField = function NotesField(props) {
  return (
    <Controller
      name="availabilityNotes"
      control={props.control}
      render={({ field: { ref, ...rest } }) => {
        return (
          <TextField
            inputRef={ref}
            label="Availability Notes"
            multiline
            minRows={3}
            variant="outlined"
            {...rest}
            slotProps={{
              inputLabel: { shrink: true },
            }}
          />
        );
      }}
    />
  );
};

export const AvailabilityField = function AvailabilityField(props) {
  return (
    <Controller
      name="availability"
      control={props.control}
      render={({ field }) => {
        const { value, ref, ...rest } = field;
        return (
          <TextField
            select
            inputRef={ref}
            label="Availability"
            variant="outlined"
            value={value ?? ""}
            {...rest}
            slotProps={{
              inputLabel: { shrink: true },
            }}
          >
            {[
              MentorAvailability.Dormant,
              MentorAvailability.YearRound,
              MentorAvailability.Seasonal,
              MentorAvailability.Substitute,
            ].map((avail) => {
              return (
                <MenuItem key={avail} value={avail}>
                  {avail}
                </MenuItem>
              );
            })}
          </TextField>
        );
      }}
    />
  );
};

export const PhoneField = function PhoneField(props) {
  return (
    <Controller
      name="phone"
      control={props.control}
      render={({ field: { ref, ...rest } }) => {
        return (
          <TextField
            inputRef={ref}
            label="Phone"
            variant="outlined"
            {...rest}
            slotProps={{
              inputLabel: { shrink: true },
            }}
          />
        );
      }}
    />
  );
};

export const EmailField = function EmailField(props) {
  return (
    <Controller
      control={props.control}
      name="email"
      render={({ field: { ref, ...rest } }) => {
        return (
          <TextField
            error={Boolean(props.error)}
            inputRef={ref}
            label="Email"
            required
            variant="outlined"
            {...rest}
            slotProps={{
              inputLabel: { shrink: true },
            }}
          />
        );
      }}
      rules={{ required: "This field is required" }}
    />
  );
};

export const PersonalEmailField = function PersonalEmailField(props) {
  return (
    <Controller
      control={props.control}
      name="personalEmail"
      render={({ field: { ref, ...rest } }) => {
        return (
          <TextField
            inputRef={ref}
            label="Personal Email"
            variant="outlined"
            {...rest}
            slotProps={{
              inputLabel: { shrink: true },
            }}
          />
        );
      }}
    />
  );
};

export const LastNameField = function LastNameField(props) {
  return (
    <Controller
      name="lastName"
      control={props.control}
      render={({ field: { ref, ...rest } }) => {
        return (
          <TextField
            error={Boolean(props.error)}
            inputRef={ref}
            label="Last Name"
            required
            variant="outlined"
            {...rest}
            slotProps={{
              inputLabel: { shrink: true },
            }}
          />
        );
      }}
      rules={{ required: "This field is required" }}
    />
  );
};

export const FirstNameField = function FirstNameField(props) {
  return (
    <Controller
      control={props.control}
      name="firstName"
      render={({ field: { ref, ...rest } }) => {
        return (
          <TextField
            error={Boolean(props.error)}
            inputRef={ref}
            label="First Name"
            required
            variant="outlined"
            {...rest}
            slotProps={{
              inputLabel: { shrink: true },
            }}
          />
        );
      }}
      rules={{ required: "This field is required" }}
    />
  );
};

export const SlackUsernameField = function SlackUsernameField(props) {
  return (
    <Controller
      control={props.control}
      name="slackUsername"
      render={({ field: { ref, ...rest } }) => {
        return (
          <TextField
            inputRef={ref}
            label="Slack Username"
            variant="outlined"
            {...rest}
            slotProps={{
              inputLabel: { shrink: true },
            }}
          />
        );
      }}
    />
  );
};
