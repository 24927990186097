import { useMutation } from "@tanstack/react-query";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

export default function Unassign({ onError, onSuccess, unassign }) {
  const unassignMutation = useMutation({
    mutationFn: unassign,
    onSuccess,
    onError,
  });

  return unassignMutation.isPending ? (
    <CircularProgress size="1.25rem" sx={{ mr: 0.5 }} />
  ) : (
    <Button
      onClick={() => {
        unassignMutation.mutate();
      }}
      variant="contained"
      color="error"
    >
      Remove
    </Button>
  );
}
