import * as PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid2";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import { ActionButtons } from "@/components/ActionButtons";
import FormErrorMessage from "@/components/FormErrorMessage";
import { FORM_PAPER_SX, INVENTORY_TYPE_OPTIONS } from "@/constants";
import { useCatalogueForPartner } from "@/models/catalogue/hooks";
import Inventory from "@/models/inventory";

import MaxCapacity from "./MaxCapacity";
import ProductDates from "./ProductDates";

const Spinner = function () {
  return <CircularProgress size="1.75rem" sx={{ mr: 2 }} />;
};

const InventoryFormNew = function InventoryFormNew(props) {
  const { partners, isSaving, saveError } = props;
  const data = props.data || {};

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      ...Inventory.propertiesOrDefaults(data),
      catalogue_item_id: "",
      partner_id: "",
      type: "program",
    },
  });

  const selectedPartnerID = watch("partner_id");
  const selectedCatalogueItemID = watch("catalogue_item_id");
  const itemType = watch("type");

  const catalogueItems = useCatalogueForPartner(selectedPartnerID);

  const catalogueByType = catalogueItems?.data?.filter(
    (item) => item.type === itemType,
  );

  const [MSRP, setMSRP] = useState(0.0);

  useEffect(() => {
    if (catalogueItems.data) {
      const item = catalogueItems.data.find(
        (i) => i._id === selectedCatalogueItemID,
      );
      if (item) {
        setMSRP(item.msrp);
        setValue("retailPrice", item.msrp);
      }
      if (item?.type === "addOn") {
        setValue("cohortLength", "10 weeks");
      }
      if (item?.type === "collegeCredit") {
        setValue("cohortLength", "6 weeks");
      }
    }
  }, [setValue, selectedCatalogueItemID, catalogueItems.data]);

  const preSubmit = function (formValues) {
    const partnerID = formValues.partner_id;
    const catalogueItemID = formValues.catalogue_item_id;
    const type = formValues.type;
    delete formValues.partner_id;
    delete formValues.catalogue_item_id;
    delete formValues.type;

    const partnerName = partners.data.find((p) => p._id === partnerID)["name"];
    const catalogueItemName = catalogueItems.data.find(
      (c) => c._id === catalogueItemID,
    )["name"];
    const internalName = catalogueItems.data.find(
      (c) => c._id === catalogueItemID,
    )["internalName"];

    const payload = {
      ...formValues,
      catalogueItem: {
        _id: catalogueItemID,
        name: catalogueItemName,
        type,
        internalName,
      },
      partner: {
        _id: partnerID,
        name: partnerName,
      },
    };

    props.onSubmit(payload);
  };

  const itemID = getValues("catalogue_item_id");

  const handleItemChange = (event) => {
    setValue("catalogue_item_id", event.target.value);
  };

  return (
    <form onSubmit={handleSubmit(preSubmit)} noValidate>
      <Paper elevation={1} sx={FORM_PAPER_SX}>
        {partners.isLoading && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Spinner /> Loading Partners
          </div>
        )}
        {partners.error && <div>Error loading Partners</div>}
        {partners.data && (
          <FormControl
            error={errors.partner_id ? true : false}
            fullWidth
            margin="normal"
          >
            <InputLabel id="partner-select-field-label">Partner</InputLabel>
            <Controller
              control={control}
              name="partner_id"
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="partner-select-field-label"
                  label="Partner"
                >
                  <MenuItem key={0} value={0}>
                    Choose a partner...
                  </MenuItem>
                  {partners.data.map((partner) => {
                    return (
                      <MenuItem
                        key={partner._id}
                        value={partner._id}
                        data-testid={partner._id}
                      >
                        {partner.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
              rules={{ required: "This field is required" }}
            />
            <FormErrorMessage error={errors["partner_id"]} />
          </FormControl>
        )}
        {Boolean(selectedPartnerID) && (
          <>
            {catalogueItems.isLoading && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: 16,
                }}
              >
                <Spinner /> Loading Catalogue Options
              </div>
            )}
            <Grid container spacing={2}>
              <Grid size={4}>
                {catalogueItems.data && (
                  <FormControl
                    error={errors.catalogue_item_id ? true : false}
                    fullWidth
                    margin="normal"
                  >
                    <InputLabel id="item-type-select-field-label">
                      Type
                    </InputLabel>
                    <Controller
                      control={control}
                      name="type"
                      data-testid="catalogue-item-type"
                      render={({ field }) => {
                        return (
                          <Select
                            {...field}
                            labelId="item-type-select-field-label"
                            label="Type"
                            value={itemType}
                          >
                            {INVENTORY_TYPE_OPTIONS.map((item) => {
                              return (
                                <MenuItem key={item.value} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                    <FormErrorMessage error={errors["type"]} />
                  </FormControl>
                )}
              </Grid>
              <Grid size={8}>
                {catalogueItems.data && (
                  <FormControl
                    error={errors.catalogue_item_id ? true : false}
                    fullWidth
                    margin="normal"
                  >
                    <InputLabel id="catalogue-item-select-field-label">
                      Catalogue Item
                    </InputLabel>
                    <Controller
                      control={control}
                      name="catalogue_item_id"
                      data-testid="catalogue-item-selection"
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="catalogue-item-select-field-label"
                          label="Catalogue Item"
                          value={itemID}
                          onChange={(event) => handleItemChange(event)}
                        >
                          {catalogueByType.map((item) => {
                            return (
                              <MenuItem key={item._id} value={item._id}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                      rules={{ required: "This field is required" }}
                    />
                    <FormErrorMessage error={errors["catalogue_item_id"]} />
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
      <br />
      {Boolean(selectedPartnerID) && Boolean(selectedCatalogueItemID) ? (
        <Paper
          elevation={1}
          sx={FORM_PAPER_SX}
          className="animation-fade-in-slow"
        >
          <Grid container spacing={2}>
            <Grid size={12}>
              <Box sx={{ display: "inline", mr: 2 }}>
                <MaxCapacity control={control} errors={errors} />
              </Box>
              <FormControl margin="normal">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Controller
                    control={control}
                    name="retailPrice"
                    render={({ field: { ref, ...rest } }) => {
                      return (
                        <TextField
                          error={errors.retailPrice ? true : false}
                          value={MSRP}
                          inputRef={ref}
                          label="Retail Price"
                          required
                          sx={{ flexGrow: 1, maxWidth: "11rem" }}
                          variant="outlined"
                          {...rest}
                        />
                      );
                    }}
                    rules={{ required: "This field is required" }}
                  />
                </div>
                <FormHelperText>MSRP: ${MSRP} USD</FormHelperText>
                <FormErrorMessage error={errors["retailPrice"]} />
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      ) : null}
      {Boolean(selectedPartnerID) && Boolean(selectedCatalogueItemID) ? (
        <ProductDates control={control} errors={errors} type={itemType} />
      ) : null}
      <br />
      <ActionButtons
        cancel={props.cancel}
        error={saveError}
        isSaving={isSaving}
      >
        <FormErrorMessage gutterBottom error={saveError} />
      </ActionButtons>
    </form>
  );
};
InventoryFormNew.propTypes = {
  cancel: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isSaving: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  saveError: PropTypes.object,
  successCallback: PropTypes.func,
};
export default InventoryFormNew;
