import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";

import type { AlertColor } from "@mui/material/Alert";
import type { ReactNode, SyntheticEvent } from "react";

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

export type UPAlertProps = {
  action?: (close: () => void) => ReactNode;
  duration?: number;
  horizontal?: "left" | "center" | "right";
  isOpen?: boolean;
  message?: string;
  onClose?: (event?: SyntheticEvent<Element, Event>) => void;
  severity?: AlertColor;
  variant?: "filled" | "outlined" | "standard";
  verticalAnchor?: "top" | "bottom";
};

const UPAlert = function UPAlert({
  action,
  duration = 4000,
  horizontal = "right",
  isOpen = true,
  message = "",
  onClose,
  severity = "success",
  variant = "filled",
  verticalAnchor = "top",
}: UPAlertProps) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: verticalAnchor,
        horizontal: horizontal,
      }}
      onClose={(event: SyntheticEvent<Element, Event>, reason: string) => {
        if (reason === "clickaway") {
          return;
        }

        onClose?.(event);
      }}
      autoHideDuration={duration}
      open={isOpen}
      TransitionComponent={SlideTransition}
    >
      <Alert
        action={
          action ? (
            action(onClose)
          ) : (
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={onClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )
        }
        elevation={20}
        onAbort={(event: SyntheticEvent<Element, Event>) => {
          onClose?.(event);
        }}
        severity={severity}
        variant={variant}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default UPAlert;
