import Skeleton from "@mui/material/Skeleton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function EnrollmentsTableEmptyContent() {
  return (
    <>
      <TableRow aria-hidden>
        <TableCell>
          <Skeleton animation={false} sx={{ width: "50%" }} />
        </TableCell>
        <TableCell>
          <Skeleton animation={false} sx={{ width: "25%" }} />
        </TableCell>
        <TableCell>
          <Skeleton animation={false} sx={{ width: "50%" }} />
        </TableCell>
        <TableCell>
          <Skeleton animation={false} sx={{ width: "80%" }} />
        </TableCell>
      </TableRow>
      <TableRow aria-hidden>
        <TableCell>
          <Skeleton animation={false} sx={{ width: "65%" }} />
        </TableCell>
        <TableCell>
          <Skeleton animation={false} sx={{ width: "25%" }} />
        </TableCell>
        <TableCell>
          <Skeleton animation={false} sx={{ width: "40%" }} />
        </TableCell>
        <TableCell>
          <Skeleton animation={false} sx={{ width: "50%" }} />
        </TableCell>
      </TableRow>
      <TableRow aria-hidden>
        <TableCell>
          <Skeleton animation={false} sx={{ width: "50%" }} />
        </TableCell>
        <TableCell>
          <Skeleton animation={false} sx={{ width: "25%" }} />
        </TableCell>
        <TableCell>
          <Skeleton animation={false} sx={{ width: "45%" }} />
        </TableCell>
        <TableCell>
          <Skeleton animation={false} sx={{ width: "70%" }} />
        </TableCell>
      </TableRow>
    </>
  );
}
