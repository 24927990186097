import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useDrawer } from "@/components/Drawer";
import MarketingListDrawer from "@/components/Drawers/MarketingListDrawer";

export default function Show({ data, successCallback }) {
  const drawer = useDrawer(MarketingListDrawer);

  return (
    <div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {(data ?? []).map((list) => {
            return (
              <TableRow key={list.listId} hover>
                <TableCell>{list.listId}</TableCell>
                <TableCell>{list.name}</TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="edit"
                    color="primary"
                    onClick={() => {
                      drawer.show({
                        cancel() {
                          drawer.remove();
                        },
                        data: list,
                        successCallback() {
                          drawer.remove();
                          successCallback();
                        },
                      });
                    }}
                  >
                    <RocketLaunchIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}
