import { useQuery } from "@tanstack/react-query";

import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";

import Mentor from "@/models/mentor";

import Results from "../components/Results";

const deDupedMentors = function (mentors) {
  return mentors.filter(
    (mentor, index, arr) =>
      arr.findIndex((entry) => entry.email === mentor.email) === index,
  );
};

const MentorSearchShow = function ({ catalogueItems }) {
  const mentors = useQuery({
    queryKey: ["mentors", catalogueItems],
    queryFn() {
      return Promise.all(
        catalogueItems.map((id) => Mentor.findAllByCatalogueItemId.queryFn(id)),
      );
    },
    enabled: Boolean(catalogueItems),
  });
  return (
    <>
      {mentors.isError ? (
        <Alert sx={{ mt: 1 }} severity="error">
          {mentors.error.response.status === 404
            ? "Could not find mentors"
            : "An error has occurred"}
        </Alert>
      ) : null}
      {mentors.isLoading ? <LinearProgress color="secondary" /> : null}
      {mentors.data ? (
        <Results mentors={deDupedMentors(mentors.data.flat())} />
      ) : null}
    </>
  );
};

export default MentorSearchShow;
