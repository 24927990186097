import { find, get } from "lodash-es";
import { useId } from "react";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";

import type { SelectProps } from "@mui/material/Select";

export const MultiSelect = function MultiSelect(
  props: SelectProps<string[]> & {
    options: Array<{
      _id: string;
      name: string;
    }>;
  },
) {
  const id = useId();
  const getNameFromID = function (all, _id) {
    return get(find(all, ["_id", _id]), "name");
  };
  const { label, options, sx = {}, ...selectProps } = props;

  return (
    <FormControl fullWidth>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        input={<OutlinedInput label="Chip" />}
        {...selectProps}
        label={label}
        labelId={id}
        multiple
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((_id) => (
              <Chip
                size="small"
                key={_id}
                label={getNameFromID(options, _id)}
              />
            ))}
          </Box>
        )}
        sx={{ width: "100%", ...sx }}
      >
        {options?.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
