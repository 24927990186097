import {
  PRIMARY_COLOR_DARK,
  PRIMARY_COLOR_LIGHT,
  SECONDARY_COLOR_DARK,
  SECONDARY_COLOR_LIGHT,
} from "../constants";

import type { PaletteColorOptions } from "@mui/material/styles";

const LIGHT = {
  neutral: {
    main: "#64748B",
  },
  secondary: {
    main: SECONDARY_COLOR_LIGHT,
  },
  primary: {
    main: PRIMARY_COLOR_LIGHT,
  },
};

const DARK = {
  neutral: {
    main: "#aaa",
  },
  secondary: {
    main: SECONDARY_COLOR_DARK,
  },
  primary: {
    main: PRIMARY_COLOR_DARK,
  },
};

declare module "@mui/material/styles" {
  interface CustomPalette {
    neutral: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

const palette = function (mode) {
  return {
    mode: mode,
    ...(mode === "light" ? LIGHT : DARK),
  };
};

export default palette;
