import { useEffect, useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { EditTextNotepad } from "@/components/EditTextNotepad";
import { APPLICATION_STATUS_OPTIONS } from "@/constants";

const ChangeApplicationStatus = ({ application, isSaving, save }) => {
  const [status, setStatus] = useState(application.status);
  const [notes, setNotes] = useState(application.notes || null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    setStatus(application.status);
    setNotes(application.notes || null);
  }, [application]);

  const handleStatusChange = function (event) {
    setStatus(event.target.value);
  };

  const handleSave = function () {
    const newData = { ...application };
    newData.status = status;

    if (notes !== application.notes) {
      newData.notes = notes;
    }

    save(newData);
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  return (
    <>
      <div>
        <FormControl>
          <RadioGroup
            aria-labelledby="status-group-label"
            name="status"
            onChange={handleStatusChange}
            style={{
              display: matches ? "grid" : "block",
              gridTemplateColumns: "1fr 1fr",
              gridGap: "",
            }}
            value={status}
          >
            {APPLICATION_STATUS_OPTIONS.map(({ label, value }) => (
              <FormControlLabel
                key={value}
                control={<Radio />}
                label={label}
                value={value}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>

      <br />

      <EditTextNotepad
        onChange={handleNotesChange}
        title="Internal Notes"
        value={notes}
      />

      <br />

      <div>
        <LoadingButton
          onClick={handleSave}
          color="primary"
          loading={isSaving}
          sx={{ minWidth: "6rem" }}
          variant="contained"
        >
          Save
        </LoadingButton>
      </div>
    </>
  );
};

export default ChangeApplicationStatus;
