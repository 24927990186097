import { isArray, isNumber, isString, omitBy } from "lodash-es";

import isEmpty from "@/lib/is-empty";

export default function sanitize<T>(obj): T {
  if (isString(obj) || isNumber(obj)) return obj;
  if (isEmpty(obj)) return obj;
  if (isArray(obj)) {
    return obj.filter((v) => !isEmpty(v));
  }
  return omitBy(obj, isEmpty);
}
