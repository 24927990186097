import { useQuery } from "@tanstack/react-query";

import type { TPartner } from "./types";
import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import Partner from "./";

export function usePartner(
  id: string,
  options: Pick<UseQueryOptions, "enabled" | "placeholderData"> = {},
): UseQueryResult<TPartner, Error> {
  return useQuery({
    queryKey: Partner.findById.queryKey(id),
    queryFn() {
      return Partner.findById.queryFn(id);
    },
    ...options,
  });
}

export function usePartners() {
  return useQuery({
    queryKey: Partner.findAll.queryKey(),
    queryFn() {
      return Partner.findAll.queryFn();
    },
  });
}
