import { useEffect, useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

import { EditTextNotepad } from "@/components/EditTextNotepad";

const camelToSpaces = function (str) {
  return str.replace(/[a-z]/gi, (m, o) =>
    m < {} && o ? ` ${m}` : o ? m : m.toUpperCase(),
  );
};

const BLACKLIST_KEYS = [
  "_id",
  "author",
  "createdAt",
  "completedAt",
  "meta",
  "message",
  "notes",
  "partner",
  "time",
  "statement",
  "status",
];

const HEADING_STYLEZ = {
  borderBottom: "1px solid #ccc",
  opacity: "0.7",
};

const ScholarshipRequest = function ({ scholarship, essay, isSaving, save }) {
  const [statement, setStatement] = useState(scholarship.statement);

  // This forces the view to update when navigating between scholarships
  useEffect(() => {
    setStatement(scholarship.statement || null);
  }, [scholarship]);

  const handleSave = function () {
    const newData = { ...scholarship };

    if (statement !== scholarship.statement) {
      newData.statement = statement;
    }

    save(newData);
  };

  const handleStatementChange = (e) => {
    setStatement(e.target.value);
  };

  return (
    <>
      {scholarship && (
        <>
          <Grid container spacing={4}>
            {Object.entries(scholarship).map(function ([key, value]) {
              if (BLACKLIST_KEYS.includes(key)) {
                return null;
              }

              if (!value) {
                return null;
              }

              return (
                <Grid key={key} size={{ xs: 12, md: 6 }}>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    style={HEADING_STYLEZ}
                  >
                    {camelToSpaces(key)}
                  </Typography>
                  {value}
                </Grid>
              );
            })}
          </Grid>

          <br />
          <br />

          <EditTextNotepad
            color="purple"
            onChange={handleStatementChange}
            title="Scholarship Essay"
            value={statement}
          />

          <br />

          <div>
            <LoadingButton
              color="primary"
              loading={isSaving}
              onClick={handleSave}
              sx={{ minWidth: "6rem" }}
              variant="contained"
            >
              Save
            </LoadingButton>
          </div>

          <br />
          <br />

          <Typography
            variant="caption"
            display="block"
            gutterBottom
            style={HEADING_STYLEZ}
          >
            Application Essay
          </Typography>
          {essay ? essay : <em>No Essay Submitted</em>}

          <br />
        </>
      )}
    </>
  );
};

export default ScholarshipRequest;
