import Typography from "@mui/material/Typography";

export const HEADING_STYLE = {
  borderBottom: "1px solid #ccc",
  opacity: "0.7",
};

import type { PropsWithChildren } from "react";

export default function Heading({ children }: PropsWithChildren) {
  return (
    <Typography
      variant="caption"
      display="block"
      gutterBottom
      style={HEADING_STYLE}
    >
      {children}
    </Typography>
  );
}
