import { useState } from "react";

import { EmptyIndexMessage } from "@/components/EmptyIndexMessage";
import { Layout } from "@/components/Layout";
import { TitleBar } from "@/components/TitleBar";

import MentorSearchShow from "./show";
import SearchForm from "../components/SearchFormCatalogue";

function MentorsLayout({ children, submitSearch }) {
  return (
    <>
      <TitleBar title="Mentor Search"></TitleBar>
      <SearchForm onSubmit={submitSearch} />
      <Layout.Content>{children}</Layout.Content>
    </>
  );
}
const MentorSearch = function () {
  const [catalogueItems, setCatalogueItems] = useState([]);

  const submitSearch = (items) => {
    setCatalogueItems(items.catalogueItem_ids);
  };

  return (
    <>
      <Layout>
        <MentorsLayout submitSearch={submitSearch}>
          {catalogueItems.length > 0 ? (
            <MentorSearchShow catalogueItems={catalogueItems} />
          ) : (
            <EmptyIndexMessage text="Perform a search to view a mentor" />
          )}
        </MentorsLayout>
      </Layout>
    </>
  );
};

export default MentorSearch;
