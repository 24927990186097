import Box from "@mui/material/Box";

import { Drawer, DrawerManager } from "@/components/Drawer";
import { ProfileShow } from "@/routes/Profiles/show";

export default DrawerManager.create(function ({ email, partnerID }) {
  return (
    <Drawer title="Profile">
      {email && partnerID ? (
        <Box sx={{ p: 2, bgcolor: "background.paper" }}>
          <ProfileShow email={email} partnerID={partnerID} />
        </Box>
      ) : null}
    </Drawer>
  );
});
