import Mentorswitch from "./MentorSwitch";

export default function LmsSection({ enrollment, courseSections }) {
  const partnerId = enrollment.partner?._id;
  const email = enrollment.student?.email;
  const inventoryId = enrollment.inventory?._id;

  const section = courseSections.data?.find(
    (section) =>
      section.students &&
      section.students.some((student) => student.login_id === email),
  );
  return (
    <>
      <div>
        <strong>
          {courseSections.isLoading
            ? "Loading..."
            : section?.name || "Section not found"}
        </strong>
      </div>
      {courseSections.isSuccess && section && (
        <Mentorswitch
          enrollment={enrollment}
          section={section}
          courseSections={courseSections}
          email={email}
          partnerId={partnerId}
          inventoryId={inventoryId}
        />
      )}
    </>
  );
}
