const BASE = import.meta.env.VITE_API_ENDPOINT;

const routes = {
  show(id) {
    return `${BASE}/orders/${id}`;
  },
  refund(orderId) {
    return `${BASE}/orders/${orderId}/refund`;
  },
} as const;

export default routes;
