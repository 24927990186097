import * as PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export const DatePicker = function ({
  control,
  label,
  name,
  required = true,
  customOnChange,
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <DesktopDatePicker
            format="MM/dd/yyyy"
            label={label}
            onChange={(_event) => {
              onChange(_event);
              customOnChange?.(_event);
            }}
            value={new Date(value)}
          />
        );
      }}
      rules={required ? { required: "This field is required" } : null}
    />
  );
};

DatePicker.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  customOnChange: PropTypes.func,
};
