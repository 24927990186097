import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { CellLink } from "@/components/CellLink";
import { UPChip } from "@/components/UPChip";
import { INVENTORY_STATUS_COLOR_MAP } from "@/constants";
import clientRoutes from "@/lib/client-routes";
import { centsToPrice, formatDate } from "@/lib/helpers";

const SearchResultsTable = function ({ inventoryItems, handleEdit }) {
  const transformedItem = (item) => {
    const formattedRetailPrice = centsToPrice(item.retailPrice);
    return { ...item, retailPrice: formattedRetailPrice };
  };

  return (
    <TableBody>
      {inventoryItems.map((item) => {
        const url = clientRoutes.inventorySearch.show(item._id);
        return (
          <TableRow key={item._id} hover>
            <TableCell align="center">
              <CellLink url={url} />
              {item.lmsEnrollmentRequested ? (
                <CheckIcon color="success" />
              ) : null}
            </TableCell>
            <TableCell>
              <CellLink url={url} />
              {item.partnerName}
            </TableCell>
            <TableCell>
              <CellLink url={url} />
              {item.catalogueItemName}
            </TableCell>
            <TableCell>
              <CellLink url={url} />
              {formatDate(item.startDate)}
            </TableCell>
            <TableCell>
              <CellLink url={url} />
              {item.cohortLength}
            </TableCell>
            <TableCell>
              <CellLink url={url} />
              <UPChip
                color={INVENTORY_STATUS_COLOR_MAP[item.status]}
                label={item.status}
                size="small"
              />
            </TableCell>

            <TableCell align="right">
              <CellLink url={url} />
              <Stack
                alignItems="center"
                flexDirection="row"
                justifyContent="flex-end"
              >
                {item.redemptionCount} / {item.maxCapacity}
              </Stack>
            </TableCell>
            <TableCell align="right">
              <IconButton
                aria-label="edit"
                color="primary"
                sx={{ "&:hover": { outline: "2px solid currentColor" } }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(transformedItem(item));
                }}
              >
                <EditIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default SearchResultsTable;
