import { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { LinearProgress } from "@mui/material";

import { useDrawer } from "@/components/Drawer";
import MentorDrawer from "@/components/Drawers/MentorDrawer";
import { EmptyIndexMessage } from "@/components/EmptyIndexMessage";
import { Layout } from "@/components/Layout";
import { TitleBar } from "@/components/TitleBar";
import { TitleBarButton } from "@/components/TitleBarButton";
import { useAlert } from "@/hooks/use-alert";
import clientRoutes from "@/lib/client-routes";
import GetMiniSearchedMentors from "@/lib/get-minisearched-mentors";
import { useAllMentors } from "@/models/mentor/hooks";

import MentorSearchShow from "./show";
import Results from "../components/Results";
import SearchForm from "../components/SearchFormEmail";

import type { PropsWithChildren } from "react";

function MentorsLayout({
  children,
  submitSearch,
}: PropsWithChildren<{
  submitSearch: (keyword: string) => void;
}>) {
  const navigate = useNavigate();
  const showAlert = useAlert();

  const mentorNewDrawer = useDrawer(MentorDrawer);
  const newMentor = () => {
    mentorNewDrawer.show({
      title: "New Mentor",
      cancel() {
        mentorNewDrawer.remove();
      },
      successCallback(mentor) {
        mentorNewDrawer.remove();
        navigate(clientRoutes.mentorSearch.email.show(mentor.email));
        showAlert({
          message: "Mentor added",
        });
      },
    });
  };

  return (
    <>
      <TitleBar title="Mentor Search">
        <TitleBarButton icon="add" onClick={newMentor}>
          New Mentor
        </TitleBarButton>
      </TitleBar>
      <SearchForm onSubmit={submitSearch} />
      <Layout.Content>{children}</Layout.Content>
    </>
  );
}

const MentorSearch = function () {
  const mentorList = useAllMentors();

  const [keyword, setKeyword] = useState("");

  const submitSearch = (keyword) => {
    setKeyword(keyword);
  };
  const searchedList = GetMiniSearchedMentors(mentorList.data, keyword);
  return (
    <>
      <Layout>
        <>
          <Routes>
            <Route
              index
              element={
                <MentorsLayout submitSearch={submitSearch}>
                  {mentorList.isPending ? (
                    <>
                      <LinearProgress color="secondary" />
                      <EmptyIndexMessage text="Loading Mentor List" />
                    </>
                  ) : (
                    <Results mentors={searchedList} />
                  )}
                </MentorsLayout>
              }
            />

            <Route
              element={
                <MentorsLayout submitSearch={submitSearch}>
                  <MentorSearchShow />
                </MentorsLayout>
              }
              path={clientRoutes.mentorSearch.email.showPath}
            />
          </Routes>
        </>
      </Layout>
    </>
  );
};

export default MentorSearch;
