import { useQuery } from "@tanstack/react-query";
import { ListBullets } from "phosphor-react";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";

import { EmptyIndexMessage } from "@/components/EmptyIndexMessage";
import { Layout } from "@/components/Layout";
import { TitleBar } from "@/components/TitleBar";
import { useAlert } from "@/hooks/use-alert";
import routes from "@/models/marketing-list/routes";

import SearchBar from "./components/SearchBar";
import Show from "./show";

export default function MarketingListsRoute() {
  const [query, setQuery] = useState("");
  const showAlert = useAlert();

  const lists = useQuery({
    queryKey: ["marketingLists", query],
    async queryFn() {
      const url = routes.search(query);
      return await window.auth.client
        .post(url, {
          json: {
            query,
          },
        })
        .json();
    },
    enabled: Boolean(query),
  });

  const submitSearch = (query) => {
    setQuery(query);
  };

  return (
    <Layout>
      <Routes>
        <Route
          index
          element={
            <>
              <TitleBar loading={lists.isLoading} title="Lists" />
              <SearchBar onSubmit={submitSearch} />
              <Layout.Content>
                {lists.data ? (
                  <Show
                    data={lists.data.lists}
                    successCallback={() => {
                      showAlert({
                        message: "Import started",
                        horizontal: "center",
                        duration: 5000,
                      });
                    }}
                  />
                ) : (
                  <EmptyIndexMessage
                    icon={<ListBullets size={100} weight="thin" />}
                    text="Perform a search to view lists"
                  />
                )}
              </Layout.Content>
            </>
          }
        />
      </Routes>
    </Layout>
  );
}
