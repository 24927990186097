import { useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import { alpha } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

import { FORM_PAPER_SX } from "@/constants";
import { centsToPrice, priceToCents } from "@/lib/helpers";

export default function RefundItem({
  lineItem,
  handleAdjustment,
  toggleSelect,
}) {
  const [amount, setAmount] = useState(centsToPrice(lineItem.priceToPay));
  const DISABLED = !lineItem.selected;

  return (
    <Paper
      elevation={1}
      sx={[
        {
          ...FORM_PAPER_SX,
          backgroundColor(theme) {
            return alpha(theme.palette.background.paper, DISABLED ? 0.75 : 1);
          },
        },
        DISABLED
          ? {
              boxShadow: "0 1px 1px rgba(0,0,0, 0.075)",
            }
          : {
              boxShadow: "0 1px 5px rgba(0,0,0, 0.15)",
            },
      ]}
    >
      <Grid size={12}>
        <FormControl error={false} sx={{ width: "100%" }}>
          <TextField
            label="Order Item"
            disabled={DISABLED}
            value={lineItem.catalogueItem.name}
          ></TextField>
        </FormControl>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid size={6}>
          <FormControl error={false} sx={{ width: "100%" }}>
            <TextField
              required
              disabled={DISABLED}
              label="Refund Amount"
              onChange={(event) => {
                setAmount(event.target.value);
                handleAdjustment(lineItem, priceToCents(event.target.value));
              }}
              value={amount}
            ></TextField>
          </FormControl>
        </Grid>
        <Grid size={6}>
          <FormControl error={false} sx={{ width: "100%" }}>
            <Box sx={{ pt: 0.5 }}>
              <ButtonGroup size="small" variant="outlined" disabled={DISABLED}>
                <Button
                  onClick={() => {
                    setAmount(centsToPrice(lineItem.priceToPay * 0.15));
                    handleAdjustment(lineItem, lineItem.priceToPay * 0.15);
                  }}
                  value={15}
                >
                  15%
                </Button>
                <Button
                  onClick={() => {
                    setAmount(centsToPrice(lineItem.priceToPay * 0.5));
                    handleAdjustment(lineItem, lineItem.priceToPay * 0.5);
                  }}
                  value={50}
                >
                  50%
                </Button>
                <Button
                  onClick={() => {
                    setAmount(centsToPrice(lineItem.priceToPay * 0.7));
                    handleAdjustment(lineItem, lineItem.priceToPay * 0.7);
                  }}
                  value={70}
                >
                  70%
                </Button>
                <Button
                  onClick={() => {
                    setAmount(centsToPrice(lineItem.priceToPay));
                    handleAdjustment(lineItem, lineItem.priceToPay);
                  }}
                  value={100}
                >
                  100%
                </Button>
              </ButtonGroup>
            </Box>
          </FormControl>
        </Grid>
      </Grid>
      <br />
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          flexGrow: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          sx={{ borderRadius: 10 }}
          variant="contained"
          startIcon={DISABLED ? <AddCircleIcon /> : <HighlightOffIcon />}
          onClick={() => {
            if (!DISABLED) {
              setAmount(centsToPrice(lineItem.priceToPay));
            }
            toggleSelect(lineItem);
          }}
        >
          {DISABLED ? "Add Line Item" : "Remove Line Item"}
        </Button>
      </div>
    </Paper>
  );
}
