const BASE = import.meta.env.VITE_API_ENDPOINT;

const routes = {
  post() {
    return `${BASE}/coupons/`;
  },

  index() {
    return `${BASE}/coupons/list`;
  },

  show(id: string) {
    return `${BASE}/coupons/${id}`;
  },

  validate(id: string) {
    return `${BASE}/coupons/validate/${id}`;
  },

  patch(id: string) {
    return `${BASE}/coupons/${id}`;
  },

  delete(id: string) {
    return `${BASE}/coupons/${id}`;
  },
} as const;

export default routes;
