import { useQuery } from "@tanstack/react-query";

import type { TInventory } from "./types";
import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import Inventory from "./index";

export const useInventoryItem = (
  id: string,
  options: Pick<UseQueryOptions, "enabled" | "placeholderData"> = {},
): UseQueryResult<TInventory, Error> => {
  return useQuery({
    queryKey: Inventory.findById.queryKey(id),
    queryFn() {
      return Inventory.findById.queryFn(id);
    },
    ...options,
  });
};

export const useAvailbleInventory = (
  partnerId: string,
  catalogueId: string,
) => {
  return useQuery({
    queryKey: Inventory.findAvailable.queryKey(partnerId, catalogueId),
    queryFn() {
      return Inventory.findAvailable.queryFn(partnerId, catalogueId);
    },
    enabled: Boolean(catalogueId),
  });
};

export const useInventoryForCatalogueItem = (id: string) => {
  return useQuery({
    queryKey: Inventory.findAllByCatalogueId.queryKey(id),
    queryFn() {
      return Inventory.findAllByCatalogueId.queryFn(id);
    },
    enabled: Boolean(id),
  });
};
