import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Stack from "@mui/material/Stack";

import { NavigationDrawer } from "@/components/NavigationDrawer";
import { NiceModal } from "@/components/NiceModal";
import { NavDrawerContext } from "@/context/NavDrawerContext";
import clientRoutes from "@/lib/client-routes";
import { datadogInit } from "@/lib/datadog-init";
import Applications from "@/routes/Applications";
import Catalogue from "@/routes/Catalogue";
import Coupons from "@/routes/Coupons";
import Insights from "@/routes/Insights";
import InventorySearch from "@/routes/InventorySearch";
import Login from "@/routes/Login";
import MarketingLists from "@/routes/MarketingLists";
import MarketingPending from "@/routes/MarketingPending";
import MentorAssignments from "@/routes/MentorAssignments";
import MentorSearchCatalogue from "@/routes/MentorSearch/catalogue";
import MentorSearchEmail from "@/routes/MentorSearch/email";
import Orders from "@/routes/Orders";
import Partners from "@/routes/Partners";
import Profiles from "@/routes/Profiles";
import Scholarships from "@/routes/Scholarships";
import Settings from "@/routes/Settings";
import Status from "@/routes/Status";

export default function App() {
  useEffect(() => {
    datadogInit();
  }, []);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const openDrawer = function () {
    setDrawerOpen(true);
  };
  const navDrawerState = {
    drawerOpen,
    setDrawerOpen,
    openDrawer,
  };

  const WithNav = function WithNav({ element: Component }) {
    return (
      <Stack data-test="layout" direction="row" sx={{ minHeight: "100vh" }}>
        <NavigationDrawer open={drawerOpen} setOpen={setDrawerOpen} />
        <Component />
      </Stack>
    );
  };

  return (
    <NavDrawerContext.Provider value={navDrawerState}>
      <BrowserRouter>
        <NiceModal.Provider>
          <Routes>
            <Route element={<Login />} path={clientRoutes.login.indexPath} />
            <Route
              element={<WithNav element={InventorySearch} />}
              path={clientRoutes.inventorySearch.indexPath}
            />
            <Route
              element={<WithNav element={MarketingLists} />}
              path={clientRoutes.marketingLists.indexPath}
            />
            <Route
              element={<WithNav element={MarketingPending} />}
              path={clientRoutes.marketingLists.pendingPath}
            />
            <Route
              element={<WithNav element={MentorSearchEmail} />}
              path={clientRoutes.mentorSearch.email.indexPath}
            />
            <Route
              element={<WithNav element={MentorSearchCatalogue} />}
              path={clientRoutes.mentorSearch.catalogue.indexPath}
            />
            <Route
              element={<WithNav element={MarketingPending} />}
              path={clientRoutes.marketingLists.pendingPath}
            />
            <Route
              element={<WithNav element={MentorAssignments} />}
              path={clientRoutes.mentorAssignments.indexPath}
            />
            <Route
              element={<WithNav element={Profiles} />}
              path={clientRoutes.profiles.indexPath}
            />
            <Route
              element={<WithNav element={Partners} />}
              path={clientRoutes.partners.indexPath}
            />
            <Route
              element={<WithNav element={Catalogue} />}
              path={clientRoutes.catalogue.indexPath}
            />
            <Route
              element={<WithNav element={Coupons} />}
              path={clientRoutes.coupons.indexPath}
            />
            <Route
              element={<WithNav element={Applications} />}
              path={clientRoutes.applications.indexPath}
            />
            <Route
              element={<WithNav element={Scholarships} />}
              path={clientRoutes.scholarships.indexPath}
            />
            <Route
              element={<WithNav element={Orders} />}
              path={clientRoutes.orders.indexPath}
            />
            <Route
              element={<WithNav element={Insights} />}
              path={clientRoutes.insights.indexPath}
            />
            <Route
              element={<WithNav element={Status} />}
              path={clientRoutes.status.indexPath}
            />
            <Route
              element={<WithNav element={Settings} />}
              path={clientRoutes.settings.indexPath}
            />
            <Route
              path="/"
              element={<Navigate to={clientRoutes.inventorySearch.index()} />}
            />
          </Routes>
        </NiceModal.Provider>
      </BrowserRouter>
    </NavDrawerContext.Provider>
  );
}
