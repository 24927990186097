import { useId } from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import type { TPartner } from "@/models/partner/types";
import type { SelectProps } from "@mui/material/Select";

export const PartnerSelect = function PartnerSelect({
  partners,
  allPartners = false,
  value = "0",
  ...rest
}: SelectProps<string> & {
  partners: TPartner[];
  allPartners?: boolean;
  value?: string;
}) {
  const id = useId();

  return (
    <FormControl>
      <InputLabel id={useId + "-label"}>Partner</InputLabel>

      <Select
        label="Partner"
        labelId={useId + "-label"}
        defaultValue={value || "0"}
        name="partner_id"
        value={value || "0"}
        sx={{ width: "100%" }}
        id={id}
        {...rest}
      >
        {allPartners ? (
          <MenuItem key="allPartners" value="0">
            All Partners
          </MenuItem>
        ) : (
          <MenuItem key="choosePartner" value="0">
            Choose Partner...
          </MenuItem>
        )}
        {partners.map((partner) => {
          return (
            <MenuItem key={partner._id} value={partner._id}>
              {partner.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
