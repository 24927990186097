const BASE = import.meta.env.VITE_API_ENDPOINT;

const routes = {
  search() {
    return `${BASE}/bls/lists`;
  },
  status() {
    return `${BASE}/bls/status`;
  },
  createJob() {
    return `${BASE}/bls/list`;
  },
} as const;

export default routes;
