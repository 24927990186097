import { useMutation } from "@tanstack/react-query";
import { remove } from "lodash-es";
import { useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { ActionButtons } from "@/components/ActionButtons";
import { Drawer, DrawerManager } from "@/components/Drawer";
import FormErrorMessage from "@/components/FormErrorMessage";
import { FORM_PAPER_SX } from "@/constants";
import { formatDate } from "@/lib/helpers";
import routes from "@/models/marketing-list/routes";

export default DrawerManager.create(function ({
  cancel,
  data,
  successCallback,
}) {
  const [metadata, setMetadata] = useState(
    Object.entries(data.metadata || {}) || [],
  );

  const mutation = useMutation({
    async mutationFn(data) {
      const url = routes.createJob();
      return await window.auth.client
        .post(url, {
          json: {
            ...data,
            metadata: Object.fromEntries(metadata),
          },
        })
        .json();
    },
    onSuccess(data) {
      if (successCallback) {
        successCallback(data);
      }
    },
  });

  const deleteRow = function (index) {
    remove(metadata, function (value, i) {
      return index === i;
    });

    setMetadata([...metadata]);
  };

  const editRow = function (index, key, value) {
    metadata[index] = [key, value];
    setMetadata([...metadata]);
  };

  const addRow = function () {
    setMetadata([...metadata, ["", ""]]);
  };

  return (
    <Drawer cancel={cancel} loading={mutation.isPending} title="Create Import">
      <Paper elevation={1} sx={FORM_PAPER_SX}>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {data.name}
        </Typography>
        <Typography variant="body2">
          ID: {data.listId}
          <br />
          Created: {formatDate(data.createdAt)}
          <br />
          Total: {data.total}
        </Typography>
      </Paper>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          mutation.mutate({
            ...data,
            metadata: Object.fromEntries(metadata),
          });
        }}
      >
        <Paper elevation={1} sx={FORM_PAPER_SX}>
          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={addRow}
          >
            Add Data
          </Button>
          {Boolean(metadata.length) && (
            <>
              <br />
              <br />
            </>
          )}
          {metadata.map(function ([k, v], index) {
            return (
              <Grid sx={{ marginBottom: 3 }} key={index} container spacing={3}>
                <Grid size={{ xs: 12, sm: 5 }}>
                  <TextField
                    fullWidth
                    label="Key"
                    onChange={(e) => {
                      editRow(index, e.target.value, v);
                    }}
                    required
                    value={k}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 5 }}>
                  <TextField
                    fullWidth
                    label="Value"
                    onChange={(e) => {
                      editRow(index, k, e.target.value);
                    }}
                    value={v}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 2 }}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      deleteRow(index);
                    }}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
        </Paper>
        <ActionButtons
          cancel={cancel}
          isSaving={mutation.isPending}
          submitText="Begin Import"
        >
          <FormErrorMessage gutterBottom error={mutation.error} />
        </ActionButtons>
      </form>
    </Drawer>
  );
});
