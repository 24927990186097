import { useTernaryDarkMode } from "usehooks-ts";

import Box from "@mui/material/Box";

import { SegmentedButtons } from "@/components/SegmentedButtons";

export const ColorModeSwitcher = function ColorModeSwitcher() {
  const { ternaryDarkMode, setTernaryDarkMode } = useTernaryDarkMode();

  return (
    <Box
      sx={{
        bgcolor: "background.default",
        color: "text.primary",
      }}
    >
      <SegmentedButtons aria-label="change theme">
        <SegmentedButtons.Button
          selected={ternaryDarkMode === "system"}
          onClick={() => {
            setTernaryDarkMode("system");
          }}
        >
          System Default
        </SegmentedButtons.Button>
        <SegmentedButtons.Button
          selected={ternaryDarkMode === "light"}
          onClick={() => {
            setTernaryDarkMode("light");
          }}
        >
          Light
        </SegmentedButtons.Button>
        <SegmentedButtons.Button
          selected={ternaryDarkMode === "dark"}
          onClick={() => {
            setTernaryDarkMode("dark");
          }}
        >
          Dark
        </SegmentedButtons.Button>
      </SegmentedButtons>
    </Box>
  );
};
