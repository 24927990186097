import { useQuery } from "@tanstack/react-query";

import type { TCatalogueItem } from "./types";
import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import Catalogue from "./";

export function useCatalogueForPartner(
  partnerId,
): UseQueryResult<TCatalogueItem[], Error> {
  return useQuery({
    queryKey: Catalogue.findAllByPartnerId.queryKey(partnerId),
    queryFn() {
      return Catalogue.findAllByPartnerId.queryFn(partnerId);
    },
    enabled: Boolean(partnerId) && partnerId !== "0",
  });
}

export function useCatalogueItem(
  id: string,
  options: Pick<UseQueryOptions, "enabled" | "placeholderData"> = {},
): UseQueryResult<TCatalogueItem, Error> {
  return useQuery({
    queryKey: Catalogue.findById.queryKey(id),
    queryFn() {
      return Catalogue.findById.queryFn(id);
    },
    ...options,
  });
}
