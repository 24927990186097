import { Smiley, SmileyXEyes, Warning } from "@phosphor-icons/react";
import { useState } from "react";
import { useInterval } from "usehooks-ts";

import Glitch from "./Glitch";

const Status = function ({
  status,
  wild = false,
}: {
  status: "GOOD" | "BAD";
  wild?: boolean;
}) {
  const [showX, setShowX] = useState(wild);

  useInterval(
    () => {
      if (wild) {
        setShowX(!showX);
      }
    },
    wild ? (showX ? 600 : 3400) : null,
  );

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "0.75rem" }}>
      {status === "GOOD" ? (
        <>
          {showX ? (
            <SmileyXEyes size={28} color="#6CF841" weight="fill" />
          ) : (
            <Smiley size={28} color="#6CF841" weight="fill" />
          )}
        </>
      ) : (
        <Warning size={28} color="#FF1800" weight="fill" />
      )}

      <div
        style={{
          color: status === "GOOD" ? "#6CF841" : "#FF1800",
        }}
      >
        <Glitch
          active={wild}
          text={status === "GOOD" ? "ALL SYSTEMS NOMINAL" : "ACTIVE FAILURES"}
        />
      </div>
    </div>
  );
};

export default function Marquee({ status }: { status: "GOOD" | "BAD" }) {
  const nums = [1, 2];

  return (
    <div className="marquee">
      <div className="marquee__group">
        {nums.map((i) => (
          <Status key={i} status={status} wild={true} />
        ))}
      </div>
      <div className="marquee__group">
        {nums.map((i) => (
          <Status key={i} status={status} wild={true} />
        ))}
      </div>
    </div>
  );
}
