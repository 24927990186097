import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";

export const UPTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "none",
  fontSize: theme.typography.body1.fontSize,
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
}));
