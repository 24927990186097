import { useQuery } from "@tanstack/react-query";

import Typography from "@mui/material/Typography";

import { Layout } from "@/components/Layout";
import { TitleBar } from "@/components/TitleBar";

import Marquee from "./Marquee";
import Project from "./Project";
import ThirdParty from "./ThirdParty";

const StatusRoute = function () {
  const { isLoading, error, data } = useQuery({
    queryKey: ["status"],
    queryFn() {
      return fetch("https://status.precollegeprograms.org/status").then(
        async (response) => {
          const json = await response.json();
          return json.response;
        },
      );
    },
  });

  const ACTIVE_FAILURES = (data || [])
    .filter((p) => p.latest.status === "ERROR")
    .sort((a, b) => {
      return ("" + a.name).localeCompare(b.name);
    });

  const GLOBAL_STATUS = ACTIVE_FAILURES.length === 0 ? "GOOD" : "BAD";

  return (
    <Layout>
      <TitleBar title="System Status" />
      <Layout.Content>
        <ThirdParty />

        <br />

        <div>
          {isLoading ? <Typography>Loading...</Typography> : null}
          {error ? <Typography>Error: {error.message}</Typography> : null}
          {data ? (
            <>
              <Marquee status={GLOBAL_STATUS} />
              {data.map((project, i) => {
                return <Project key={i} project={project} />;
              })}
            </>
          ) : null}
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default StatusRoute;
