import { Controller } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import FormErrorMessage from "@/components/FormErrorMessage";

const MaxCapacity = ({ control, errors }) => {
  return (
    <FormControl margin="normal">
      <Controller
        control={control}
        name="maxCapacity"
        render={({ field: { ref, ...rest } }) => {
          return (
            <TextField
              error={errors.maxCapacity ? true : false}
              inputRef={ref}
              label="Max Capacity"
              required
              sx={{ maxWidth: "11rem" }}
              variant="outlined"
              {...rest}
            />
          );
        }}
        rules={{ required: "This field is required" }}
      />
      <FormErrorMessage error={errors["maxCapacity"]} />
    </FormControl>
  );
};

export default MaxCapacity;
