import type { TApplication } from "./types";

const BASE = import.meta.env.VITE_API_ENDPOINT as string;

const routes = {
  post(email: string, partnerId: string) {
    return `${BASE}/apps/partner/${partnerId}/email/${email}`;
  },

  patch(email: string, partnerId: string) {
    return `${BASE}/apps/partner/${partnerId}/email/${email}`;
  },

  show(email: string, partnerId: string) {
    return `${BASE}/apps/partner/${partnerId}/email/${email}`;
  },

  status(status: TApplication["status"]) {
    return `${BASE}/apps/status/${status}`;
  },

  timeline(email: string, partnerId: string) {
    return `${BASE}/apps/timeline/email/${email}/partner/${partnerId}`;
  },

  cancel(email: string, partnerId: string) {
    return `${BASE}/apps/partner/${partnerId}/email/${email}`;
  },
} as const;

export default routes;
