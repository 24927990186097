import Grid from "@mui/material/Grid2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import Heading from "../Heading";

import type { TApplication } from "@/models/application/types";

const AcademicDetails = ({
  education,
}: {
  education: TApplication["education"];
}) => {
  return (
    <>
      <Typography component="h3" variant="h6" sx={{ mb: 2 }}>
        Academic Information
      </Typography>
      <Grid container spacing={4}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Heading>High School Graduation Year</Heading>
          {education.gradYear}
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Heading>Current School Name</Heading>
          {education.name}
        </Grid>
      </Grid>

      <br />
      <br />

      <Typography variant="h6" sx={{ mb: 2 }}>
        School Address
      </Typography>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell size="small" sx={{ width: "50%", opacity: 0.7 }}>
                School Country:
              </TableCell>
              <TableCell size="small">{education.address.country}</TableCell>
            </TableRow>
            {education.address.region && (
              <TableRow>
                <TableCell size="small" sx={{ opacity: 0.7 }}>
                  School International Region:
                </TableCell>
                <TableCell size="small">{education.address.region}</TableCell>
              </TableRow>
            )}
            {education.address.state ? (
              <TableRow>
                <TableCell size="small" sx={{ opacity: 0.7 }}>
                  School State:
                </TableCell>
                <TableCell size="small">{education.address.state}</TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell size="small" sx={{ opacity: 0.7 }}>
                School City:
              </TableCell>
              <TableCell size="small">{education.address.city}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <br />
    </>
  );
};

export default AcademicDetails;
