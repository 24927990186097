import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import { TitleBar } from "@/components/TitleBar";

import SideBarList from "./SidebarList";

const SideBar = function ({
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  supTitle,
  scholarshipsQuery,
}) {
  const theme = useTheme();

  return (
    <>
      <TitleBar
        loading={scholarshipsQuery.isLoading}
        title="Scholarships"
        supTitle={supTitle}
      />

      {scholarshipsQuery.data ? (
        <SideBarList scholarships={scholarshipsQuery.data} />
      ) : null}

      {scholarshipsQuery.isError ? (
        <div style={{ textAlign: "center" }}>
          ⚠️ <em>Could not fetch scholarships</em>
        </div>
      ) : null}

      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          bgcolor: "rgb(255,255,255)",
          borderTop: "1px solid #e7e5f5",
          px: { xs: 7, md: 2 },
          py: 2,
          zIndex: 2,
          "@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)":
            {
              backdropFilter: "blur(6px)",
              bgcolor: "rgba(255,255,255, 0.6)",
              ...theme.applyStyles("dark", {
                bgcolor: "rgba(18,18,18, 0.6)",
              }),
            },
          ...theme.applyStyles("dark", {
            bgcolor: "rgb(18,18,18)",
            borderTop: "1px solid #333",
          }),
        }}
      >
        <LoadingButton
          disabled={!hasNextPage}
          loading={isFetchingNextPage}
          onClick={() => {
            if (hasNextPage) {
              fetchNextPage();
            }
          }}
          sx={{ width: "100%" }}
          variant="contained"
        >
          Load More
        </LoadingButton>
      </Box>
    </>
  );
};

export default SideBar;
