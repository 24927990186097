import routes from "./routes";

import type { TProfile } from "./types";

/* Finders
/* ------------------------------------------------------------------ */

const find = {
  queryFn: async function (email: string, partnerId: string) {
    const url = routes.show(email, partnerId);
    return await window.auth.client.get(url).json<TProfile>();
  },
  queryKey(email: string, partnerId: string) {
    return ["profile", email, partnerId] as const;
  },
};

const findAllByEmail = {
  queryFn: async function (email: string) {
    const url = routes.show(email, "all");
    return await window.auth.client.get(url).json<TProfile[]>();
  },
  queryKey(email: string) {
    return ["profile", email, "all"] as const;
  },
};

export default {
  find,
  findAllByEmail,
};
