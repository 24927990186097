import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoopIcon from "@mui/icons-material/Loop";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Glitch from "./Glitch";
import { getBGColor } from "./helpers";
import LastDeploys from "./LastDeploys";

import type { Project } from "./types";

export default function ProjectComponent({ project }: { project: Project }) {
  const STATUS = project.latest.status;

  return (
    <Accordion
      defaultExpanded={STATUS === "ERROR"}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        aria-controls={`panel${project.id}-content`}
        expandIcon={<ExpandMoreIcon />}
        id={`panel${project.id}-header`}
        sx={{
          display: "flex",
        }}
      >
        <Glitch
          active={STATUS === "ERROR"}
          style={{
            flexGrow: 1,
            fontWeight: "bold",
            color: STATUS === "ERROR" ? "#d00" : "",
          }}
          text={project.name}
        />
        <span
          style={{
            color: "#fff",
            padding: "0.1rem 0.6rem",
            borderRadius: "1rem",
            backgroundColor: getBGColor(STATUS),
            fontSize: "0.9rem",
            marginLeft: "0.35rem",
          }}
        >
          {STATUS}
        </span>
        <span
          style={{
            flexBasis: "140px",
            opacity: 0.75,
            paddingLeft: "0.75rem",
          }}
        >
          {project.latest.time}
        </span>
      </AccordionSummary>
      <AccordionDetails id={`panel${project.id}-content`}>
        <Typography sx={{ mb: 1 }}>
          <Button
            variant="outlined"
            href={project.url}
            endIcon={<ArrowOutwardIcon />}
            target="_blank"
            sx={{ mr: 1 }}
          >
            View Site
          </Button>
          <Button variant="outlined" href="#" endIcon={<LoopIcon />}>
            Rebuild Site
          </Button>
        </Typography>
        {project.lastSuccessful ? (
          <div
            style={{
              display: "flex",
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
            }}
          >
            <div style={{ flexGrow: 1 }}>
              <span>Last successful deploy: {project.lastSuccessful.time}</span>
            </div>
          </div>
        ) : null}
        {project.deployments.length ? (
          <>
            <div style={{ paddingTop: "0.5rem", marginBottom: "0.5rem" }}>
              Previous deploys:
            </div>
            <LastDeploys
              deployments={project.deployments.slice(0, 5)}
              visible={true}
            />
          </>
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
}
