import List from "@mui/material/List";
import Stack from "@mui/material/Stack";

import ScholarshipItemButton from "./ScholarshipItemButton";

const SideBarList = ({ scholarships }) => {
  const ships = scholarships.pages?.length
    ? [...scholarships.pages.map((p) => p.results)].flat()
    : [];

  return (
    <Stack>
      {ships.length ? (
        <List sx={{ paddingTop: 1, paddingRight: 1.5, paddingLeft: 1.5 }}>
          {ships.map((s) => (
            <ScholarshipItemButton key={s._id} scholarship={s} />
          ))}
        </List>
      ) : (
        <div style={{ textAlign: "center" }}>
          <em>No scholarship requests</em>
        </div>
      )}
    </Stack>
  );
};

export default SideBarList;
