export const columns = [
  {
    id: "studentName",
    label: "Student",
    minWidth: 170,
  },
  {
    id: "orderId",
    label: "Order ID",
    minWidth: 100,
  },
  {
    id: "location",
    label: "Location",
    minWidth: 100,
  },
  {
    id: "mentor",
    label: "Mentor",
    minWidth: 100,
  },
];
