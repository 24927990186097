import { formatDistanceToNow } from "date-fns";
import { Flag, Warning } from "phosphor-react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import clientRoutes from "@/lib/client-routes";

const AppItemButton = ({ application }) => {
  const to = clientRoutes.applications.show(
    application.student.email,
    application.partner._id,
  );
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  const ListItemSX = {
    borderRadius: 2,
    paddingLeft: 2,
  };

  return (
    <ListItemButton
      className={match ? "Mui-selected" : ""}
      component={Link}
      to={to}
      state={{ application }}
      sx={ListItemSX}
    >
      <ListItemText
        primary={
          <>
            {application.student.email}
            <br />
            {application.student.firstName &&
              application.student.lastName &&
              `${application.student.firstName} ${application.student.lastName}`}
          </>
        }
        primaryTypographyProps={{
          variant: "body2",
          overflow: "hidden",
          fontWeight: "500",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
        secondary={
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {formatDistanceToNow(new Date(application.completedAt), {
                addSuffix: true,
              })}
            </span>
            {application.flagged ? (
              application.flagSeverity === "red" ? (
                <Flag
                  color="#d00"
                  size="16"
                  style={{ flexShrink: 0 }}
                  weight="fill"
                />
              ) : (
                <Warning
                  color="#FFB81C"
                  size="20"
                  style={{ flexShrink: 0 }}
                  weight="fill"
                ></Warning>
              )
            ) : null}
          </span>
        }
      />
    </ListItemButton>
  );
};

export default AppItemButton;
