import Box from "@mui/material/Box";

import { Drawer, DrawerManager } from "@/components/Drawer";
import CouponShow from "@/routes/Coupons/show";

export default DrawerManager.create(function ({ couponID }) {
  return (
    <Drawer title="Coupon">
      {couponID ? (
        <Box sx={{ p: 2, bgcolor: "background.paper" }}>
          <CouponShow couponID={couponID} />
        </Box>
      ) : null}
    </Drawer>
  );
});
