import inventoryRoutes from "@/models/inventory/routes";

import routes from "./routes";

import type { TEnrollment, TEnrollmentFindByCohortResponse } from "./types";

/* Finders
/* ------------------------------------------------------------------ */

const findByCohort = {
  queryFn: async function (id: string) {
    const url = inventoryRoutes.enrollments(id);
    return await window.auth.client
      .get(url)
      .json<TEnrollmentFindByCohortResponse>();
  },
  queryKey(id: string) {
    return ["enrollments-by-cohort", id] as const;
  },
};

const findByUser = {
  queryFn: async function (partnerId: string, email: string) {
    const url = routes.activeEnrollments(partnerId, email);
    return await window.auth.client.get(url).json<TEnrollment>();
  },
  queryKey(partnerId: string, email: string) {
    return ["enrollments", partnerId, email] as const;
  },
};

const fetchLmsEnrollment = {
  queryFn: async function ({
    partnerId,
    inventoryId,
    email,
  }: {
    partnerId: string;
    inventoryId: string;
    email: string;
  }) {
    const url = routes.lmsEnrollment(partnerId, inventoryId, email);
    return await window.auth.client.get(url).json();
  },
  queryKey({
    partnerId,
    inventoryId,
    email,
  }: {
    partnerId: string;
    inventoryId: string;
    email: string;
  }) {
    return [
      "lmsEnrollment",
      {
        partnerId,
        inventoryId,
        email,
      },
    ] as const;
  },
};

const fetchLmsSections = {
  queryFn: async function ({
    partnerId,
    inventoryId,
  }: {
    partnerId: string;
    inventoryId: string;
  }) {
    const url = routes.sections(partnerId, inventoryId);
    return await window.auth.client.get(url).json();
  },
  queryKey({
    partnerId,
    inventoryId,
  }: {
    partnerId: string;
    inventoryId: string;
  }) {
    return [
      "lmsSections",
      {
        partnerId,
        inventoryId,
      },
    ] as const;
  },
};

/* Actions
/* ------------------------------------------------------------------ */

const deleteEnrollment = async function (data, inventoryId, studentEmail) {
  const url = routes.deleteEnrollment(inventoryId, studentEmail);
  return await window.auth.client
    .delete(url, {
      json: data,
    })
    .json();
};

const withdrawEnrollment = async function (data, inventoryId, studentEmail) {
  const url = routes.withdrawEnrollment(inventoryId, studentEmail);
  return await window.auth.client
    .patch(url, {
      json: data,
    })
    .json();
};

const switchLmsSections = async function (
  { partnerId, inventoryId, email },
  data,
) {
  const url = routes.switchSections(partnerId, inventoryId, email);
  const resp = await window.auth.client.patch(url, { json: data }).json();
  return resp;
};

export default {
  findByCohort,
  findByUser,
  fetchLmsEnrollment,
  fetchLmsSections,

  deleteEnrollment,
  withdrawEnrollment,
  switchLmsSections,
};
