import { useQuery } from "@tanstack/react-query";

import type { TCoupon } from "./types";
import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import Coupon from "./index";

export const useCoupon = (
  id: string,
  options: Pick<UseQueryOptions, "enabled" | "placeholderData"> = {},
): UseQueryResult<TCoupon, Error> => {
  return useQuery({
    queryKey: Coupon.findById.queryKey(id),
    queryFn() {
      return Coupon.findById.queryFn(id);
    },
    ...options,
  });
};

export const useCoupons = () => {
  return useQuery({
    queryKey: Coupon.findAll.queryKey(),
    queryFn() {
      return Coupon.findAll.queryFn();
    },
  });
};
