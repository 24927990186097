import { find, get } from "lodash-es";
import { useState } from "react";

import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid2";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";

import { PartnerSelect } from "@/components/PartnerSelect";
import { useCatalogueForPartner } from "@/models/catalogue/hooks";
import { usePartners } from "@/models/partner/hooks";

import EmailInputField from "./EmailInputField";

const getNameFromID = function (all, _id) {
  return get(find(all, ["_id", _id]), "name");
};

const SetScope = ({ setValue, partnerID, watch }) => {
  const scopedItems = watch("scopedItems");
  const partners = usePartners();
  const [selectedPartnerID, setSelectedPartnerID] = useState(partnerID);
  const catalogueItems = useCatalogueForPartner(selectedPartnerID);

  const handlePartnerChange = function (e) {
    const partnerID = e.target.value;
    const partnerName = getNameFromID(partners.data, partnerID);
    if (partnerID) {
      setValue("partner", { _id: partnerID, name: partnerName });
      setValue("scopedItems", []); //if the partner has to change, we have to clear all the scoped programs
      setSelectedPartnerID(partnerID);
    }
  };

  const labelObject = {};

  catalogueItems.data?.forEach((catalogueItem) => {
    labelObject[catalogueItem._id] = catalogueItem.name;
  });

  const strippedCatalogueItems = catalogueItems.data?.map((item) => {
    return { _id: item._id, name: item.name };
  });

  const handleOnChange = (event) => {
    const selectedIDs = event.target.value;
    if (selectedIDs.includes(1)) {
      setValue("scopedItems", []);
    } else if (selectedIDs.includes(0)) {
      setValue("scopedItems", strippedCatalogueItems);
    } else {
      const items = selectedIDs.map((_id) => {
        return {
          _id,
          name: getNameFromID(catalogueItems.data, _id),
        };
      });
      setValue("scopedItems", items);
    }
  };

  return (
    <>
      <Typography gutterBottom variant="body1" fontWeight="500">
        Catalogue Items
      </Typography>

      <Grid container>
        <Grid size={12}>
          {partners.data ? (
            <FormControl sx={{ minWidth: "200px" }}>
              <PartnerSelect
                name="scopedPartner"
                onChange={handlePartnerChange}
                partners={partners.data}
                value={selectedPartnerID}
              />
            </FormControl>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
              <CircularProgress size="1.5rem" sx={{ mr: 1 }} />
              <Typography variant="body2">Loading Partners...</Typography>
            </Box>
          )}
          {catalogueItems.data && catalogueItems.data.length ? (
            <>
              <Select
                sx={{ width: "100%", mt: 1 }}
                id="scopedItems"
                multiple
                name="scopedItems"
                labelId="Scope Catalogue Items"
                value={scopedItems?.map((item) => {
                  return item._id;
                })}
                onChange={(event) => handleOnChange(event)}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((_id) => (
                      <Chip
                        key={_id}
                        label={getNameFromID(catalogueItems.data, _id)}
                      />
                    ))}
                  </Box>
                )}
              >
                <MenuItem key={0} value={0}>
                  Select All Items
                </MenuItem>
                <MenuItem key={1} value={1}>
                  Unselect All Items
                </MenuItem>
                {catalogueItems.data.map((item) => {
                  return (
                    <MenuItem key={item._id} value={item._id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </>
          ) : (
            <Typography
              sx={{
                border: "1px dashed rgba(153,153,153, 0.6)",
                marginTop: "0.5rem",
                borderRadius: 1.5,
                px: 2,
                py: 1,
                opacity: 0.8,
                fontStyle: "italic",
              }}
            >
              No Catalogue items available
            </Typography>
          )}
        </Grid>

        <Grid sx={{ mt: 3 }} size={12}>
          <Box>
            <Typography gutterBottom variant="body1" fontWeight="500">
              Users
            </Typography>
            <Box sx={{ mt: 1 }}>
              <EmailInputField
                setValue={setValue}
                fieldId={"scopedUsers"}
                fieldName={"scopedUsers"}
                watch={watch}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SetScope;
