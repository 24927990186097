import { useQuery } from "@tanstack/react-query";

import type { TMentor } from "./types";
import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import Mentor from "./index";

export function useMentorByEmail(
  email: string,
  options: Pick<UseQueryOptions, "enabled" | "placeholderData"> = {},
): UseQueryResult<TMentor, Error> {
  return useQuery({
    queryKey: Mentor.findByEmail.queryKey(email),
    queryFn() {
      return Mentor.findByEmail.queryFn(email);
    },
    enabled: Boolean(email),
    ...options,
  });
}

export function useAllMentors(): UseQueryResult<TMentor[], Error> {
  return useQuery({
    queryKey: Mentor.findAll.queryKey(),
    queryFn() {
      return Mentor.findAll.queryFn();
    },
  });
}

export function useMentorsByCatalogueItemID(
  id: string,
): UseQueryResult<TMentor[], Error> {
  return useQuery({
    queryKey: Mentor.findAllByCatalogueItemId.queryKey(id),
    queryFn() {
      return Mentor.findAllByCatalogueItemId.queryFn(id);
    },
    enabled: Boolean(id),
  });
}

export function useMentorsAssignedToInventoryItem(
  id: string,
): UseQueryResult<TMentor[], Error> {
  return useQuery({
    queryKey: Mentor.findAllByInventoryId.queryKey(id),
    queryFn() {
      return Mentor.findAllByInventoryId.queryFn(id);
    },
    enabled: Boolean(id),
  });
}
