import { useMutation } from "@tanstack/react-query";

import { Drawer, DrawerManager } from "@/components/Drawer";
import Order from "@/models/order";
import RefundForm from "@/routes/Orders/components/OrderDetails/Refunds/RefundForm";

export default DrawerManager.create(function ({
  cancel,
  data,
  orderID,
  errorCallback,
  successCallback,
}) {
  const mutation = useMutation({
    mutationFn(data) {
      return Order.refund(orderID, data);
    },
    onSuccess(data) {
      if (successCallback) {
        successCallback(data);
      }
    },
    onError(error) {
      if (errorCallback) {
        errorCallback(error);
      }
    },
  });

  const onSubmit = function (newValues) {
    mutation.mutate(newValues);
  };

  return (
    <Drawer loading={mutation.isPending} title="Refund">
      <RefundForm
        data={data}
        cancel={cancel}
        isSaving={mutation.isPending}
        onSubmit={onSubmit}
        saveError={mutation.error}
      />
    </Drawer>
  );
});
