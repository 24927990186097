import { useMutation } from "@tanstack/react-query";

import { Drawer, DrawerManager } from "@/components/Drawer";
import InventoryFormNew from "@/components/InventoryForm/InventoryFormNew";
import Inventory from "@/models/inventory";

export default DrawerManager.create(function ({
  cancel,
  partners,
  successCallback,
}) {
  const mutation = useMutation({
    mutationFn: Inventory.save,
    onSuccess(data) {
      if (successCallback) {
        successCallback(data);
      }
    },
  });

  return (
    <Drawer loading={mutation.isPending} title="New Inventory Item">
      <InventoryFormNew
        cancel={cancel}
        data={{}}
        isSaving={mutation.isPending}
        onSubmit={(newValues) => {
          mutation.mutate(newValues);
        }}
        partners={partners}
        saveError={mutation.error}
      />
    </Drawer>
  );
});
