import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";

import { TitleBar } from "@/components/TitleBar";

import SideBarList from "./SideBarList";

import type { TApplicationFindAllByStatusResponse } from "@/models/application/types";
import type {
  InfiniteData,
  UseInfiniteQueryResult,
} from "@tanstack/react-query";

export default function SideBar({
  applicationsQuery,
  supTitle,
}: {
  applicationsQuery: UseInfiniteQueryResult<
    InfiniteData<TApplicationFindAllByStatusResponse, unknown>,
    Error
  >;
  supTitle: string;
}) {
  return (
    <>
      <TitleBar
        loading={applicationsQuery.isLoading}
        title="Applications"
        supTitle={supTitle}
      />
      {applicationsQuery.data ? (
        <SideBarList applicationsQuery={applicationsQuery} />
      ) : null}
      {applicationsQuery.isError ? (
        <div style={{ textAlign: "center" }}>
          ⚠️ <em>Could not fetch applications</em>
        </div>
      ) : null}
      <Box
        sx={(theme) => ({
          position: "sticky",
          bottom: 0,
          bgcolor: "rgb(255,255,255)",
          borderTop: "1px solid #e7e5f5",
          px: { xs: 7, md: 2 },
          py: 2,
          zIndex: 2,
          "@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)":
            {
              backdropFilter: "blur(6px)",
              bgcolor: "rgba(255,255,255, 0.6)",
              ...theme.applyStyles("dark", {
                bgcolor: "rgba(18,18,18, 0.6)",
              }),
            },
          ...theme.applyStyles("dark", {
            bgcolor: "rgb(18,18,18)",
            borderTop: "1px solid #333",
          }),
        })}
      >
        <LoadingButton
          disabled={!applicationsQuery.hasNextPage}
          loading={applicationsQuery.isFetchingNextPage}
          onClick={() => {
            if (applicationsQuery.hasNextPage) {
              applicationsQuery.fetchNextPage();
            }
          }}
          sx={{ width: "100%" }}
          variant="contained"
        >
          Load More
        </LoadingButton>
      </Box>
    </>
  );
}
