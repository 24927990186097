import { useMutation } from "@tanstack/react-query";

import { Drawer, DrawerManager } from "@/components/Drawer";
import Catalogue from "@/models/catalogue";

import CatalogueForm from "./Form";

import type { TCatalogueItem } from "@/models/catalogue/types";
import type { TPartner } from "@/models/partner/types";
import type { UseQueryResult } from "@tanstack/react-query";

export const CatalogueFormDrawer = DrawerManager.create(function ({
  cancel,
  data,
  errorCallback,
  partners,
  successCallback,
}: {
  cancel: () => void;
  data: Partial<TCatalogueItem>;
  errorCallback?: (error: unknown) => void;
  partners: UseQueryResult<TPartner[], Error>;
  successCallback: (data: TCatalogueItem) => void;
}) {
  const EXISTING_ITEM = Boolean(data?._id);

  const mutation = useMutation({
    mutationFn: Catalogue.saveItem,
    onSuccess(data) {
      successCallback?.(data);
    },
    onError(error) {
      errorCallback?.(error);
    },
  });

  return (
    <Drawer
      loading={mutation.isPending}
      title={`${EXISTING_ITEM ? "Edit" : "New"} Catalogue Item`}
    >
      <CatalogueForm
        cancel={cancel}
        data={data}
        isEdit={EXISTING_ITEM}
        isSaving={mutation.isPending}
        onSubmit={(newValues) => {
          mutation.mutate(newValues);
        }}
        partners={partners}
        saveError={mutation.error}
      />
    </Drawer>
  );
});
