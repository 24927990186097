import { useEffect, useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";

import { EditTextNotepad } from "@/components/EditTextNotepad";

const EssayAndFileTab = ({ appData, isSaving, save }) => {
  const [essay, setEssay] = useState(appData.essay);

  useEffect(() => {
    setEssay(appData.essay || null);
  }, [appData]);

  const handleSave = function () {
    const newData = { ...appData };

    if (essay !== appData.essay) {
      newData.essay = essay;
    }

    save(newData);
  };

  const handleEssayChange = (e) => {
    setEssay(e.target.value);
  };

  return (
    <>
      <EditTextNotepad
        color="purple"
        onChange={handleEssayChange}
        title="Application Essay"
        value={essay}
      />

      <br />

      <div>
        <LoadingButton
          onClick={handleSave}
          color="primary"
          loading={isSaving}
          sx={{ minWidth: "6rem" }}
          variant="contained"
        >
          Save
        </LoadingButton>
      </div>
    </>
  );
};

export default EssayAndFileTab;
