import type { TScholarship } from "./types";

const BASE = import.meta.env.VITE_API_ENDPOINT;

const routes = {
  post() {
    return `${BASE}/scholarships/`;
  },

  show(email: string, partnerId: string) {
    return `${BASE}/scholarships/email/${email}/partner/${partnerId}`;
  },

  patch() {
    return `${BASE}/scholarships/email`;
  },

  cohorts(id: string) {
    return `${BASE}/programs/${id}/cohorts`;
  },

  changeStatus() {
    return `${BASE}/scholarships/status`;
  },

  queue() {
    return `${BASE}/scholarships/queue`;
  },

  status(status: TScholarship["status"]) {
    return `${BASE}/scholarships/status/${status}`;
  },

  timeline(email: string, partnerId: string) {
    return `${BASE}/scholarships/timeline/email/${email}/partner/${partnerId}`;
  },
} as const;

export default routes;
