/*
inventory object example
{
	"0": {
		"_id": "304cd276-abe2-46b8-8508-80168e0de7d8",
		"startDate": "2024-11-23T00:00:00.000-08:00",
		"endDate": "2024-12-21T00:00:00.000-08:00",
		"applicationDeadline": "2024-11-16T23:59:59.999-08:00",
		"enrollmentDeadline": "2024-11-16T23:59:59.999-08:00",
		"lmsStartDate": "2024-11-20T00:00:00.000-08:00",
		"catalogueItem": {
			"_id": "490f2163-3a58-4a98-ae10-acf1b4d55b2b",
			"name": "International Law: Protecting the World’s Interests",
			"msrp": 149503,
			"type": "program",
			"internalName": "International Law"
		},
		"partner": {
			"_id": "709eedce-7712-484a-b1d8-d37ca530599f",
			"name": "Georgetown University"
		},
		"cohortLength": "4 weeks",
		"maxCapacity": 60,
		"redemptionCount": 10,
		"retailPrice": 149500,
		"name": "Sat, Nov 23, 2024 - Sat, Dec 21, 2024 (4 weeks)",
		"status": "canceled",
		"availableForPurchase": false,
		"availableForEnrollment": false,
		"availableForPublicMarketing": false,
		"lmsEnrollmentRequested": false,
		"createdAt": "2023-10-23T20:12:55.703Z"
	}
}
*/
import MiniSearch from "minisearch";

const GetMiniSearchedData = (data = [], queryParams) => {
  const { keyword, catalogueItem_ids, partner_ids, status, duration } =
      queryParams,
    filteredData = data.filter(
      (item) =>
        (!partner_ids.length || partner_ids.includes(item.partner._id)) &&
        (!catalogueItem_ids.length ||
          catalogueItem_ids.includes(item.catalogueItem._id)) &&
        (!status || status === item.status) &&
        (!duration || item.cohortLength === duration),
    );
  if (!keyword) {
    return filteredData;
  }
  const miniSearch = new MiniSearch({
    fields: ["catalogueItemName", "partnerName"],
    idField: "_id",
    searchOptions: {
      prefix: true,
    },
    storeFields: ["_id"],
  });
  miniSearch.addAll(filteredData);
  const result_ids = miniSearch.search(keyword),
    results = result_ids.map((result_id) =>
      filteredData.find((item) => item._id === result_id.id),
    );
  return results;
};

export default GetMiniSearchedData;
