import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import CouponItemButton from "./CouponItemButton";

const SideBarList = ({ coupons }) => {
  return (
    <Stack>
      <Typography
        mt={2}
        pt={2}
        pb={1}
        px={2}
        variant="overline"
        style={{ opacity: 0.75, fontWeight: 500 }}
      >
        Last 10 Coupons
      </Typography>
      {coupons.length ? (
        <List sx={{ padding: 0 }}>
          {coupons.map((c) => (
            <CouponItemButton key={c.code} coupon={c} />
          ))}
        </List>
      ) : coupons.isLoading ? (
        <div>⚠️ No recent coupons found</div>
      ) : (
        <div />
      )}
    </Stack>
  );
};

export default SideBarList;
