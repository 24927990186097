import Typography from "@mui/material/Typography";

import Order from "./Order";

export default function OrderList({ profileData }) {
  return (
    <>
      {profileData && Boolean(profileData.orders?.length) ? (
        profileData.orders.map((order, i) => {
          return <Order key={i} orderID={order} />;
        })
      ) : (
        <Typography display="block" variant="body1">
          <em>No orders</em>
        </Typography>
      )}
    </>
  );
}
