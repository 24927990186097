import { Link } from "react-router-dom";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import clientRoutes from "@/lib/client-routes";

export default function ResultsTableRow({ mentor }) {
  return (
    <TableRow hover>
      <TableCell sx={{ position: "relative" }}>
        <Link
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
          }}
          to={clientRoutes.mentorSearch.email.show(mentor.email)}
          state={{
            mentor,
          }}
        />
        <strong>{`${mentor.firstName} ${mentor.lastName}`}</strong>
        <br />
        {mentor.email}
      </TableCell>
    </TableRow>
  );
}
