import {
  UP_AUTH_DID_SIGN_OUT,
  authStore,
  setBaseURL,
} from "@kaplan-labs/up-auth-api-client";

import clientRoutes from "@/lib/client-routes";

export const authSetup = function () {
  setBaseURL(import.meta.env.VITE_API_ENDPOINT);
  window.auth = authStore.get();

  const handleSignOut = function () {
    if (window.location.href.match(/login/)) {
      return;
    }
    const $auth = authStore.get();
    authStore.set({
      ...$auth,
      uriBeforeFailure: window.location.href,
    });
    window.location.href = clientRoutes.login.index();
  };

  if (!window.auth.currentUser) {
    handleSignOut();
  }

  document.addEventListener(UP_AUTH_DID_SIGN_OUT, handleSignOut);

  authStore.listen(($auth) => {
    window.auth = $auth;
  });
};
