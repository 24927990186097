import enrollmentRoutes from "@/models/enrollment/routes";

import routes from "./routes";

import type { TOrder } from "./types";

/* Finders
/* ------------------------------------------------------------------ */

const findById = {
  queryFn: async function (id: string) {
    const url = routes.show(id);
    return await window.auth.client.get(url).json<TOrder>();
  },
  queryKey(id: string) {
    return ["orders", id] as const;
  },
};

/* Actions
/* ------------------------------------------------------------------ */

const refund = async function (orderId: string, data) {
  const url = routes.refund(orderId);
  return await window.auth.client
    .post(url, {
      json: data,
    })
    .json();
};

const dropEnrollment = async function (email: string, data) {
  const url = enrollmentRoutes.delete(email);
  return await window.auth.client
    .delete(url, {
      json: data,
    })
    .json();
};

const switchEnrollment = async function (email: string, data) {
  const url = enrollmentRoutes.switch(email);
  return await window.auth.client
    .post(url, {
      json: data,
    })
    .json();
};

export default {
  findById,

  dropEnrollment,
  refund,
  switchEnrollment,
};
