import { Link, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

import { formatDate } from "@/lib/helpers";
import { useInventoryItem } from "@/models/inventory/hooks";

const View = function ({ children, component = "div", ...rest }) {
  return (
    <Card component={component} variant="outlined" {...rest}>
      <Box sx={{ paddingTop: 1, paddingBottom: 2, px: 2 }}>{children}</Box>
    </Card>
  );
};

const InventoryItemDetails = ({ inventoryId }) => {
  const location = useLocation();
  const inventoryItem = useInventoryItem(inventoryId, {
    placeholderData: location.state?.item,
  });

  if (!inventoryItem.data && inventoryItem.isFetching) {
    return (
      <View>
        <Typography variant="overline">
          <Skeleton />
        </Typography>
        <Typography variant="body2">
          <Skeleton />
        </Typography>
      </View>
    );
  }

  if (!inventoryItem?.data) {
    return null;
  }

  return (
    <View
      component={Link}
      to={`/inventory-search/${inventoryId}`}
      style={{ cursor: "pointer", display: "block", textDecoration: "none" }}
    >
      <Typography variant="overline" sx={{ opacity: 0.75 }}>
        {formatDate(inventoryItem.data.startDate)} —{" "}
        {formatDate(inventoryItem.data.endDate)}
      </Typography>
      <Typography variant="body2">
        {inventoryItem.data.catalogueItem.name}
      </Typography>
    </View>
  );
};

export default InventoryItemDetails;
