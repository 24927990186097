import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";

import { formatDate } from "@/lib/helpers";

import {
  TimelineEnrollmentEvent,
  TimelineRefundEvent,
  TimelineSwitchEvent,
} from "./TimelineHelpers";

const OrderTimeline = ({ history }) => {
  if (!history || history.length < 1) return null;

  const getMultiLineEventData = (timelineEvent) => {
    switch (timelineEvent.eventType) {
      case "StudentEnrolled":
        return <TimelineEnrollmentEvent timelineEvent={timelineEvent} />;
      case "StudentUnenrolled":
        return <TimelineEnrollmentEvent timelineEvent={timelineEvent} />;
      case "StudentEnrollmentSwitched":
        return <TimelineSwitchEvent timelineEvent={timelineEvent} />;
      case "OrderRefunded":
        return <TimelineRefundEvent timelineEvent={timelineEvent} />;
      default:
        return null;
    }
  };

  return (
    <Timeline>
      {history.map((timelineEvent, index) => {
        return (
          <TimelineItem key={index}>
            <TimelineOppositeContent
              color="text.secondary"
              style={{ flexBasis: "25%" }}
            >
              <span style={{ fontSize: "0.9rem" }}>
                {formatDate(timelineEvent?.createdAt, "pp")}
              </span>
              <br />
              {formatDate(timelineEvent?.createdAt)}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              {index !== history.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent style={{ flexBasis: "70%" }}>
              <div style={{ fontWeight: 600 }}>{timelineEvent?.message}</div>
              {timelineEvent.eventType !== "OrderCreated"
                ? getMultiLineEventData(timelineEvent)
                : null}
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

export default OrderTimeline;
