import { Link } from "react-router-dom";

/**
 * Solution for making an entire @mui TableCell clickable
 */
export const CellLink = function CellLink({ url }) {
  return (
    <Link
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
      }}
      to={url}
    />
  );
};
