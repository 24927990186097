import { ApplicationStatus } from "@/models/application/constants";
import { CatalogueItemType } from "@/models/catalogue/constants";
import { CouponAmountType, CouponType } from "@/models/coupon/constants";
import { InventoryStatus } from "@/models/inventory/constants";

import { generateEndOfSeasonDate } from "./lib/helpers";

export const NAVIGATION_DRAWER_WIDTH = 240;
export const MAIN_VIEW_WIDTH = NAVIGATION_DRAWER_WIDTH + 40;

export const FORM_PAPER_SX = {
  marginTop: 1,
  marginRight: 2,
  marginBottom: 2,
  marginLeft: 2,
  px: 2,
  py: 2,
  borderRadius: 2,
  boxShadow: "0 1px 1px rgba(0,0,0, 0.075)",
} as const;

export const FORM_PAPER_HEADING_SX = {
  pl: 4,
  pt: 3.5,
  fontWeight: "600",
} as const;
export const FORM_PAPER_SUB_HEADING_SX = {
  mb: 2,
  pl: 4,
  opacity: 0.75,
} as const;

// 347af6
export const PRIMARY_COLOR_LIGHT = "#9134f6";
export const PRIMARY_COLOR_DARK = "#A14DFF";
export const SECONDARY_COLOR_LIGHT = "#006a99";
export const SECONDARY_COLOR_DARK = "#3C93C6";

export const FONT_FAMILY = [
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(",");

export const INVENTORY_TYPE_OPTIONS = [
  { label: "Program", value: CatalogueItemType.Program },
  { label: "College Credit", value: CatalogueItemType.CollegeCredit },
  { label: "Add-On", value: CatalogueItemType.AddOn },
] as const;

export const INVENTORY_TYPE_MAP = {
  [CatalogueItemType.Program]: "Program",
  [CatalogueItemType.CollegeCredit]: "College Credit",
  [CatalogueItemType.AddOn]: "Add-On",
} as const;

export const REFUND_REASONS_OPTIONS = [
  { label: "Cancellation", value: "cancellation" },
  { label: "Illness / Family", value: "illnessFamily" },
  { label: "Make Good / Dissatisfied", value: "makeGood" },
  { label: "No Longer Available", value: "notAvailable" },
  { label: "Opted for In Person", value: "inPerson" },
  { label: "System Issue", value: "systemIssue" },
  { label: "Unauthorized Payment", value: "unauthorizedPayment" },
  { label: "Unknown", value: "unknown" },
  { label: "Other", value: "other" },
] as const;

export const REFUND_REASONS_MAP = {
  cancellation: "Cancellation",
  illnessFamily: "Illness / Family",
  makeGood: "Make Good / Dissatisfied",
  notAvailable: "No Longer Available",
  inPerson: "Opted for In Person",
  systemIssue: "System Issue",
  unauthorizedPayment: "Unauthorized Payment",
  unknown: "Unknown",
  other: "Other",
} as const;

export const STATUS_MAP = {
  userStarted: "user started",
  pendingReview: "pending",
  onHoldCX: "on hold, agent",
  onHoldUser: "on hold, user",
  denied: "denied",
  approved: "approved",
  adminCompleted: "complete",
  abandoned: "abandoned",
} as const;

export const STATUS_COLOR_MAP = {
  userStarted: "grey",
  pendingReview: "grey",
  onHoldCX: "grey",
  onHoldUser: "grey",
  denied: "red",
  approved: "green",
  adminCompleted: "green",
  abandoned: "red",
} as const;

export const SCHOLARSHIP_STATUS_OPTIONS = [
  { label: "Pending", value: "pendingReview" },
  { label: "No action, complete", value: "adminCompleted" },
  { label: "On hold, awaiting agent", value: "onHoldCX" },
  { label: "Approve Now", value: "approved" },
  { label: "On hold, awaiting user", value: "onHoldUser" },
  { label: "Deny Now", value: "denied" },
] as const;

export const ALL_INVENTORY_DURATION_WEEKS = [1, 2, 4, 6, 8, 9, 10, 12] as const;

export const ALL_INVENTORY_DURATION_OPTIONS = ALL_INVENTORY_DURATION_WEEKS.map(
  (item) => {
    if (item === 1) return { value: `${item} week` };
    return { value: `${item} weeks` };
  },
);

export const PROGRAM_INVENTORY_DURATION_WEEKS = [1, 2, 4] as const;

export const PROGRAM_INVENTORY_DURATION_OPTIONS =
  PROGRAM_INVENTORY_DURATION_WEEKS.map((item) => {
    if (item === 1) return { value: `${item} week` };
    return { value: `${item} weeks` };
  });

export const COLLEGECREDIT_INVENTORY_DURATION_WEEKS = [
  6, 8, 9, 10, 12,
] as const;

export const COLLEGECREDIT_INVENTORY_DURATION_OPTIONS =
  COLLEGECREDIT_INVENTORY_DURATION_WEEKS.map((item) => {
    return { value: `${item} weeks` };
  });

export const ADDON_INVENTORY_DURATION_WEEKS = [10];

export const ADDON_INVENTORY_DURATION_OPTIONS =
  ADDON_INVENTORY_DURATION_WEEKS.map((item) => {
    if (item === 1) return { value: `${item} week` };
    return { value: `${item} weeks` };
  });

export const APPLICATION_STATUS_OPTIONS = [
  { label: "Pending", value: ApplicationStatus.PendingReview },
  { label: "On hold, awaiting agent", value: ApplicationStatus.OnHoldCX },
  { label: "Approve Now", value: ApplicationStatus.Approved },
  { label: "On hold, awaiting user", value: ApplicationStatus.OnHoldUser },
  { label: "Deny Now", value: ApplicationStatus.Denied },
] as const;

export const INVENTORY_STATUS_MAP = {
  [InventoryStatus.PreMarket]: "Pre-Market",
  [InventoryStatus.OnMarketPublic]: "Publicly on Market",
  [InventoryStatus.OnMarketPrivate]: "Privately on Market",
  [InventoryStatus.Onboarding]: "Onboarding",
  [InventoryStatus.Running]: "Running",
  [InventoryStatus.Canceled]: "Canceled",
  [InventoryStatus.Completed]: "Completed",
} as const;

export const INVENTORY_STATUS_OPTIONS = [
  { label: "Pre-Market", value: InventoryStatus.PreMarket },
  { label: "Publicly on Market", value: InventoryStatus.OnMarketPublic },
  { label: "Privately on Market", value: InventoryStatus.OnMarketPrivate },
  { label: "Onboarding", value: InventoryStatus.Onboarding },
  { label: "Running", value: InventoryStatus.Running },
  { label: "Canceled", value: InventoryStatus.Canceled },
  { label: "Completed", value: InventoryStatus.Completed },
] as const;

export const INVENTORY_STATUS_COLOR_MAP = {
  [InventoryStatus.PreMarket]: "grey",
  [InventoryStatus.OnMarketPublic]: "green",
  [InventoryStatus.OnMarketPrivate]: "green",
  [InventoryStatus.Onboarding]: "grey",
  [InventoryStatus.Running]: "grey",
  [InventoryStatus.Canceled]: "red",
  [InventoryStatus.Completed]: "grey",
} as const;

//Does not include partner or scopedUsers
export const PS_COUPON_DEFAULTS = {
  amountType: CouponAmountType.Percentage,
  applicableToCart: true,
  hasCustomCode: false,
  type: CouponType.PartialScholarship,
  amount: 50,
  maxUses: 10,
  expiration: generateEndOfSeasonDate(),
};

//Does not include partner or scopedUsers
export const FS_COUPON_DEFAULTS = {
  amountType: CouponAmountType.Percentage,
  hasCustomCode: false,
  type: CouponType.FullScholarship,
  amount: 100,
  maxUses: 1,
  expiration: generateEndOfSeasonDate(),
};

export const PROMO_COUPON_DEFAULTS = {
  hasCustomCode: true,
  amountType: CouponAmountType.Percentage,
  amount: 10,
  maxUses: 10,
  expiration: generateEndOfSeasonDate(),
};

export const AGES = [
  {
    value: "13",
    displayValue: "13",
  },
  {
    value: "14",
    displayValue: "14",
  },
  {
    value: "15",
    displayValue: "15",
  },
  {
    value: "16",
    displayValue: "16",
  },
  {
    value: "17",
    displayValue: "17",
  },
  {
    value: "18",
    displayValue: "18+",
  },
];
