/// <reference types="vite/client" />

import { datadogRum } from "@datadog/browser-rum";

export const datadogInit = function () {
  if (
    import.meta.env.VITE_DATADOG_RUM_APP_ID &&
    import.meta.env.VITE_DATADOG_RUM_CLIENT_TOKEN
  ) {
    datadogRum.init({
      applicationId: import.meta.env.VITE_DATADOG_RUM_APP_ID,
      clientToken: import.meta.env.VITE_DATADOG_RUM_CLIENT_TOKEN,
      // site refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: "datadoghq.com",
      service: "backoffice",
      env: import.meta.env.VITE_ENVIRONMENT,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });
  }
};
