const BASE = import.meta.env.VITE_API_ENDPOINT;

const routes = {
  post() {
    return `${BASE}/inventory/items`;
  },

  show(id: string) {
    return `${BASE}/inventory/items/${id}`;
  },

  patch(id: string) {
    return `${BASE}/inventory/items/${id}`;
  },

  market() {
    return `${BASE}/inventory/market`;
  },

  migrate() {
    return `${BASE}/ucs/course`;
  },

  job(id) {
    return `${BASE}/ucs/job/inventory/${id}`;
  },

  delete(id: string) {
    return `${BASE}/inventory/items/${id}`;
  },

  available(partnerId: string, catalogueId: string) {
    return `${BASE}/aai/${partnerId}/${catalogueId}`;
  },

  inventoryByCatalogueId(catalogueId: string) {
    return `${BASE}/inventory/enroll/${catalogueId}`;
  },

  search() {
    return `${BASE}/inventory/search`;
  },

  enrollments(programId: string) {
    return `${BASE}/enrollments/${programId}`;
  },
} as const;

export default routes;
