import { ShoppingCart } from "phosphor-react";

import LinearProgress from "@mui/material/LinearProgress";

import SearchMessage from "@/components/SearchMessage";

import OrderDetails from "./components/OrderDetails";

export default function OrderShow({ order }) {
  return (
    <>
      {order.isFetching ? <LinearProgress color="secondary" /> : null}
      {order.isError ? (
        order?.error?.response?.status === 404 ? (
          <SearchMessage icon={ShoppingCart} text="Order could not be found" />
        ) : (
          <SearchMessage icon={ShoppingCart} text="An error has occurred" />
        )
      ) : null}
      {order.data === "" ? (
        <SearchMessage icon={ShoppingCart} text="Order does not exist" />
      ) : null}
      {order.data ? <OrderDetails orderData={order.data} /> : null}
    </>
  );
}
