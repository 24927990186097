import { useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import UPDialog from "@/components/UPDialog";
import { useAvailbleInventory } from "@/models/inventory/hooks";

export default function ChangeDates({ open, close, inventoryItem, swapDates }) {
  const partnerId = inventoryItem.partner._id;
  const catalogueId = inventoryItem.catalogueItem._id;
  const inventoryId = inventoryItem._id;
  const [selectedInventory, setSelectedInventory] = useState(inventoryId);
  const [swapping, setSwapping] = useState(false);

  const catalogueItem = useAvailbleInventory(partnerId, catalogueId);

  const handleConfirm = () => {
    if (selectedInventory === inventoryId) {
      close();
      return;
    }
    setSwapping(true);
    swapDates(selectedInventory);
  };

  return (
    <UPDialog
      close={close}
      Actions={
        <>
          <Button onClick={close} variant="contained" color="error">
            Cancel
          </Button>
          <LoadingButton
            loading={swapping}
            variant="contained"
            onClick={handleConfirm}
          >
            Confirm
          </LoadingButton>
        </>
      }
      Content={
        catalogueItem.isSuccess ? (
          <Select
            value={selectedInventory}
            onChange={(e) => setSelectedInventory(e.target.value)}
          >
            {catalogueItem.data?.inventoryItems?.map((inventoryItem, i) => (
              <MenuItem key={i} value={inventoryItem._id}>
                {inventoryItem.name}
              </MenuItem>
            ))}
          </Select>
        ) : null
      }
      open={open}
      Title={"Change Dates"}
    />
  );
}
