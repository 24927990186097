import { useQuery } from "@tanstack/react-query";

import type { TScholarship } from "./types";
import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import Scholarship from "./";

export function useScholarship(
  email: string,
  partnerId: string,
  options: Pick<UseQueryOptions, "enabled" | "placeholderData"> = {},
): UseQueryResult<TScholarship, Error> {
  return useQuery({
    queryKey: Scholarship.find.queryKey(email, partnerId),
    queryFn() {
      return Scholarship.find.queryFn(email, partnerId);
    },
    ...options,
  });
}
