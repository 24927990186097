import { useMutation } from "@tanstack/react-query";

import { Drawer, DrawerManager } from "@/components/Drawer";
import { useAlert } from "@/hooks/use-alert";
import Order from "@/models/order";

import Switch from "./Switch";

export default DrawerManager.create(function ({
  cancel,
  email,
  lineItem,
  successCallback,
}) {
  const showAlert = useAlert();
  const mutation = useMutation({
    mutationFn(data) {
      return Order.switchEnrollment(email, data);
    },
    onSuccess(data) {
      if (successCallback) {
        successCallback(data);
      }
    },
    onError(error) {
      console.log(error);
      showAlert({ message: "An error has occurred", severity: "error" });
    },
  });

  const onSubmit = function (data) {
    mutation.mutate(data);
  };

  return (
    <Drawer loading={mutation.isPending} title="Switch Enrollment">
      <Switch
        cancel={cancel}
        isSaving={mutation.isPending}
        lineItem={lineItem}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
});
