import Skeleton from "@mui/material/Skeleton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function ResultsEmptyTableRows({ animation = false }) {
  return (
    <>
      <TableRow aria-hidden>
        <TableCell sx={{ width: "50%" }}>
          <Skeleton animation={animation} sx={{ width: "50%" }} />
          <Skeleton animation={animation} sx={{ width: "100%" }} />
        </TableCell>
        <TableCell sx={{ width: "50px" }}>
          <Skeleton animation={animation} sx={{ width: "45%" }} />
          <Skeleton animation={animation} sx={{ width: "60%" }} />
        </TableCell>
      </TableRow>
      <TableRow aria-hidden>
        <TableCell sx={{ width: "50%" }}>
          <Skeleton animation={animation} sx={{ width: "40%" }} />
          <Skeleton animation={animation} sx={{ width: "90%" }} />
        </TableCell>
        <TableCell sx={{ width: "50px" }}>
          <Skeleton animation={animation} sx={{ width: "70%" }} />
          <Skeleton animation={animation} sx={{ width: "40%" }} />
        </TableCell>
      </TableRow>
    </>
  );
}
