import createDate from "@/lib/create-date";
import { centsToPrice, priceToCents } from "@/lib/helpers";
import requestOptions from "@/lib/request-options";

import routes from "./routes";

import type {
  TInventory,
  TInventoryFindAllResponse,
  TInventoryFindAvailableResponse,
  TInventoryFindCourseJobResponse,
  TMigrationStatus,
} from "./types";

/* Helpers
/* ------------------------------------------------------------------ */

function formatForServer(data) {
  return {
    ...data,
    maxCapacity: parseInt(data.maxCapacity),
    retailPrice: priceToCents(data.retailPrice),
  };
}

function formatForClient(data: TInventory): TInventory {
  return {
    ...data,
    retailPrice: centsToPrice(data.retailPrice),
  };
}

function propertiesOrDefaults(data: Partial<TInventory>) {
  return {
    ...data,
    startDate: data.startDate || createDate(0, 1, 0),
    endDate: data.endDate || createDate(28, 1, 0),
    applicationDeadline: data.applicationDeadline || createDate(-7, 1, 0),
    enrollmentDeadline: data.enrollmentDeadline || createDate(-2, 1, 0),
    maxCapacity: data.maxCapacity || 60,
    retailPrice: data.retailPrice || 0,
    cohortLength: data.cohortLength || "4 weeks",
  };
}

/* Finders
/* ------------------------------------------------------------------ */

const findAvailable = {
  queryFn: async function (partnerId: string, catalogueId: string) {
    return await window.auth.client
      .get(routes.available(partnerId, catalogueId))
      .json<TInventoryFindAvailableResponse>();
  },
  queryKey(partnerId: string, catalogueId: string) {
    return ["inventory", partnerId, catalogueId] as const;
  },
};

const findAllByCatalogueId = {
  queryFn: async function (id: string) {
    const url = routes.inventoryByCatalogueId(id);
    return await window.auth.client.get(url).json<TInventory[]>();
  },
  queryKey(id: string) {
    return ["inventory-by-catalogue", id] as const;
  },
};

const findAll = {
  queryFn: async function (
    params: {
      endDate: string;
      startDate: string;
    },
    cursor,
  ) {
    const url = routes.search();
    return await window.auth.client
      .post(url, {
        json: {
          endDate: params.endDate,
          startDate: params.startDate,
          cursor,
        },
      })
      .json<TInventoryFindAllResponse>();
  },
  queryKey(params) {
    return ["inventory", params] as const;
  },
};

const findById = {
  queryFn: async function (id: string) {
    const resp = await window.auth.client
      .get(routes.show(id))
      .json<TInventory>();
    return formatForClient(resp);
  },
  queryKey(id: string) {
    return ["inventory", id] as const;
  },
};

const findCourseJob = {
  queryFn: async function (id: string) {
    const url = routes.job(id);
    return await window.auth.client
      .get(url)
      .json<TInventoryFindCourseJobResponse>();
  },
  queryKey(id: string) {
    return ["courseJob", id] as const;
  },
};

/* Actions
/* ------------------------------------------------------------------ */

async function save(data: TInventory) {
  const { method, url } = requestOptions(routes, data._id);
  const resp = await window.auth.client[method](url, {
    json: formatForServer(data),
  }).json<TInventory>();

  return formatForClient(resp);
}

async function promoteToMarket(data: TInventory) {
  const url = routes.market();
  const resp = await window.auth.client
    .patch(url, {
      json: formatForServer(data),
    })
    .json<TInventory>();
  return formatForClient(resp);
}

async function migrateToCanvas(data: TInventory) {
  const url = routes.migrate();
  const resp = await window.auth.client
    .post(url, {
      json: formatForServer(data),
    })
    .json<
      TInventory & {
        migrationStatus: TMigrationStatus;
        jobCreatedAt: string;
      }
    >();
  return formatForClient(resp);
}

async function deleteItem(id: string, newInventoryItem: TInventory) {
  const data: {
    newInventoryItem?: TInventory;
  } = {};
  if (newInventoryItem) {
    data.newInventoryItem = newInventoryItem;
  }

  const url = routes.delete(id);
  return await window.auth.client
    .delete(url, {
      json: data,
    })
    .json();
}

export default {
  propertiesOrDefaults,
  findAvailable,
  findAllByCatalogueId,
  findAll,
  findById,
  findCourseJob,

  save,
  promoteToMarket,
  migrateToCanvas,
  deleteItem,
};
