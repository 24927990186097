import requestOptions from "@/lib/request-options";

import routes from "./routes";

import type {
  TApplication,
  TApplicationFindAllByStatusResponse,
  TApplicationTimelineEvent,
} from "./types";

/* Finders
/* ------------------------------------------------------------------ */

const find = {
  queryFn: async function (email: string, partnerId: string) {
    const url = routes.show(email, partnerId);
    return await window.auth.client.get(url).json<TApplication>();
  },
  queryKey(email: string, partnerId: string) {
    return ["application", email, partnerId] as const;
  },
};

const findAllByStatus = {
  queryFn: async function (status: TApplication["status"], cursor: unknown) {
    const url = routes.status(status);
    return await window.auth.client
      .post(url, {
        json: {
          cursor,
        },
      })
      .json<TApplicationFindAllByStatusResponse>();
  },
  queryKey(status?: TApplication["status"]) {
    const key = ["applications"];
    if (status) {
      key.push(status);
    }
    return key;
  },
};

const timeline = async function (email: string, partnerId: string) {
  const url = routes.timeline(email, partnerId);
  return await window.auth.client.get(url).json<TApplicationTimelineEvent[]>();
};

/* Actions
/* ------------------------------------------------------------------ */

async function save(data: TApplication) {
  const { method, url } = requestOptions(
    routes,
    data.student.email,
    data.partner._id,
  );

  return await window.auth.client[method](url, {
    json: data,
  }).json<TApplication>();
}

async function cancel(data: TApplication) {
  return await window.auth.client
    .delete(routes.cancel(data.student.email, data.partner._id), {
      json: data,
    })
    .json();
}

export default {
  find,
  findAllByStatus,
  timeline,

  save,
  cancel,
};
