import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ResultsEmptyTableRows from "./ResultsEmptyTableRows";
import ResultsTableRow from "./ResultsTableRow";

export default function Results({ mentors = [] }) {
  return (
    <>
      <br />
      <List
        sx={{
          bgcolor: "background.paper",
          position: "relative",
          overflow: "auto",
          padding: 0,
          "& ul": { padding: 0 },
          "& .MuiListSubheader-root": { lineHeight: "32px" },
        }}
      >
        <ListItem disablePadding>
          <Box sx={{ width: "100%", marginBottom: 2 }}>
            <Table
              size="small"
              className={mentors?.length ? "" : "empty-table-body"}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    {mentors?.length ? "Mentor Name/Email" : "No Mentors Found"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mentors?.length ? (
                  mentors.map((mentor) => (
                    <ResultsTableRow key={mentor.email} mentor={mentor} />
                  ))
                ) : (
                  <ResultsEmptyTableRows />
                )}
              </TableBody>
            </Table>
          </Box>
        </ListItem>
      </List>
    </>
  );
}
