import { Link, useMatch, useResolvedPath } from "react-router-dom";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import clientRoutes from "@/lib/client-routes";

const ListItemSX = {
  borderRadius: 2,
  paddingTop: 0.5,
  paddingBottom: 0.6,
  paddingLeft: 2,
};

function Button({ item }) {
  const to = clientRoutes.catalogue.show(item._id);
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <li>
      <ListItemButton
        component={Link}
        className={match ? "Mui-selected" : ""}
        to={to}
        state={{ item }}
        sx={ListItemSX}
      >
        <ListItemText
          primary={item.name}
          primaryTypographyProps={{ lineHeight: "1.25", variant: "body2" }}
        />
      </ListItemButton>
    </li>
  );
}
export default function MainList({ items = [] }) {
  return (
    <List sx={{ paddingTop: 1, paddingRight: 1.5, paddingLeft: 1.5 }}>
      {items.map((item) => (
        <Button key={item._id} item={item} />
      ))}
    </List>
  );
}
