import { useQuery } from "@tanstack/react-query";
import { Route, Routes } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { Layout } from "@/components/Layout";
import { TitleBar } from "@/components/TitleBar";
import { formatDate } from "@/lib/helpers";
import routes from "@/models/marketing-list/routes";

export default function MarketingListsRoute() {
  const pending = useQuery({
    queryKey: ["marketingListsPending"],
    async queryFn() {
      return await window.auth.client.get(routes.status()).json();
    },
  });

  return (
    <Layout>
      <Routes>
        <Route
          index
          element={
            <>
              <TitleBar loading={pending.isLoading} title="Import Status" />
              <Layout.Content>
                {pending.data?.length ? (
                  <div>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Progress</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Updated</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pending.data.map((item) => {
                          return (
                            <TableRow key={item.listId}>
                              <TableCell>{item.listId}</TableCell>
                              <TableCell>
                                {item.name}
                                <br />
                                {Object.entries(item.metadata).map(([k, v]) => {
                                  return (
                                    <div key={k}>
                                      <code>
                                        {k}: {v}
                                      </code>
                                    </div>
                                  );
                                })}
                              </TableCell>
                              <TableCell>
                                {item.processed} / {item.total}
                              </TableCell>
                              <TableCell>{item.status}</TableCell>
                              <TableCell>
                                {formatDate(
                                  item.updatedAt,
                                  "M/dd/yy -kk:mm:ss",
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                ) : null}
              </Layout.Content>
            </>
          }
        />
      </Routes>
    </Layout>
  );
}
