import routes from "./routes";

import type {
  TScholarship,
  TScholarshipFindAllByStatusResponse,
  TScholarshipTimelineEvent,
} from "./types";

/* Finders
/* ------------------------------------------------------------------ */

const findAllByStatus = {
  queryFn: async function (status: TScholarship["status"], cursor) {
    const url = routes.status(status);
    return await window.auth.client
      .post(url, {
        json: {
          cursor,
        },
      })
      .json<TScholarshipFindAllByStatusResponse>();
  },
  queryKey(status?: TScholarship["status"]) {
    const key = ["scholarships"];
    if (status) {
      key.push(status);
    }
    return key;
  },
};

const timeline = async function (email: string, partnerId: string) {
  const url = routes.timeline(email, partnerId);
  return await window.auth.client.get(url).json<TScholarshipTimelineEvent[]>();
};

const find = {
  queryKey(email: string, partnerId: string) {
    return ["scholarship", email, partnerId] as const;
  },
  queryFn: async function (email: string, partnerId: string) {
    const url = routes.show(email, partnerId);
    return await window.auth.client.get(url).json<TScholarship>();
  },
};

/* Actions
/* ------------------------------------------------------------------ */

async function save(data: TScholarship) {
  const url = routes.patch();
  return await window.auth.client
    .patch(url, {
      json: data,
    })
    .json<TScholarship>();
}

export default {
  find,
  findAllByStatus,
  timeline,

  save,
};
