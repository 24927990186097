import { CurrencyDollar } from "phosphor-react";

import { EmptyIndexMessage } from "@/components/EmptyIndexMessage";

const Empty = function ({ scholarshipsQuery }) {
  const { isLoading, isFetching, data } = scholarshipsQuery;

  const isEmpty =
    !isLoading && !isFetching && Boolean(data) && !data.pages?.length;

  const text = (function () {
    if (isLoading || isFetching) {
      return "Inbox Loading";
    }
    if (isEmpty) {
      return "Inbox Empty!";
    }
    if (Boolean(data?.pages) && data?.pages?.length) {
      return "Select a scholarship";
    }

    return "Inbox";
  })();

  return (
    <EmptyIndexMessage
      celebrate={isEmpty}
      icon={<CurrencyDollar size={100} weight="thin" />}
      text={text}
    />
  );
};

export default Empty;
