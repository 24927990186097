export const sx = {
  position: "relative",
  right: "-24px",
  flexDirection: "column",
  flexShrink: 0,
  pl: 1,
  pr: 1,
  color: (theme) => theme.palette.text.primary,
  fontSize: "0.8rem",
  lineHeight: "1.4",
  backgroundColor: "transparent",
  opacity: 0.8,
  textAlign: "center",

  "&:hover": {
    boxShadow: "none",
    background: "transparent",
  },

  "& .MuiButton-startIcon": {
    display: "flex",
    justifyContent: "center",
    minWidth: "2rem",
    marginRight: 0,
    marginLeft: 0,
    borderRadius: "4px",
    padding: "3px",
  },

  "&:hover .MuiButton-startIcon": {
    color: (theme) => theme.palette.primary.main,
    background: "rgba(180,52,246, 0.15)",
  },
};
