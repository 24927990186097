import { UseMutateFunction, useQuery } from "@tanstack/react-query";

import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { Data } from "@/components/Data";
import { useDrawer } from "@/components/Drawer";
import { ApplicationDrawer } from "@/components/Drawers/ApplicationDrawer";
import ProfileDrawer from "@/components/Drawers/ProfileDrawer";
import { UPChip } from "@/components/UPChip";
import { UPTimeline } from "@/components/UPTimeline";
import { FORM_PAPER_SX, STATUS_COLOR_MAP, STATUS_MAP } from "@/constants";
import { useAlert } from "@/hooks/use-alert";
import { formatDate } from "@/lib/helpers";
import Scholarship from "@/models/scholarship";

import ChangeStatus from "./ChangeStatus";
import getScore from "./getScore";
import ScholarshipRequest from "./ScholarshipRequest";

import type { TUseApplication } from "@/models/application/types";
import type { TUseProfile } from "@/models/profile/types";
import type { TScholarship, TUseScholarship } from "@/models/scholarship/types";

export const ScholarshipShowView = function ScholarshipShowView({
  applicationQuery,
  isSaving,
  profileQuery,
  saveScholarship,
  scholarshipQuery,
}: {
  applicationQuery: TUseApplication;
  isSaving: boolean;
  profileQuery: TUseProfile;
  saveScholarship: UseMutateFunction<
    TScholarship,
    Error,
    TScholarship,
    unknown
  >;
  scholarshipQuery: TUseScholarship;
}) {
  const timelineQuery = useQuery({
    queryKey: [
      "timeline",
      "scholarship",
      scholarshipQuery.data?.author,
      scholarshipQuery.data?.partner._id,
    ],
    queryFn() {
      return Scholarship.timeline(
        scholarshipQuery.data?.author,
        scholarshipQuery.data?.partner._id,
      );
    },
    enabled: Boolean(scholarshipQuery.data),
  });
  const applicationDrawer = useDrawer(ApplicationDrawer);
  const profileDrawer = useDrawer(ProfileDrawer);
  const showAlert = useAlert();

  const showApplication = function () {
    applicationDrawer.show({
      profileQuery,
      successCallback() {
        showAlert({
          message: `Application updated`,
        });
        applicationDrawer.remove();
      },
    });
  };

  return (
    <>
      <Typography
        component="h2"
        display="flex"
        variant="h5"
        sx={{ alignItems: "center" }}
      >
        {profileQuery.isLoading ? (
          <>
            <span style={{ opacity: 0.5 }}>Loading applicant</span>
            <IconButton style={{ opacity: 0 }} onClick={() => {}}>
              <VisibilityIcon />
            </IconButton>
          </>
        ) : profileQuery.data ? (
          <>
            {`${profileQuery.data.firstName} ${profileQuery.data.lastName}`}
            <IconButton
              onClick={() => {
                profileDrawer.show({
                  email: profileQuery.data.email,
                  partnerID: profileQuery.data.partner._id,
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </>
        ) : null}
      </Typography>

      <Typography component="h2" display="block" marginBottom={1} variant="h5">
        {scholarshipQuery.data && scholarshipQuery.data.partner.name}
      </Typography>
      <br />

      <Data>
        <Data.Item>
          <Data.Label>Scholarship Status</Data.Label>
          <Data.Content>
            {scholarshipQuery.data ? (
              <UPChip
                color={STATUS_COLOR_MAP[scholarshipQuery.data.status]}
                label={STATUS_MAP[scholarshipQuery.data.status]}
                size="small"
              />
            ) : (
              <em>none</em>
            )}
          </Data.Content>
        </Data.Item>
        <Data.Item>
          <Data.Label>Application Status</Data.Label>
          <Data.Content>
            {applicationQuery.data && (
              <UPChip
                color={STATUS_COLOR_MAP[applicationQuery.data.status]}
                label={STATUS_MAP[applicationQuery.data.status]}
                size="small"
              />
            )}
            {!applicationQuery.data && <em>none</em>}
          </Data.Content>
          {applicationQuery.data && (
            <IconButton onClick={showApplication} size="small">
              <VisibilityIcon />
            </IconButton>
          )}
        </Data.Item>
        <Data.Item>
          <Data.Label>Needs/Benefits Score:</Data.Label>
          <Data.Content>{`${getScore(
            scholarshipQuery.data,
          )} out of 10`}</Data.Content>
        </Data.Item>
        <Data.Item>
          <Data.Label>Created On:</Data.Label>
          <Data.Content>
            {formatDate(scholarshipQuery.data.createdAt)}
          </Data.Content>
        </Data.Item>
      </Data>

      <br />

      <Typography variant="h6" sx={{ mb: 2 }}>
        Scholarship Status
      </Typography>
      {scholarshipQuery.data && (
        <ChangeStatus
          isSaving={isSaving}
          save={(data) => {
            saveScholarship(data);
          }}
          scholarship={scholarshipQuery.data}
        />
      )}

      <br />

      <Typography variant="h6" sx={{ mb: 2 }}>
        Scholarship Request
      </Typography>
      {scholarshipQuery.data ? (
        <ScholarshipRequest
          scholarship={scholarshipQuery.data}
          essay={applicationQuery?.data?.essay}
          isSaving={isSaving}
          save={(data) => {
            saveScholarship(data);
          }}
        />
      ) : null}

      <br />

      <Typography variant="h6" sx={{ mb: 1 }}>
        Timeline
      </Typography>
      {scholarshipQuery.isLoading ? <div>Loading...</div> : null}
      {scholarshipQuery.data ? (
        <Paper elevation={1} sx={FORM_PAPER_SX}>
          <UPTimeline timelineQuery={timelineQuery} />
        </Paper>
      ) : null}
    </>
  );
};
