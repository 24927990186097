import * as PropTypes from "prop-types";
import { Route, Routes } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/system/Box";

import { Layout } from "@/components/Layout";
import { MAIN_VIEW_WIDTH } from "@/constants";

export const MainDetail = function MainDetail({
  MainComponent,
  mainProps = {},
  DetailComponent,
  detailPath,
  detailProps = {},
  EmptyComponent,
}) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return matches ? (
    <Layout sx={{ maxWidth: { xs: "100%", md: `calc(100% - 240px)` } }}>
      <Box
        data-test="main-detail-view"
        sx={{
          height: "100vh",
          overflow: "hidden",
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          position: "relative",
        }}
      >
        <Box
          data-test="main-view"
          sx={{
            position: "relative",
            flexShrink: 0,
            overflow: "auto",
            width: `${MAIN_VIEW_WIDTH}px`,
            height: "100%",
            borderRight: "1px solid rgba(0,0,0, 0.12)",
            ...theme.applyStyles("dark", {
              borderRight: "1px solid rgba(255,255,255, 0.12)",
            }),
          }}
        >
          <Routes>
            <Route path="*" element={<MainComponent {...mainProps} />} />
          </Routes>
        </Box>
        <Box
          data-test="detail-view"
          sx={{ position: "relative", flexGrow: 1, overflow: "auto" }}
        >
          <Routes>
            <Route index element={<EmptyComponent />} />
            <Route
              path={detailPath}
              element={<DetailComponent {...detailProps} />}
            />
          </Routes>
        </Box>
      </Box>
    </Layout>
  ) : (
    <Layout>
      <Routes>
        <Route index element={<MainComponent {...mainProps} />} />
        <Route
          path={detailPath}
          element={<DetailComponent {...detailProps} />}
        />
      </Routes>
    </Layout>
  );
};

MainDetail.propTypes = {
  MainComponent: PropTypes.func.isRequired,
  mainProps: PropTypes.object,
  DetailComponent: PropTypes.func.isRequired,
  detailPath: PropTypes.string.isRequired,
  detailProps: PropTypes.object,
  EmptyComponent: PropTypes.func.isRequired,
};
