const YearRound = "year-round";
const Seasonal = "seasonal";
const Substitute = "substitute";
const Dormant = "dormant";

export const MentorAvailability = {
  YearRound,
  Seasonal,
  Substitute,
  Dormant,
} as const;
