import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useForm } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { ActionButtons } from "@/components/ActionButtons";
import { Drawer, DrawerManager } from "@/components/Drawer";
import FormErrorMessage from "@/components/FormErrorMessage";
import { FORM_PAPER_HEADING_SX, FORM_PAPER_SX } from "@/constants";
import Mentor from "@/models/mentor";

import {
  ActiveField,
  AvailabilityField,
  EmailField,
  FirstNameField,
  LastNameField,
  NotesField,
  PersonalEmailField,
  PhoneField,
  SlackUsernameField,
} from "./fields";

import type { TMentor } from "@/models/mentor/types";

export default DrawerManager.create(function (props: {
  cancel: () => void;
  mentor?: TMentor;
  successCallback: (mentor: TMentor) => void;
  title: string;
}) {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<TMentor>({
    defaultValues: Mentor.propertiesOrDefaults(props.mentor || {}),
  });
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: Mentor.save,
    onSuccess(data) {
      queryClient.invalidateQueries({
        queryKey: Mentor.findAll.queryKey(),
      });
      props.successCallback?.(data);
    },
    onError: async (error) => {
      if (error instanceof HTTPError) {
        const errorText = await error.response.text();
        error.message = errorText;
      }
    },
  });

  return (
    <Drawer loading={mutation.isPending} title={props.title}>
      <form
        onSubmit={handleSubmit((updatedMentor) => {
          return mutation.mutate(updatedMentor);
        })}
        noValidate
      >
        <Paper elevation={1} sx={FORM_PAPER_SX}>
          <Grid container spacing={3}>
            <Grid size={{ xs: 12, md: 6 }}>
              <FormControl fullWidth>
                <FirstNameField control={control} error={errors.firstName} />
                <FormErrorMessage error={errors.firstName} />
              </FormControl>
            </Grid>

            <Grid size={{ xs: 12, md: 6 }}>
              <FormControl fullWidth>
                <LastNameField control={control} error={errors.lastName} />
                <FormErrorMessage error={errors.lastName} />
              </FormControl>
            </Grid>

            <Grid size={12}>
              <FormControl fullWidth>
                <EmailField control={control} error={errors.email} />
                <FormErrorMessage error={errors.email} />
              </FormControl>
            </Grid>

            <Grid size={12}>
              <FormControl fullWidth>
                <PersonalEmailField control={control} />
              </FormControl>
            </Grid>

            <Grid size={12}>
              <FormControl fullWidth>
                <SlackUsernameField control={control} />
              </FormControl>
            </Grid>

            <Grid size={12}>
              <FormControl fullWidth>
                <PhoneField control={control} />
              </FormControl>
            </Grid>
          </Grid>
        </Paper>

        <Paper elevation={1} sx={FORM_PAPER_SX}>
          <Grid container spacing={3}>
            <Typography variant="h6" component="h3" sx={FORM_PAPER_HEADING_SX}>
              Availability
            </Typography>

            <Grid size={12}>
              <FormControl fullWidth>
                <ActiveField control={control} />
              </FormControl>
            </Grid>

            <Grid size={12}>
              <FormControl fullWidth>
                <AvailabilityField control={control} />
              </FormControl>
            </Grid>

            <Grid size={12}>
              <FormControl fullWidth>
                <NotesField control={control} />
              </FormControl>
            </Grid>
          </Grid>
        </Paper>

        <br />

        <ActionButtons cancel={props.cancel} isSaving={mutation.isPending}>
          <FormErrorMessage gutterBottom error={mutation.error} />
        </ActionButtons>
      </form>
    </Drawer>
  );
});
