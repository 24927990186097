import { useContext, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { NavDrawerContext } from "@/context/NavDrawerContext";

import { BackButton } from "./BackButton";
import SidebarToggle from "./SidebarToggle";

import type { ReactNode } from "react";

const supTitleStyle = {
  fontSize: "14px",
  fontWeight: 400,
  opacity: 0.8,
};
const titleStyle = {
  fontSize: { xs: "14px", sm: "18px" },
  fontWeight: 700,
};
const progressStyle = {
  position: "absolute",
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1,
};

export const TitleBar = function ({
  children,
  supTitle,
  title,
  backFunction,
  backRoute,
  loading = false,
  startComponent,
}: {
  children?: ReactNode;
  supTitle?: string;
  title: ReactNode;
  backFunction?: () => void;
  backRoute?: string;
  loading?: boolean;
  startComponent?: ReactNode;
}) {
  const [showShadow, setShowShadow] = useState(false);

  const { ref, inView } = useInView();

  // Slight delay to prevent shadow flicker on title bar
  useEffect(() => {
    const t = setTimeout(() => {
      setShowShadow(!inView);
    }, 100);
    return () => clearTimeout(t);
  }, [setShowShadow, inView]);

  const drawerState = useContext(NavDrawerContext);
  const titleIsText = typeof title === "string";
  const titleIsObject = typeof title === "object";

  return (
    <>
      <div
        ref={ref}
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          height: "1px",
          backgroundColor: "transparent",
        }}
      />
      <AppBar
        data-test="app-bar"
        elevation={0}
        position="sticky"
        sx={(theme) => ({
          bgcolor: "rgb(255,255,255)",
          color: theme.palette.text.primary,
          zIndex: theme.zIndex.drawer - 1,
          boxShadow: showShadow
            ? "0 1px 3px rgba(0,0,0, 0.15), 0 1px 0px rgba(0,0,0, 0.025)"
            : "none",
          "@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)":
            {
              backdropFilter: "blur(6px)",
              bgcolor: "rgba(255,255,255, 0.6)",
              ...theme.applyStyles("dark", {
                bgcolor: "rgba(18,18,18, 0.6)",
              }),
            },
          ...theme.applyStyles("dark", {
            bgcolor: "rgb(18,18,18)",
            boxShadow: showShadow ? "0 1px 4px rgba(0,0,0, 0.9)" : "none",
          }),
        })}
      >
        <Toolbar
          sx={{
            paddingLeft: { xs: 7, md: 2 },
            minHeight: "50px",
            zIndex: 1,
          }}
        >
          <SidebarToggle onClick={drawerState.openDrawer} />
          {startComponent ? startComponent : null}
          {(backRoute || backFunction) && (
            <BackButton onClick={backFunction} to={backRoute} />
          )}

          {titleIsText && (
            <Box sx={{ flexGrow: 1 }}>
              {supTitle && (
                <Typography
                  data-testid="navigation-sup-title"
                  noWrap
                  sx={supTitleStyle}
                >
                  {supTitle}
                </Typography>
              )}

              <Typography data-testid="navigation-title" noWrap sx={titleStyle}>
                {title}
              </Typography>
            </Box>
          )}
          {titleIsObject && (
            <Box data-testid="navigation-title" sx={titleStyle}>
              {title}
            </Box>
          )}
          {children}
        </Toolbar>
        {loading && <LinearProgress color="secondary" sx={progressStyle} />}
      </AppBar>
    </>
  );
};
TitleBar.BackButton = BackButton;
