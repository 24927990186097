import { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

export default function SearchBar({ onSubmit }) {
  const [query, setQuery] = useState("");

  const onChange = function (event) {
    setQuery(event.target.value);
  };

  const handleSubmit = function (event) {
    event.preventDefault();
    onSubmit(query);
  };

  return (
    <Box
      as="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        p: 2,
      }}
    >
      <TextField
        label="Search"
        name="query"
        onChange={onChange}
        sx={{ flexGrow: 1 }}
        value={query}
        variant="outlined"
        slotProps={{
          inputLabel: { shrink: true },
        }}
      />
      <Button
        color="neutral"
        sx={{ marginLeft: 1 }}
        type="submit"
        variant="outlined"
      >
        Search
      </Button>
    </Box>
  );
}
