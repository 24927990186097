import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const SidebarInput = styled(TextField)(() => ({
  "& input ": {
    paddingTop: "0.35rem",
    paddingBottom: "0.35rem",
  },
}));

const CouponSearch = ({ searchSuccess }) => {
  const handleSubmit = function (event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    searchSuccess(formData.get("code"));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" alignItems="center">
        <SidebarInput
          placeholder="Search"
          aria-label="Search for coupon"
          name="code"
          variant="outlined"
          size="small"
          required
        />
        <IconButton
          color="primary"
          type="submit"
          aria-label="search"
          size="small"
        >
          <SearchIcon />
        </IconButton>
      </Box>
    </form>
  );
};

export default CouponSearch;
