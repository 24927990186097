import { useQuery } from "@tanstack/react-query";

import Fulfillment from "./index";

export function useFulfillment(id: string) {
  return useQuery({
    queryKey: Fulfillment.findById.queryKey(id),
    queryFn() {
      return Fulfillment.findById.queryFn(id);
    },
  });
}
