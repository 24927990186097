import { useInfiniteQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { MainDetail } from "@/components/MainDetail";
import { STATUS_MAP } from "@/constants";
import clientRoutes from "@/lib/client-routes";
import Scholarship from "@/models/scholarship";

import Empty from "./components/Empty";
import SideBar from "./components/Sidebar/Sidebar";
import ScholarshipShow from "./show";

import type { TScholarshipStatus } from "@/models/scholarship/types";

export default function ScholarshipsRoute() {
  const { status } = useParams();
  const scholarshipsQuery = useInfiniteQuery({
    queryKey: Scholarship.findAllByStatus.queryKey(
      status as TScholarshipStatus,
    ),
    queryFn(context) {
      const cursor = context.pageParam;
      return Scholarship.findAllByStatus.queryFn(
        status as TScholarshipStatus,
        cursor,
      );
    },
    getNextPageParam: (lastPage) => lastPage.cursor,
    initialPageParam: 0,
  });

  return (
    <MainDetail
      MainComponent={SideBar}
      mainProps={{
        scholarshipsQuery,
        supTitle: STATUS_MAP[status],
      }}
      DetailComponent={ScholarshipShow}
      detailPath={clientRoutes.scholarships.showPath}
      EmptyComponent={() => <Empty scholarshipsQuery={scholarshipsQuery} />}
    />
  );
}
