import FormErrorMessage from "@/components/FormErrorMessage";

export default function HookFormErrorMessage({
  errors,
  gutterBottom,
  name,
  sx,
}: {
  errors: any;
  gutterBottom?: boolean;
  name: string;
  sx?: Record<string, unknown>;
}) {
  return (
    <FormErrorMessage
      error={errors && errors[name] ? errors[name] : null}
      gutterBottom={gutterBottom || false}
      sx={sx}
    />
  );
}
