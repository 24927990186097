import routes from "./routes";

import type { TFulfillment } from "./types";

const findById = {
  async queryFn(id: string) {
    const url = routes.show(id);
    return await window.auth.client.get(url).json<TFulfillment>();
  },
  queryKey(id: string) {
    return ["fulfillment", id] as const;
  },
};

export default {
  findById,
};
