import { Spinner } from "@/components/Spinner";

import EmptyBox from "./EmptyBox";

import type { PropsWithChildren } from "react";

export default function LoadingSelect({
  children,
  sx = {},
}: PropsWithChildren<{
  sx?: Record<string, unknown>;
}>) {
  return (
    <EmptyBox sx={sx}>
      <Spinner loading={true} sx={{ mr: 1 }} /> {children}
    </EmptyBox>
  );
}
