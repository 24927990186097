import Paper from "@mui/material/Paper";

import { Drawer, DrawerManager } from "@/components/Drawer";
import { FORM_PAPER_SX } from "@/constants";
import Switch from "@/routes/InventorySearch/components/Switch";

export default DrawerManager.create(function ({
  cancel,
  inventoryItem,
  isSaving,
  onSubmit,
}) {
  return (
    <Drawer loading={isSaving} title="Cancel Inventory">
      <Paper sx={FORM_PAPER_SX}>
        <Switch
          cancel={cancel}
          isSaving={isSaving}
          inventoryItem={inventoryItem}
          onSubmit={onSubmit}
        />
      </Paper>
    </Drawer>
  );
});
