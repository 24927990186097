const UserStarted = "userStarted";
const PendingReview = "pendingReview";
const OnHoldCX = "onHoldCX";
const OnHoldUser = "onHoldUser";
const Approved = "approved";
const Denied = "denied";
const AdminCompleted = "adminCompleted";
const Abandoned = "abandoned";

export const ApplicationStatus = {
  UserStarted,
  PendingReview,
  OnHoldCX,
  OnHoldUser,
  Approved,
  Denied,
  AdminCompleted,
  Abandoned,
} as const;
