import { formatDistanceToNow } from "date-fns";
import { Suspense } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";

import { CopyToClipboardButton } from "@/components/CopyToClipboardButton";
import { Data } from "@/components/Data";
import { formatDate } from "@/lib/helpers";
import { CouponAmountType } from "@/models/coupon/constants";

import InventoryItemName from "./Form/InventoryItemName";
import PartnerName from "./Form/PartnerName";
import ProfileName from "./Form/ProfileName";

const CouponDetails = function ({ coupon, FUTURE }) {
  const DATE = formatDate(coupon.expiration);
  const DISTANCE = formatDistanceToNow(new Date(coupon.expiration));

  return (
    <>
      <br />
      <Data>
        <Data.Item>
          <Data.Label>Code:</Data.Label>
          <Data.Content>
            <code>{coupon.code}</code>
          </Data.Content>
          <CopyToClipboardButton text={coupon.code} />
        </Data.Item>

        <Data.Item>
          <Data.Label>Adjustment Type:</Data.Label>
          <Data.Content>
            {coupon.type.charAt(0).toUpperCase() + coupon.type.slice(1)} use
          </Data.Content>
        </Data.Item>

        <Data.Item>
          <Data.Label>Amount:</Data.Label>
          <Data.Content>
            {coupon.amountType === CouponAmountType.Percentage
              ? `${coupon.amount}% off`
              : `$${coupon.amount} off`}
          </Data.Content>
        </Data.Item>

        <Data.Item>
          <Data.Label>Expiration Date:</Data.Label>
          <Data.Content>
            {coupon.expiration
              ? `${DATE} – ${FUTURE ? "expires" : "expired"} ${DISTANCE} ${
                  FUTURE ? "from now" : "ago"
                }`
              : "Will never expire"}
          </Data.Content>
        </Data.Item>

        <Data.Item>
          <Data.Label>Max Uses:</Data.Label>
          <Data.Content>
            {coupon.maxUses === 0 ? "Unlimited" : coupon.maxUses}
          </Data.Content>
        </Data.Item>

        <Data.Item>
          <Data.Label>Redemption Count:</Data.Label>
          <Data.Content>{coupon.useCount}</Data.Content>
        </Data.Item>
      </Data>

      <br />

      <div>
        <Data.Label>Scoped Partner: </Data.Label>
        {Boolean(coupon.partner._id) && (
          <List>
            <PartnerName key={coupon.partner.id} partner={coupon.partner} />
          </List>
        )}
        {!coupon.partner._id && (
          <Typography variant="body2" style={{ fontStyle: "italic" }}>
            No scoped partner. Applies to any partner.
          </Typography>
        )}
      </div>

      <br />

      <div>
        <Data.Label>Scoped Users: </Data.Label>
        {Boolean(coupon.scopedUsers.length) && (
          <List>
            {coupon.scopedUsers.map((user, index) => (
              <ProfileName
                key={index}
                _id={user}
                partnerID={coupon.partner._id}
              />
            ))}
          </List>
        )}
        {!coupon.scopedUsers.length && (
          <Typography variant="body2" style={{ fontStyle: "italic" }}>
            No scoped users. Applies to any student.
          </Typography>
        )}
      </div>

      <br />

      <div>
        <Data.Label>Scoped Items: </Data.Label>
        {Boolean(coupon.scopedItems.length) && (
          <Suspense
            fallback={
              <div>
                <CircularProgress color="secondary" size="1rem" /> Loading...
              </div>
            }
          >
            <List>
              {coupon.scopedItems.map((u, i) => (
                <InventoryItemName key={i} _id={u._id} />
              ))}
            </List>
          </Suspense>
        )}
        {!coupon.scopedItems.length && (
          <Typography variant="body2" style={{ fontStyle: "italic" }}>
            No scoped items. Applies to cart total.
          </Typography>
        )}
      </div>
    </>
  );
};

export default CouponDetails;
