import Typography from "@mui/material/Typography";

import { formatDate } from "@/lib/helpers";

export default function AddLineItem({
  catalogueItem,
  selectedInventoryItemID,
}) {
  const inventoryItem =
    selectedInventoryItemID && catalogueItem.data
      ? catalogueItem.data.inventoryItems.find((item) => {
          return item._id === selectedInventoryItemID;
        })
      : null;

  return (
    <div>
      <Typography sx={{ lineHeight: 1 }} variant="overline">
        {inventoryItem ? (
          `${formatDate(inventoryItem.startDate)} — ${formatDate(
            inventoryItem.endDate,
          )}`
        ) : (
          <em>start date — end date</em>
        )}
      </Typography>

      <Typography
        gutterBottom
        variant="body1"
        fontWeight="bold"
        sx={{ mt: -0.25 }}
      >
        {catalogueItem.data ? catalogueItem.data.name : "Course name"}
      </Typography>

      <Typography variant="body2">
        {inventoryItem ? (
          `Retail price: $${inventoryItem.retailPrice / 100}`
        ) : (
          <em>Retail price: $xxx.xx</em>
        )}
      </Typography>
    </div>
  );
}
