import { PRIMARY_COLOR_DARK, PRIMARY_COLOR_LIGHT } from "../constants";

type Mode = "dark" | "light";

const MuiSelect = function () {
  return {
    defaultProps: {
      size: "small",
    },
    styleOverrides: {
      root: {
        borderRadius: "8px",
      },
    },
  };
};

const MuiMenu = function () {
  return {
    styleOverrides: {
      paper: {
        borderRadius: "8px",
      },
    },
  };
};

const MuiMenuItem = function () {
  return {
    styleOverrides: {
      root: {
        borderRadius: "8px",
      },
    },
  };
};

const MuiButton = function () {
  return {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: () => ({
        paddingTop: "3px",
        paddingBottom: "3px",
        borderRadius: "8px",
        fontSize: "1rem",
        letterSpacing: "-0.02rem",
        textTransform: "inherit",
        boxShadow: "none",
        variants: [
          {
            props: {
              size: "small",
            },
            style: {
              fontSize: ".9rem",
            },
          },
        ],
      }),
    },
  };
};

const MuiButtonBase = function () {
  return {
    defaultProps: {
      disableRipple: true,
    },
  };
};

const MuiIconButton = function (mode: Mode) {
  return {
    styleOverrides: {
      root: {
        borderRadius: "8px",
        "&:hover": {
          color: mode === "dark" ? PRIMARY_COLOR_DARK : PRIMARY_COLOR_LIGHT,
          backgroundColor: "rgba(180,52,246, 0.08)",
        },
      },
    },
  };
};

const MuiButtonGroup = function () {
  return {
    defaultProps: {
      disableRipple: true,
    },
  };
};

const MuiListItemButton = function (mode: Mode) {
  return {
    styleOverrides: {
      root: {
        "&:after": {
          content: '""',
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          borderRadius: 8,
          background:
            mode === "dark" ? PRIMARY_COLOR_DARK : PRIMARY_COLOR_LIGHT,
          zIndex: 1,
          opacity: 0,
          transform: "scale(0.8)",
          transition:
            "transform 200ms cubic-bezier(0.345, 0.115, 0.135, 1.42), opacity 150ms ease-out",
        },
        "& > *": {
          zIndex: 2,
        },
        "&.Mui-selected": {
          color: "#fff",
          backgroundColor: "transparent",
        },
        "&.Mui-selected:after": {
          transform: "scale(1)",
          opacity: 1,
        },
        "&.Mui-selected .MuiListItemText-secondary": {
          color: "rgba(255,255,255, 0.75)",
        },
        "&.Mui-selected:hover": {
          backgroundColor: "transparent",
        },
      },
    },
  };
};

const MuiListItemIcon = function () {
  return {
    styleOverrides: {
      root: {
        minWidth: 36,
      },
    },
  };
};

const MuiList = function () {
  return {
    styleOverrides: {
      root: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };
};

const MuiTableCell = function () {
  return {
    styleOverrides: {
      head: {
        fontWeight: 500,
      },
    },
  };
};

const MuiPickersPopper = function () {
  return {
    styleOverrides: {
      paper: {
        borderRadius: "8px",
      },
    },
  };
};

const MuiDialog = function () {
  return {
    styleOverrides: {
      paper: {
        borderRadius: "12px",
      },
    },
  };
};

const MuiOutlinedInput = function () {
  return {
    styleOverrides: {
      root: {
        borderRadius: "8px",
      },
    },
  };
};

const MuiFormControl = function () {
  return {
    defaultProps: {
      size: "small",
    },
  };
};

const MuiTextField = function () {
  return {
    defaultProps: {
      size: "small",
    },
  };
};

const components = function (mode: Mode) {
  return {
    MuiButton: MuiButton(),
    MuiButtonBase: MuiButtonBase(),
    MuiButtonGroup: MuiButtonGroup(),
    MuiDialog: MuiDialog(),
    MuiFormControl: MuiFormControl(),
    MuiIconButton: MuiIconButton(mode),
    MuiListItemButton: MuiListItemButton(mode),
    MuiListItemIcon: MuiListItemIcon(),
    MuiList: MuiList(),
    MuiSelect: MuiSelect(),
    MuiTableCell: MuiTableCell(),
    MuiMenu: MuiMenu(),
    MuiMenuItem: MuiMenuItem(),
    MuiPickersPopper: MuiPickersPopper(),
    MuiOutlinedInput: MuiOutlinedInput(),
    MuiTextField: MuiTextField(),
  };
};

export default components;
