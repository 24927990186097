import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";

import type { TCatalogueItem } from "@/models/catalogue/types";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const CatalogueItemSelectMultiple = function ({
  items,
  onChange,
  value,
}: {
  items: TCatalogueItem[];
  onChange: (value: string[]) => void;
  value: string[] | undefined;
}) {
  const catalogueItems = items.flat();
  const formattedValue = value
    ?.map((id) => {
      return catalogueItems.find((i) => {
        return i._id === id;
      });
    })
    .filter((value) => value !== undefined);

  return (
    <Autocomplete
      multiple
      options={catalogueItems}
      disableCloseOnSelect
      getOptionLabel={(option) => {
        return option.name;
      }}
      groupBy={(option) => {
        return option.partner.name;
      }}
      onChange={(e, newValue) => {
        onChange(
          newValue.map((item) => {
            return item._id;
          }),
        );
      }}
      value={formattedValue}
      size="small"
      limitTags={1}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} key={props.id}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              size="small"
            />
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label="Catalogue items"
            size="small"
            placeholder="Catalogue items"
          />
        );
      }}
    />
  );
};
