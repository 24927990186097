import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";

import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { CopyToClipboardButton } from "@/components/CopyToClipboardButton";
import { Data } from "@/components/Data";
import { useDrawer } from "@/components/Drawer";
import PartnerEditDrawer from "@/components/Drawers/PartnerEditDrawer";
import { Layout } from "@/components/Layout";
import { TitleBar } from "@/components/TitleBar";
import { TitleBarButton } from "@/components/TitleBarButton";
import { UPChip } from "@/components/UPChip";
import { useAlert } from "@/hooks/use-alert";
import Partner from "@/models/partner";
import { usePartner } from "@/models/partner/hooks";

const ErrorMessage = function (props) {
  const text = props.text ? props.text : "Error loading data";
  return props.error ? (
    <Alert severity="error" style={{ marginBottom: "0.5rem" }}>
      {text}
    </Alert>
  ) : null;
};

const LoadingMessage = function ({ loading, text = "Loading" }) {
  return loading ? (
    <Alert
      icon={<CircularProgress size="1.25rem" />}
      severity="info"
      style={{ marginBottom: "0.5rem" }}
    >
      {text}
    </Alert>
  ) : null;
};

const ClipboardOrNothing = function ({ text }) {
  if (text) {
    return <CopyToClipboardButton text={text} />;
  }

  return null;
};

const PartnersShowRoute = function () {
  const location = useLocation();
  const queryClient = useQueryClient();
  const showAlert = useAlert();
  const partnerEditDrawer = useDrawer(PartnerEditDrawer);
  const { _id: partnerID } = useParams();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const partner = usePartner(partnerID, {
    placeholderData: location.state?.partner ?? null,
  });

  const successCallback = function () {
    queryClient.invalidateQueries({
      queryKey: Partner.findAll.queryKey(),
    });
    showAlert({
      message: "Partner saved",
    });
    partnerEditDrawer.remove();
  };

  return (
    <>
      <Layout>
        <TitleBar
          backRoute={matches ? null : ".."}
          title={partner.data?.name ?? "Partner"}
        >
          <TitleBarButton
            icon="edit"
            onClick={() => {
              partnerEditDrawer.show({
                cancel() {
                  partnerEditDrawer.remove();
                },
                data: partner.data,
                successCallback,
              });
            }}
          >
            Edit Partner
          </TitleBarButton>
        </TitleBar>

        <Layout.Content>
          <br />
          <LoadingMessage loading={partner.isLoading} />
          <ErrorMessage error={partner.error} />

          {partner.data && (
            <Data>
              <Data.Item>
                <Data.Label>ID:</Data.Label>
                <Data.Content sx={{ flexGrow: 1 }}>{partnerID}</Data.Content>
                <ClipboardOrNothing text={partnerID} />
              </Data.Item>

              <Data.Item>
                <Data.Label>Published:</Data.Label>
                <Data.Content>
                  <UPChip
                    color={partner.data.published ? "green" : "grey"}
                    label={partner.data.published ? "published" : "unpublished"}
                    size="small"
                  />
                </Data.Content>
              </Data.Item>

              <Data.Item>
                <Data.Label>Online Name:</Data.Label>
                <Data.Content>{partner.data.onlineName}</Data.Content>
              </Data.Item>

              <Data.Item>
                <Data.Label>Domain:</Data.Label>
                <Data.Content sx={{ flexGrow: 1 }}>
                  <Link href={partner.data.domain} target="_blank">
                    {partner.data.domain}
                  </Link>
                </Data.Content>
                <ClipboardOrNothing text={partner.data.domain} />
              </Data.Item>

              <Data.Item>
                <Data.Label>Support Email:</Data.Label>
                <Data.Content sx={{ flexGrow: 1 }}>
                  {partner.data.supportEmail}
                </Data.Content>
                <ClipboardOrNothing text={partner.data.supportEmail} />
              </Data.Item>

              <Data.Item>
                <Data.Label>Canvas:</Data.Label>
                <Data.Content sx={{ flexGrow: 1 }}>
                  <Link href={partner.data.linkLMS} target="_blank">
                    {partner.data.linkLMS}
                  </Link>
                </Data.Content>
                <ClipboardOrNothing text={partner.data.linkLMS} />
              </Data.Item>
              {partner.data.endOfCourseSurveyURL ? (
                <Data.Item>
                  <Data.Label>End of Course Survey URL:</Data.Label>
                  <Data.Content sx={{ flexGrow: 1 }}>
                    <Link
                      href={partner.data.endOfCourseSurveyURL}
                      target="_blank"
                    >
                      {partner.data.endOfCourseSurveyURL}
                    </Link>
                  </Data.Content>
                  <ClipboardOrNothing
                    text={partner.data.endOfCourseSurveyURL}
                  />
                </Data.Item>
              ) : null}
            </Data>
          )}
        </Layout.Content>
      </Layout>
    </>
  );
};

export default PartnersShowRoute;
