import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import NotInterestedIcon from "@mui/icons-material/NotInterested";
import Box from "@mui/material/Box";

import { ApplicationCancelDialog } from "@/components/ApplicationCancelDialog";
import { Drawer, DrawerManager } from "@/components/Drawer";
import { TitleBarButton } from "@/components/TitleBarButton";
import { useAlert } from "@/hooks/use-alert";
import Application from "@/models/application";
import { useApplication } from "@/models/application/hooks";
import { useScholarship } from "@/models/scholarship/hooks";
import ShowView from "@/routes/Applications/components/ShowView";

import type { TUseProfile } from "@/models/profile/types";

export const ApplicationDrawer = DrawerManager.create(function ({
  cancelApplicationSuccessCallback,
  profileQuery,
  successCallback,
}: {
  cancelApplicationSuccessCallback?: () => void;
  profileQuery: TUseProfile;
  successCallback?: (data: unknown) => void;
}) {
  const showAlert = useAlert();
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const EMAIL = profileQuery.data?.email;
  const PARTNER_ID = profileQuery.data?.partner._id;

  const applicationQuery = useApplication(EMAIL, PARTNER_ID, {
    enabled: Boolean(profileQuery.data),
  });
  const scholarshipQuery = useScholarship(EMAIL, PARTNER_ID, {
    enabled: Boolean(profileQuery.data),
  });

  const updateMutation = useMutation({
    mutationFn: Application.save,
    onSuccess(data) {
      applicationQuery.refetch();
      if (successCallback) {
        successCallback(data);
      }
    },
    onError() {
      showAlert({ message: "An error has occurred", severity: "error" });
    },
  });

  const cancelMutation = useMutation({
    mutationFn() {
      return Application.cancel(applicationQuery.data);
    },
    onSuccess() {
      showAlert({
        message: `Application cancelled`,
      });
      applicationQuery.refetch();
      setShowCancelDialog(false);
      cancelApplicationSuccessCallback?.();
    },
  });

  return (
    <Drawer
      loading={
        updateMutation.isPending ||
        applicationQuery.isLoading ||
        scholarshipQuery.isLoading
      }
      title="Application"
      toolbarChildren={
        <>
          <TitleBarButton
            onClick={() => {
              setShowCancelDialog(true);
            }}
            startIcon={<NotInterestedIcon />}
          >
            Cancel Application
          </TitleBarButton>
        </>
      }
    >
      <ApplicationCancelDialog
        cancelMutation={cancelMutation}
        close={() => {
          setShowCancelDialog(false);
        }}
        isOpen={showCancelDialog}
      />
      {profileQuery.data && (
        <Box sx={{ p: 2, bgcolor: "background.paper" }}>
          {applicationQuery.isLoading && <div>Loading...</div>}
          {applicationQuery.data && (
            <ShowView
              applicationQuery={applicationQuery}
              isSaving={updateMutation.isPending}
              profileQuery={profileQuery}
              scholarshipQuery={scholarshipQuery}
              saveApplication={updateMutation.mutate}
            />
          )}
        </Box>
      )}
    </Drawer>
  );
});
