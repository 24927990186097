const BASE = import.meta.env.VITE_API_ENDPOINT;

const routes = {
  index(qp) {
    return `${BASE}/mentors/?${qp}`;
  },

  post() {
    return `${BASE}/mentors/`;
  },

  show(email) {
    return `${BASE}/mentors/${email}`;
  },

  delete(email) {
    return `${BASE}/mentors/${email}`;
  },

  patch() {
    return `${BASE}/mentors/`;
  },

  assignment: {
    index() {
      return `${BASE}/mentor/assignments`;
    },
    list(inventoryId: string) {
      return `${BASE}/mentors/list/inv/${inventoryId}`;
    },
    assign(email: string) {
      return `${BASE}/mentors/inv/${email}`;
    },
    unassign(email: string) {
      return `${BASE}/mentors/inv/${email}`;
    },
  },

  search: {
    search() {
      return `${BASE}/mentors/search`;
    },

    show(email: string) {
      return `${BASE}/mentors/${email}`;
    },

    list(catalogueId: string) {
      return `${BASE}/mentors/list/cat/${catalogueId}`;
    },
  },

  qualifiedCatalogueItems: {
    // TODO: index not being used?
    index(email) {
      return `${BASE}/mentors/${email}/qualified`;
    },

    post(email) {
      return `${BASE}/mentors/cat/${email}`;
    },

    patch(email) {
      return `${BASE}/mentors/cat/${email}`;
    },

    delete(email) {
      return `${BASE}/mentors/cat/${email}`;
    },
  },
} as const;

export default routes;
