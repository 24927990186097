import MiniSearch from "minisearch";

const GetMiniSearchedMentors = (data = [], keyword) => {
  if (!keyword) {
    return data;
  }
  const miniSearch = new MiniSearch({
    fields: ["email", "firstName", "lastName"],
    idField: "_id",
    searchOptions: {
      prefix: true,
    },
    storeFields: ["_id"],
  });
  miniSearch.addAll(data);
  const result_ids = miniSearch.search(keyword),
    results = result_ids.map((result_id) =>
      data.find((item) => item._id === result_id.id),
    );
  return results;
};

export default GetMiniSearchedMentors;
