import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";

import { useDrawer } from "@/components/Drawer";
import MentorAssignmentDrawer from "@/components/Drawers/MentorAssignmentDrawer";
import { EmptyIndexMessage } from "@/components/EmptyIndexMessage";
import { Layout } from "@/components/Layout";
import { TitleBar } from "@/components/TitleBar";
import { useAlert } from "@/hooks/use-alert";
import { serializeData, unSerializeData } from "@/lib/helpers";
import Inventory from "@/models/inventory";
import { usePartners } from "@/models/partner/hooks";

import SearchForm from "./components/SearchForm";
import SearchResults from "./components/SearchResults";

const MentorAssignRoute = function () {
  const AssignMentorDrawer = useDrawer(MentorAssignmentDrawer);
  const showAlert = useAlert();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = unSerializeData(searchParams, [
    "catalogueItem_ids",
    "partner_ids",
  ]);

  // Data Fetching -------------------------------------------------------------

  const partners = usePartners();

  const inventorySearch = useInfiniteQuery({
    queryKey: Inventory.findAll.queryKey(queryParams),
    queryFn(context) {
      const cursor = context.pageParam;
      return Inventory.findAll.queryFn(queryParams, cursor);
    },
    enabled: searchParams.size > 0,
    getNextPageParam(lastPage) {
      return lastPage.cursor;
    },
    initialPageParam: 0,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = inventorySearch;

  useEffect(
    function autoFetch() {
      if (isFetchingNextPage) {
        return;
      }

      if (hasNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage],
  );

  // Event Handlers ------------------------------------------------------------

  const submitSearch = (data) => {
    setSearchParams(serializeData(data));
  };

  const handleAssign = function (inventoryItem) {
    AssignMentorDrawer.show({
      cancel: AssignMentorDrawer.remove,
      inventoryItem,
      successCallback() {
        showAlert({
          message: "Inventory item saved",
        });
        AssignMentorDrawer.remove();
      },
    });
  };

  return (
    <Layout>
      <TitleBar title="Mentor Assignments" />

      <SearchForm
        onSubmit={submitSearch}
        partners={partners}
        queryParams={queryParams}
      />

      <div
        style={{
          display: "flex",
          width: "100%",
          flexGrow: 1,
          position: "relative",
        }}
      >
        <Layout.Content>
          <Routes>
            <Route
              element={
                searchParams.size > 0 ? (
                  <SearchResults
                    handleAssign={handleAssign}
                    search={inventorySearch}
                    queryParams={queryParams}
                  />
                ) : (
                  <EmptyIndexMessage text="Perform a search to view a class" />
                )
              }
              index
            ></Route>
          </Routes>
        </Layout.Content>
      </div>
    </Layout>
  );
};

export default MentorAssignRoute;
