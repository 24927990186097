import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";

export default function SidebarToggle({ onClick }: { onClick: () => void }) {
  return (
    <IconButton
      aria-label="Show Navigation"
      color="inherit"
      edge="start"
      onClick={onClick}
      sx={{
        display: { md: "none" },
        position: "fixed",
        top: { md: "0.79rem" },
        left: "1rem",
        zIndex: 100000,
      }}
    >
      <SvgIcon width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          d="M3 7a2.5 2.5 0 012.5-2.5h13A2.5 2.5 0 0121 7v10a2.5 2.5 0 01-2.5 2.5h-13A2.5 2.5 0 013 17V7zm2.5-1.5A1.5 1.5 0 004 7v10a1.5 1.5 0 001.5 1.5h13A1.5 1.5 0 0020 17V7a1.5 1.5 0 00-1.5-1.5h-13z"
          clipRule="evenodd"
        ></path>
        <path
          fillRule="evenodd"
          d="M10 18.5v-13h1v13h-1zM5.25 8h3.5v1h-3.5V8zM5.25 10h3.5v1h-3.5v-1zM5.25 12h3.5v1h-3.5v-1z"
          clipRule="evenodd"
        ></path>
      </SvgIcon>
    </IconButton>
  );
}
