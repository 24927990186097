import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { styled } from "@mui/material/styles";

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  overflow: "hidden",
  borderRadius: "8px",
  padding: "2px",
  boxShadow: "none",
  background: "#222",
  "& .MuiButtonGroup-grouped": {
    borderRadius: "6px !important",
    color: "#ddd",
    lineHeight: "1.5",
    ...theme.applyStyles("light", {
      color: "#222",
    }),
  },
  "& .MuiButton-containedPrimary": {
    backgroundColor: "#5B5A60",
    boxShadow: `0 0 5px 0 ${"#111"}`,
    ...theme.applyStyles("light", {
      backgroundColor: "#fff",
      boxShadow: `0 0 5px 0 ${"#aaa"}`,
    }),
  },
  "& .MuiButton-containedSecondary": {
    backgroundColor: "transparent",
  },
  "& .MuiButtonGroup-grouped:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiButton-containedPrimary.MuiButtonGroup-grouped:hover": {
    backgroundColor: "#5B5A60",
    boxShadow: `0 0 5px 0 ${"#111"}`,
    ...theme.applyStyles("light", {
      backgroundColor: "#fff",
      boxShadow: `0 0 5px 0 ${"#aaa"}`,
    }),
  },
  "& .MuiButtonGroup-grouped:not(:last-of-type)": {
    border: "none",
  },
  ...theme.applyStyles("light", {
    background: "#eee",
  }),
}));

const SegmentedButton = function ({ selected = false, ...props }) {
  return <Button {...props} color={selected ? `primary` : "secondary"} />;
};

export const SegmentedButtons = function (props) {
  return <StyledButtonGroup variant="contained" {...props} />;
};

SegmentedButtons.Button = SegmentedButton;
