const BASE = import.meta.env.VITE_API_ENDPOINT;

const routes = {
  switch(email: string) {
    return `${BASE}/enrollments/switch/${email}`;
  },

  delete(inventoryId: string) {
    return `${BASE}/enrollments/${inventoryId}`;
  },

  deleteEnrollment(inventoryId: string, email: string) {
    return `${BASE}/enrollments/${inventoryId}/${email}`;
  },

  withdrawEnrollment(inventoryId: string, email: string) {
    return `${BASE}/enrollments/${inventoryId}/${email}`;
  },

  activeEnrollments(partnerId: string, email: string) {
    return `${BASE}/enrollments/${partnerId}/${email}`;
  },

  lmsEnrollment(partnerId: string, inventoryId: string, email: string) {
    return `${BASE}/ucs/partner/${partnerId}/inventory/${inventoryId}/user/${email}`;
  },

  sections(partnerId: string, inventoryId: string) {
    return `${BASE}/ucs/partner/${partnerId}/inventory/${inventoryId}`;
  },

  switchSections(partnerId: string, inventoryId: string, email: string) {
    return `${BASE}/ucs/partner/${partnerId}/inventory/${inventoryId}/user/${email}`;
  },
} as const;

export default routes;
