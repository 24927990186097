import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useIsMounted } from "usehooks-ts";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import { FORM_PAPER_SX } from "@/constants";
import { usePartners } from "@/models/partner/hooks";

import { AddCatalogueItems } from "./AddCatalogueItems";
import { CatalogueItemShow } from "./CatalogueItemShow";

import type { TMentorQualifiedCatalogueItem } from "@/models/mentor/types";

type Props = {
  cancel: () => void;
  email: string;
  onSuccess: () => void;
  items: TMentorQualifiedCatalogueItem[];
};

export const MentorQualifiedCoursesForm = function MentorQualifiedCoursesForm(
  props: Props,
) {
  const didMount = useIsMounted();

  const partners = usePartners();

  const [catalogueItems, setCatalogueItems] = useState<
    TMentorQualifiedCatalogueItem[]
  >(props.items);

  const add = (newCatalogueItems: TMentorQualifiedCatalogueItem[]) => {
    setCatalogueItems([...newCatalogueItems, ...catalogueItems]);
    props.onSuccess();
  };

  const remove = (itemToRemove: TMentorQualifiedCatalogueItem) => {
    const keepers = catalogueItems.filter((i) => {
      return i._id !== itemToRemove._id;
    });

    setCatalogueItems(keepers);
    props.onSuccess();
  };

  const usedItems = catalogueItems.map((item) => item._id);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", pt: 2 }}>
      <Box
        sx={{
          ...FORM_PAPER_SX,
          marginTop: 0,
          marginBottom: 2,
          border: "1px dashed rgba(153,153,153, 0.6)",
          boxShadow: "none",
          borderRadius: 1.5,
          py: 2,
        }}
      >
        <AddCatalogueItems
          email={props.email}
          partners={partners}
          save={add}
          usedItems={usedItems}
        />
      </Box>
      <AnimatePresence>
        {catalogueItems?.map((item) => {
          return (
            <motion.div
              key={item._id}
              initial={didMount() ? { opacity: 0, height: 0 } : false}
              transition={{
                ease: [0.76, 0, 0.24, 1],
                duration: 0.5,
              }}
              animate={{
                opacity: 1,
                height: "auto",
                top: 0,
                zIndex: 10,
              }}
              exit={{
                opacity: 0,
                height: 0,
                top: "10px",
                zIndex: 9,
              }}
              style={{
                position: "relative",
                overflow: "hidden",
                zIndex: 9,
                opacity: 0,
                top: "-50px",
              }}
            >
              <Box sx={{ pb: 2 }}>
                <Paper
                  elevation={1}
                  sx={{
                    ...FORM_PAPER_SX,
                    marginTop: 0,
                    marginBottom: 0,
                    position: "relative",
                  }}
                  className="animation-fade-in"
                >
                  <CatalogueItemShow
                    catalogueItem={item}
                    email={props.email}
                    remove={() => {
                      remove(item);
                    }}
                  />
                </Paper>
              </Box>
            </motion.div>
          );
        })}
      </AnimatePresence>
    </Box>
  );
};
