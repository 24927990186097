import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

export default function Contact({ profileData }) {
  const TWO_COLUMN_STYLE = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "2rem",
    listStyle: "none",
    margin: 0,
    padding: 0,
  };
  return (
    <>
      <Typography display="block" variant="body1">
        {`Age: ${profileData.age}`}
        <Link
          display="block"
          noWrap
          rel="noopener noreferrer"
          sx={{ mr: 1 }}
          target="_blank"
          underline="always"
          variant="body1"
          href={`https://app.hubspot.com/contacts/6703643/contact/${profileData.hubspotContactId}`}
        >
          Hubspot Contact
        </Link>
      </Typography>
      {profileData.address && (
        <Box py={2}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Address
          </Typography>
          <ul style={TWO_COLUMN_STYLE}>
            <li style={{ gridColumn: "span 2" }}>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow key="profileMailingAddress1">
                      <TableCell size="small" sx={{ opacity: 0.7 }}>
                        Street 1:
                      </TableCell>
                      <TableCell size="small">
                        {profileData.address.street1}
                      </TableCell>
                    </TableRow>
                    {profileData.address?.street2 && (
                      <TableRow key="profileMailingAddress2">
                        <TableCell size="small" sx={{ opacity: 0.7 }}>
                          Street 2:
                        </TableCell>
                        <TableCell size="small">
                          {profileData.address.street2}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow key="profileCity">
                      <TableCell size="small" sx={{ opacity: 0.7 }}>
                        City:
                      </TableCell>
                      <TableCell size="small">
                        {profileData.address.city}
                      </TableCell>
                    </TableRow>
                    {profileData.address?.state && (
                      <TableRow key="profileState">
                        <TableCell size="small" sx={{ opacity: 0.7 }}>
                          State:
                        </TableCell>
                        <TableCell size="small">
                          {profileData.address.state}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow key="profileZip">
                      <TableCell size="small" sx={{ opacity: 0.7 }}>
                        Zip/Postal Code:
                      </TableCell>
                      <TableCell size="small">
                        {profileData.address.zipCode}
                      </TableCell>
                    </TableRow>
                    <TableRow key="profileCountry">
                      <TableCell size="small" sx={{ opacity: 0.7 }}>
                        Country:
                      </TableCell>
                      <TableCell size="small">
                        {profileData.address.country}
                      </TableCell>
                    </TableRow>
                    {profileData.address?.region && (
                      <TableRow key="profileMailingInternationalRegion">
                        <TableCell size="small" sx={{ opacity: 0.7 }}>
                          International Region:
                        </TableCell>
                        <TableCell size="small">
                          {profileData.address.region}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </li>
          </ul>
        </Box>
      )}
    </>
  );
}
