export default function requestOptions(
  routes,
  ...urlParams
): {
  url: string;
  method: "patch" | "post";
} {
  const params = urlParams.filter(Boolean);
  return params.length
    ? { url: routes.patch.apply(null, params), method: "patch" }
    : { url: routes.post.apply(null, params), method: "post" };
}
