import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

export default function ThirdParty() {
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="overline">Third Party Services</Typography>
        <Typography>
          <Link
            href="https://braze.statuspage.io/"
            display="inline"
            variant="body2"
            target="_blank"
            rel="noopener noreferrer"
            underline="always"
            sx={{
              mr: 2,
            }}
          >
            Braze
          </Link>
          <Link
            href="https://status.hubspot.com"
            display="inline"
            variant="body2"
            target="_blank"
            rel="noopener noreferrer"
            underline="always"
            sx={{
              mr: 2,
            }}
          >
            HubSpot
          </Link>
          <Link
            href="https://status.zendesk.com"
            display="inline"
            variant="body2"
            target="_blank"
            rel="noopener noreferrer"
            underline="always"
            sx={{
              mr: 2,
            }}
          >
            Zendesk
          </Link>
          <Link
            href="https://status.stripe.com"
            display="inline"
            variant="body2"
            target="_blank"
            rel="noopener noreferrer"
            underline="always"
            sx={{
              mr: 2,
            }}
          >
            Stripe
          </Link>
          <Link
            href="https://status.prismic.io"
            display="inline"
            variant="body2"
            target="_blank"
            rel="noopener noreferrer"
            underline="always"
            sx={{
              mr: 2,
            }}
          >
            Prismic
          </Link>
          <Link
            href="https://www.vercel-status.com"
            display="inline"
            variant="body2"
            target="_blank"
            rel="noopener noreferrer"
            underline="always"
            sx={{
              mr: 2,
            }}
          >
            Vercel
          </Link>
          <Link
            href="https://health.aws.amazon.com/health/status"
            display="inline"
            variant="body2"
            target="_blank"
            rel="noopener noreferrer"
            underline="always"
            sx={{
              mr: 2,
            }}
          >
            AWS
          </Link>
        </Typography>
      </CardContent>
    </Card>
  );
}
