import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";

export const Spinner = function Spinner({
  loading = false,
  sx = {},
}: {
  loading: boolean;
  sx?: Record<string, unknown>;
}) {
  return (
    <Fade
      in={loading}
      style={{
        transitionDelay: loading ? "400ms" : "0ms",
      }}
      unmountOnExit
    >
      <CircularProgress color="secondary" size="1rem" sx={sx} />
    </Fade>
  );
};
