import { Fragment } from "react";

import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

import { UPChip } from "@/components/UPChip";
import { INVENTORY_STATUS_MAP } from "@/constants";
import { formatInventoryDate } from "@/lib/helpers";
import { InventoryStatus } from "@/models/inventory/constants";

const setColor = (isOn) => {
  return isOn ? "green" : "off";
};

const dateStyles = {
  position: "absolute",
  bottom: 0,
  left: 0,
  transform: "translateX(-50%)",
  textAlign: "center",
};

const StatusBoard = ({
  status,
  enrollment,
  purchase,
  appDeadline,
  enrollDeadline,
}) => {
  const statusEntries = Object.entries(INVENTORY_STATUS_MAP).filter(
    (entry) => !entry.includes(InventoryStatus.Canceled),
  );
  const pastEnrollmentDeadline = Date.now() > new Date(enrollDeadline);
  return status === InventoryStatus.Canceled ? (
    <div
      style={{
        margin: "1em 0",
      }}
    >
      <UPChip size="large" color="red" label={INVENTORY_STATUS_MAP[status]} />
    </div>
  ) : (
    <div
      style={{
        position: "relative",
        display: "grid",
        gap: ".75rem 4px",
        gridTemplateColumns: "repeat(5, 1fr auto) 1fr",
        marginTop: "2.25rem",
        width: "100%",
      }}
    >
      <div style={{ gridRow: 1, gridColumn: 4, position: "relative" }}>
        <Typography component="span" variant="caption" sx={dateStyles}>
          {formatInventoryDate(appDeadline)}
        </Typography>
      </div>
      <div style={{ gridRow: 1, gridColumn: 8, position: "relative" }}>
        <Typography component="span" variant="caption" sx={dateStyles}>
          {formatInventoryDate(enrollDeadline)}
        </Typography>
      </div>
      {statusEntries.map(([code, label], i) => (
        <Fragment key={code}>
          <UPChip
            style={{ gridRow: 3, gridColumn: i * 2 + 1 }}
            label={label}
            color={setColor(code === status)}
          />
          {i < statusEntries.length - 1 ? (
            <Divider
              flexItem
              orientation="vertical"
              sx={{
                gridRow: "1 / 7",
                gridColumn: i * 2 + 2,
                margin: "0 .25rem",
                marginTop: "2px",
              }}
            />
          ) : null}
        </Fragment>
      ))}

      <UPChip
        style={{ gridColumn: "3 / 6", gridRow: 4, zIndex: 10 }}
        label="Available for Purchase"
        color={setColor(purchase)}
      />
      <UPChip
        style={{ gridColumn: "3 / 8", gridRow: 5, zIndex: 10 }}
        label="Enrollment Open to Public"
        color={setColor(!pastEnrollmentDeadline)}
      />
      <UPChip
        style={{ gridColumn: "9", gridRow: 5, zIndex: 10 }}
        label="Enrollment by Agent Only"
        color={setColor(pastEnrollmentDeadline && enrollment)}
      />

      <div style={{ gridRow: 6, height: "4px" }} aria-hidden />
    </div>
  );
};

export default StatusBoard;
