import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";

import clientRoutes from "@/lib/client-routes";

const CouponItemButton = ({ coupon }) => {
  const to = clientRoutes.coupons.show(coupon.code);
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  const navigate = useNavigate();

  const ListItemSX = {
    borderRadius: 2,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 2,
    overflow: "hidden",
  };

  return (
    <ListItemButton
      className={match ? "Mui-selected" : ""}
      onClick={() => {
        navigate(to, {
          state: {
            coupon: coupon,
          },
        });
      }}
      sx={ListItemSX}
    >
      <Stack sx={{ py: 1 }}>
        <ListItemText
          primary={coupon.code}
          primaryTypographyProps={{
            variant: "body2",
          }}
        />
      </Stack>
    </ListItemButton>
  );
};
export default CouponItemButton;
