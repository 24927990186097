import { useState } from "react";

import RefundItem from "./RefundItem";

function decorateLineItems(data) {
  return data.reduce((obj, item) => {
    return {
      ...obj,
      [item._id]: { ...item, amount: item.priceToPay, selected: false },
    };
  }, {});
}

export default function Enrollments({ data, setValue }) {
  const [lineItems, setLineItems] = useState(decorateLineItems(data));

  const toggleSelect = function (lineItem) {
    const newItems = {
      ...lineItems,
      [lineItem._id]: { ...lineItem, selected: !lineItem.selected },
    };
    setLineItems(newItems);
    setScopedItems(newItems);
  };

  const handleAdjustment = (lineItem, amount) => {
    const newItems = {
      ...lineItems,
      [lineItem._id]: { ...lineItem, amount: amount },
    };
    setLineItems(newItems);
    setScopedItems(newItems);
  };

  const setScopedItems = function (items) {
    const selectedLineItems = {};
    Object.values(items).forEach((item) => {
      if (item.selected) {
        selectedLineItems[item._id] = item;
      }
    });

    setValue(
      "refund.scopedItems",
      Object.values(selectedLineItems).map((lineItem) => {
        const newData = { lineItem: { ...lineItem }, amount: lineItem.amount };
        delete newData.lineItem.amount;
        delete newData.lineItem.selected;
        return newData;
      }),
    );
  };

  return (
    <div>
      {data &&
        Object.values(lineItems).map((lineItem) => {
          return (
            <RefundItem
              key={lineItem._id}
              lineItem={lineItem}
              toggleSelect={toggleSelect}
              handleAdjustment={handleAdjustment}
            />
          );
        })}
    </div>
  );
}
