import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DatePicker } from "@/components/DatePicker";

export default function SetExpirationDate({ control }) {
  return (
    <>
      <Typography gutterBottom variant="body1" fontWeight="500">
        Expiration Date
      </Typography>
      <br />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          control={control}
          label="Expiration Date"
          name="expiration"
        />
      </LocalizationProvider>
    </>
  );
}
