import { useQuery } from "@tanstack/react-query";

import Order from "./index";

export const useOrder = (id: string) => {
  return useQuery({
    queryKey: Order.findById.queryKey(id),
    queryFn() {
      return Order.findById.queryFn(id);
    },
  });
};
