import { isFuture } from "date-fns";
import { Suspense } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";

import { CopyToClipboardButton } from "@/components/CopyToClipboardButton";
import { useDrawer } from "@/components/Drawer";
import CouponFormDrawer from "@/components/Drawers/CouponFormDrawer";
import { TitleBarButton } from "@/components/TitleBarButton";
import { useAlert } from "@/hooks/use-alert";
import { formatDate } from "@/lib/helpers";
import { CouponAmountType, CouponType } from "@/models/coupon/constants";
import { useCoupon } from "@/models/coupon/hooks";
import InventoryItemName from "@/routes/Coupons/components/Form/InventoryItemName";

export default function Coupon({ couponID }) {
  const couponData = useCoupon(couponID);
  const couponFormDrawer = useDrawer(CouponFormDrawer);
  const showAlert = useAlert();

  const getFormattedDate = (date) => {
    return formatDate(date);
  };

  const dateIsInFuture = (date) => {
    return isFuture(new Date(date));
  };

  const getSubTitle = (couponType, partnerName) => {
    let formattedPartner = "";
    let formattedType = `Type: Promotional`;

    if (couponType === CouponType.PartialScholarship) {
      formattedType = `Type: Partial Scholarship`;
    } else if (couponType === CouponType.FullScholarship) {
      formattedType = `Type: Full Scholarship`;
    } else {
      formattedType = `Type: Promotional`;
    }

    if (partnerName) {
      formattedPartner = `Partner: ${partnerName}`;
    }

    return (
      <>
        {formattedPartner}
        <br />
        {formattedType}
      </>
    );
  };

  const getAmountOff = (amount, amountType) => {
    if (amountType === CouponAmountType.Percentage) {
      return `Amount Off: ${amount}%`;
    } else {
      return `Amount Off: $${amount}`;
    }
  };

  const handleEdit = (couponToEdit) => {
    couponFormDrawer.show({
      cancel() {
        couponFormDrawer.remove();
      },
      data: couponToEdit,
      errorCallback() {
        showAlert({
          message: `An error has ocurred!`,
          severity: "error",
          duration: 10000,
        });
      },
      successCallback() {
        couponData.refetch();
        showAlert({
          message: `Successfully saved changes to coupon`,
        });
        couponFormDrawer.remove();
      },
    });
  };

  return (
    <>
      {couponData.isLoading ? <div>Loading...</div> : null}
      {couponData.error ? <div>Could not load coupon</div> : null}
      {couponData.data && dateIsInFuture(couponData.data?.expiration) && (
        <Card variant="outlined">
          <CardHeader
            title={couponData.data.code}
            subheader={getSubTitle(
              couponData.data.type,
              couponData.data.partner.name,
            )}
            action={
              <>
                <CopyToClipboardButton text={couponData.data.code} />
                <TitleBarButton
                  onClick={() => {
                    handleEdit(couponData.data);
                  }}
                  icon="edit"
                >
                  Edit
                </TitleBarButton>
              </>
            }
          />
          <CardContent>
            <Typography variant="body2">
              {getAmountOff(couponData.data.amount, couponData.data.amountType)}
            </Typography>
            <Typography variant="body2">
              {`Coupon Creation Date: ${getFormattedDate(
                couponData.data.createdAt,
              )}`}
            </Typography>
            <Typography variant="body2">
              {couponData.data.expiration
                ? `Expiration Date: ${getFormattedDate(
                    couponData.data.expiration,
                  )}`
                : "Will never expire"}
            </Typography>
            <Typography variant="body2">
              {`Max Uses: ${
                couponData.data.maxUses === 0
                  ? "Unlimited"
                  : couponData.data.maxUses
              }`}
            </Typography>
            <Typography variant="body2">{`Redemption Count: ${couponData.data.useCount}`}</Typography>
            <div>
              {!couponData.data?.scopedItems.length ? (
                <>
                  <Typography variant="body2" style={{ fontStyle: "italic" }}>
                    No scoped items.
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="body2">Scoped Items:</Typography>
                  {Boolean(couponData.data.scopedItems.length) && (
                    <Suspense
                      fallback={
                        <div>
                          <CircularProgress color="secondary" size="1rem" />{" "}
                          Loading...
                        </div>
                      }
                    >
                      <List>
                        {couponData.data.scopedItems.map((item, index) => (
                          <InventoryItemName key={index} _id={item._id} />
                        ))}
                      </List>
                    </Suspense>
                  )}
                </>
              )}
            </div>
          </CardContent>
        </Card>
      )}
      <br />
    </>
  );
}
