const DESIRED_KEYS = [
  "lunchProgram",
  "snap",
  "publicHousing",
  "medicaid",
  "ssdi",
  "satRegistrationFeeWaiver",
  "pellGrant",
  "otherBenefitsUS",
  "otherBenefitsNonUS",
  "privateSchoolAssistance",
];

const getScore = (scholarshipItem) =>
  Object.entries(scholarshipItem).reduce((previousValue, [key, val]) => {
    if (!DESIRED_KEYS.includes(key)) {
      return previousValue;
    }
    if (val !== "yes") {
      return previousValue;
    }
    return previousValue + 1;
  }, 0);

export default getScore;
