import { Controller } from "react-hook-form";

import { FormControl, TextField } from "@mui/material";

function CustomCode({ control }) {
  return (
    <FormControl fullWidth margin="normal">
      <Controller
        control={control}
        name="code"
        render={({ field: { ref, ...rest } }) => {
          return (
            <TextField
              inputRef={ref}
              label="Custom code"
              required
              color="primary"
              variant="outlined"
              {...rest}
            />
          );
        }}
      />
    </FormControl>
  );
}

export default CustomCode;
