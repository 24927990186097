import { tz } from "@date-fns/tz";
import { format, parseISO } from "date-fns";

import createDate from "./create-date";

const DATE_PATTERN = "MMM dd, yyyy";

/**
 * @param {string} value
 * @param {string} [pattern]
 * @returns {string}
 */
export const formatInventoryDate = (value) => {
  const shortZoneName = new Date(value)
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];

  return `${format(
    parseISO(value, {
      in: tz("America/Los_Angeles"),
    }),
    "M/dd/yyyy hh:mm",
  )} ${shortZoneName}`;
};

/**
 * @param {string | Date} value
 * @param {string} [pattern]
 * @returns {string}
 */
export const formatDate = function (value, pattern = DATE_PATTERN) {
  return format(new Date(value), pattern);
};

/**
 * @param {string | number} priceInCents
 * @returns {number}
 */
export const displayPrice = (priceInCents) => {
  const priceAsInteger = parseInt(priceInCents);
  if (isNaN(priceAsInteger)) return "";
  return Math.floor(priceAsInteger / 100);
};

/**
 * @param {string | number} priceAsDollars
 * @returns {number}
 * @example
 * priceToCents(1) => 100
 * priceToCents("1") => 100
 */
export const priceToCents = (priceAsDollars) => {
  const cents = priceAsDollars * 100;
  if (isNaN(cents)) return "";
  return cents;
};

/**
 * @param {string | number} cents
 * @returns {string}
 * @example
 * centsToPrice(100) => "1.00"
 * centsToPrice("100") => "1.00"
 */
export const centsToPrice = (cents) => {
  const centsAsInteger = parseInt(cents);
  if (isNaN(centsAsInteger)) return "";
  return (centsAsInteger / 100).toFixed(2);
};

/**
 * @param {string | number} dollars
 * @returns {string}
 */
export const dollarsToFixed = (dollars) => {
  const formattedDollars = dollars.toString().replace(/[^\d.-]/g, ""); //strip all characters away except digits and period
  const dollarsAsInterger = parseInt(formattedDollars);
  if (isNaN(dollarsAsInterger)) return "";
  return dollarsAsInterger.toFixed(2);
};

export const refundedAmount = (enrollment) => {
  const chargesList = enrollment.paymentIntent?.charges?.data || [];
  const amountRefunded = chargesList?.[0]?.amount_refunded || 0;
  return amountRefunded;
};

export const amountPaid = (enrollment) => {
  return enrollment.paymentIntent?.amount_received || 0;
};

export const canRefund = (enrollment) => {
  const paymentStatus = enrollment.paymentIntent?.status;
  const paidAmount = amountPaid(enrollment);
  const price = enrollment.price;
  const amountRefunded = refundedAmount(enrollment);
  return (
    paymentStatus === "succeeded" &&
    paidAmount > 0 &&
    paidAmount === price &&
    amountRefunded < paidAmount &&
    !enrollment.isOutsidePayer
  );
};

/**
 * @param {Date} [input]
 * @returns {Date}
 */
export const generateEndOfSeasonDate = (input) => {
  const fullCurrentDate = input || new Date();
  let targetYear = fullCurrentDate.getFullYear();
  const cutOffDate = new Date(targetYear, 5, 1, 23, 59, 59);
  if (cutOffDate < fullCurrentDate) targetYear++;
  return new Date(targetYear, 7, 31, 23, 59, 59);
};

const isoDateFormat =
  /^(?:(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)$)/;

/**
 * @param {unknown} value
 * @returns {boolean}
 */
export const isIsoDateString = function (value) {
  return Boolean(
    value && typeof value === "string" && isoDateFormat.test(value),
  );
};

export const searchDefault = {
  partner_ids: [],
  catalogueItem_ids: [],
  status: "",
  keyword: "",
  startDate: createDate(0, 0, 0),
  endDate: createDate(0, 9, 0),
};

export const serializeData = (data) => {
  const serializedData = {};
  for (const [key, value] of Object.entries(data)) {
    if (value.constructor === Array) {
      if (value.length > 0) {
        serializedData[key] = value.join(",");
      }
    } else if (value.constructor === Date) {
      serializedData[key] = value.toISOString();
    } else if (value !== "") {
      serializedData[key] = value;
    }
  }
  return serializedData;
};

export const unSerializeData = (searchParams, multiValueKeys = []) => {
  const unSerializedData = {};
  for (const [key, value] of searchParams) {
    if (multiValueKeys.includes(key)) {
      unSerializedData[key] = value.split(",");
    } else {
      unSerializedData[key] = value;
    }
  }
  return { ...searchDefault, ...unSerializedData };
};

export const flattenSearchResults = function (search) {
  return search.data?.pages?.length
    ? [...search.data.pages.map((p) => p.results)].flat()
    : [];
};

export const decorateSearchResults = function (results) {
  return results.map((item) => {
    return {
      ...item,
      partnerName: item.partner.name,
      partnerId: item.partner._id,
      catalogueItemName: item.catalogueItem.name,
    };
  });
};
