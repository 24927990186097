import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useDrawer } from "@/components/Drawer";
import CouponNewDrawer from "@/components/Drawers/CouponFormDrawer";
import { Layout } from "@/components/Layout";
import { TitleBar } from "@/components/TitleBar";
import { TitleBarButton } from "@/components/TitleBarButton";
import { PS_COUPON_DEFAULTS } from "@/constants";
import { useAlert } from "@/hooks/use-alert";
import { useApplication } from "@/models/application/hooks";
import { useProfile } from "@/models/profile/hooks";
import Scholarship from "@/models/scholarship";
import { useScholarship } from "@/models/scholarship/hooks";

import { ScholarshipShowView } from "./components/ShowView";

import type { TCoupon } from "@/models/coupon/types";

const ScholarshipShow = function ScholarshipShow() {
  const queryClient = useQueryClient();
  const showAlert = useAlert();
  const couponFormDrawer = useDrawer(CouponNewDrawer);
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { author, partner } = useParams();

  // Data Fetching -------------------------------------------------------------

  const profileQuery = useProfile(author, partner);
  const scholarshipQuery = useScholarship(author, partner, {
    placeholderData: location.state?.scholarship ?? null,
  });
  const applicationQuery = useApplication(author, partner);

  const updateMutation = useMutation({
    mutationFn: Scholarship.save,
    onSuccess(savedData) {
      showAlert({
        message: `Scholarship updated`,
      });
      queryClient.invalidateQueries({
        queryKey: Scholarship.findAllByStatus.queryKey(),
      });
      if (savedData.status === "approved") {
        couponFormDrawer.show({
          cancel() {
            couponFormDrawer.remove();
          },
          data: {
            ...PS_COUPON_DEFAULTS,
            partner: {
              _id: scholarshipQuery.data.partner._id,
              name: scholarshipQuery.data.partner.name,
            },
            scopedUsers: [profileQuery.data.email],
          },
          errorCallback(error) {
            const IS_409 =
              error instanceof Error && error.message.includes("409");
            showAlert({
              message: IS_409
                ? "Error! This coupon code already exists!"
                : "Error! An error has ocurred",
              severity: "error",
              duration: 10000,
            });
          },
          successCallback(data: TCoupon) {
            showAlert({
              message: `Successfully created coupon with code: ${data.code}`,
              duration: null,
            });
            couponFormDrawer.remove();
          },
        });
      }
    },
  });

  // Actions -------------------------------------------------------------

  const showCoupon = function () {
    couponFormDrawer.show({
      cancel() {
        couponFormDrawer.remove();
      },
      data: {
        ...PS_COUPON_DEFAULTS,
        partner: {
          _id: scholarshipQuery.data.partner._id,
          name: scholarshipQuery.data.partner.name,
        },
        scopedUsers: [profileQuery.data.email],
      },
      errorCallback(error) {
        const IS_409 = error instanceof Error && error.message.includes("409");
        showAlert({
          message: IS_409
            ? "Error! This coupon code already exists!"
            : "An error has ocurred!",
          horizontal: "center",
          severity: "error",
          duration: 10000,
        });
      },
      successCallback(data: TCoupon) {
        showAlert({
          message: `Successfully created coupon with code: ${data.code}`,
          horizontal: "center",
          duration: null,
        });
        couponFormDrawer.remove();
      },
    });
  };

  return (
    <Layout>
      <>
        <TitleBar
          backRoute={matches ? null : ".."}
          title={
            scholarshipQuery.data ? scholarshipQuery.data.author : "Scholarship"
          }
        >
          {scholarshipQuery.data ? (
            <TitleBarButton onClick={showCoupon} icon="add">
              New Coupon
            </TitleBarButton>
          ) : null}
        </TitleBar>

        <Layout.Content>
          <ScholarshipShowView
            applicationQuery={applicationQuery}
            isSaving={updateMutation.isPending}
            profileQuery={profileQuery}
            saveScholarship={updateMutation.mutate}
            scholarshipQuery={scholarshipQuery}
          />
        </Layout.Content>
      </>
    </Layout>
  );
};

export default ScholarshipShow;
