import Chip from "@mui/material/Chip";
import { green, grey, red } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

import type { ComponentProps } from "react";

const GreenChip = styled(Chip)(({ theme }) => ({
  backgroundColor: green[300],
  color: "black",
  fontWeight: 500,
  ...theme.applyStyles("dark", {
    backgroundColor: green[900],
    color: green[50],
  }),
}));

const GreyChip = styled(Chip)(({ theme }) => ({
  color: grey[800],
  fontWeight: 500,
  backgroundColor: grey[200],
  ...theme.applyStyles("dark", {
    color: grey[50],
    backgroundColor: grey[800],
  }),
}));

const RedChip = styled(Chip)(({ theme }) => ({
  color: "black",
  fontWeight: 500,
  backgroundColor: red[200],
  ...theme.applyStyles("dark", {
    color: red[50],
    backgroundColor: red[900],
  }),
}));

const OffChip = styled(Chip)(({ theme }) => ({
  color: grey[600],
  fontWeight: 500,
  backgroundColor: "#fff",
  outlineColor: grey[500],
  outlineWidth: "1px",
  outlineStyle: "solid",
  ...theme.applyStyles("dark", {
    color: grey[500],
    backgroundColor: "rgb(18, 18, 18)",
  }),
}));

export const UPChip = function UPChip(
  props: Omit<ComponentProps<typeof Chip>, "color"> & {
    color: "green" | "red" | "grey" | "off";
  },
) {
  const { color = "grey", size = "small", ...rest } = props;

  switch (color) {
    case "green":
      return <GreenChip size={size} {...rest} />;
    case "red":
      return <RedChip size={size} {...rest} />;
    case "off":
      return <OffChip size={size} {...rest} />;
    default:
      return <GreyChip size={size} {...rest} />;
  }
};
