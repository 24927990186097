import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { useDrawer } from "@/components/Drawer";
import ProfileDrawer from "@/components/Drawers/ProfileDrawer";

import type { TProfile } from "@/models/profile/types";

export default function Relationship({
  profileData,
}: {
  profileData: TProfile;
}) {
  const profileDrawer = useDrawer(ProfileDrawer);

  return (
    <>
      {profileData?.relatedProfiles?.length ? (
        <div>
          {profileData.relatedProfiles.map((profile, i) => {
            return (
              <div key={i} style={{ marginBottom: "2rem" }}>
                <Typography display="block" variant="overline">
                  {`${
                    profile.type.charAt(0).toUpperCase() + profile.type.slice(1)
                  }`}
                </Typography>
                <Typography display="block" variant="h6">
                  {profile.firstName ? profile.firstName : null}{" "}
                  {profile.lastName ? profile.lastName : null}
                </Typography>
                <Button
                  color="secondary"
                  endIcon={<VisibilityIcon />}
                  onClick={() => {
                    profileDrawer.show({
                      email: profile.email,
                      partnerID: profile.partner._id,
                    });
                  }}
                  size="small"
                  sx={{ mr: 1 }}
                >
                  {profile.email}
                </Button>
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <em>No relationships</em>
        </div>
      )}
    </>
  );
}
