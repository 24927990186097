import requestOptions from "@/lib/request-options";
import sanitize from "@/lib/sanitize";

import routes from "./routes";

import type { TMentor, TMentorQualifiedCatalogueItem } from "./types";
import type { TInventory } from "@/models/inventory/types";

/* Helpers
/* ------------------------------------------------------------------ */

function formatForServer(data: TMentor): TMentor {
  return {
    ...sanitize<TMentor>(data),
  };
}

function propertiesOrDefaults(data: Partial<TMentor>) {
  return {
    ...data,
  };
}

/* Finders
/* ------------------------------------------------------------------ */

const findByEmail = {
  queryFn: async function (email: string) {
    const url = routes.search.show(email);
    return await window.auth.client.get(url).json<TMentor>();
  },
  queryKey(email: string) {
    return ["mentor", email] as const;
  },
};

const findAll = {
  queryFn: async function () {
    const url = routes.search.search();
    return await window.auth.client.get(url).json<TMentor[]>();
  },
  queryKey() {
    return ["mentors"] as const;
  },
};

const findAllByCatalogueItemId = {
  queryFn: async function (id: string) {
    const url = routes.search.list(id);
    return await window.auth.client.get(url).json<TMentor[]>();
  },
  queryKey(id: string) {
    return ["qualifiedMentors", id] as const;
  },
};

const findAllByInventoryId = {
  queryFn: async function (id: string) {
    const url = routes.assignment.list(id);
    return await window.auth.client.get(url).json<TMentor[]>();
  },
  queryKey(id: string) {
    return ["assignedMentors", id] as const;
  },
};

/* Actions
/* ------------------------------------------------------------------ */

const save = async function (mentor: TMentor) {
  const { method, url } = requestOptions(routes, mentor._id);

  return await window.auth.client[method](url, {
    json: formatForServer(mentor),
  }).json<TMentor>();
};

const deleteMentor = async function (mentor: TMentor) {
  const url = routes.delete(mentor.email);
  const resp = await window.auth.client.delete(url);
  return resp.status;
};

const assign = async function ({ data, email }) {
  const url = routes.assignment.assign(email);

  return await window.auth.client
    .post(url, {
      json: data,
    })
    .json<TMentorQualifiedCatalogueItem>();
};

const unassign = async function ({
  data,
  email,
}: {
  data: TInventory;
  email: string;
}) {
  const url = routes.assignment.unassign(email);

  const resp = await window.auth.client.delete(url, {
    json: data,
  });

  return resp.status > 199 && resp.status < 300;
};

const addQualifiedCatalogueItems = async function ({ data, email }) {
  const url = routes.qualifiedCatalogueItems.post(email);

  return await window.auth.client
    .post(url, {
      json: data,
    })
    .json<TMentorQualifiedCatalogueItem>();
};

const deleteQualifiedCatalogueItems = async function (
  email: string,
  item: TMentorQualifiedCatalogueItem,
) {
  const url = routes.qualifiedCatalogueItems.delete(email);

  const resp = await window.auth.client.delete(url, {
    json: item,
  });

  return resp.status > 199 && resp.status < 300;
};

export default {
  propertiesOrDefaults,

  findByEmail,
  findAll,
  findAllByCatalogueItemId,
  findAllByInventoryId,

  save,
  deleteMentor,
  assign,
  unassign,
  addQualifiedCatalogueItems,
  deleteQualifiedCatalogueItems,
};
