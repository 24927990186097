import { useEffect, useMemo } from "react";
import { useTernaryDarkMode } from "usehooks-ts";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import components from "./components";
import palette from "./palette";
import { FONT_FAMILY } from "../constants";

import type { PropsWithChildren } from "react";
import type { TernaryDarkModeReturn } from "usehooks-ts";

const calculateMode = function (userMode: TernaryDarkModeReturn) {
  const prefersDark = userMode.isDarkMode;
  if (userMode.ternaryDarkMode === "system") {
    return prefersDark ? "dark" : "light";
  } else if (!userMode) {
    return "light";
  }

  return userMode.ternaryDarkMode;
};

const ThemeSetup = function ThemeSetup({ children }: PropsWithChildren) {
  const userMode = useTernaryDarkMode();
  const mode = calculateMode(userMode);

  useEffect(() => {
    document.body.setAttribute("mode", mode);
  }, [mode]);

  const theme = useMemo(() => {
    return createTheme({
      components: components(mode),
      palette: palette(mode),
      typography: {
        fontFamily: FONT_FAMILY,
      },
    });
  }, [mode]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeSetup;
