import Grid from "@mui/material/Grid2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import Heading from "../Heading";

const yesNo = function (bool) {
  return bool ? "yes" : "no";
};

const StudentLegalAndAddress = ({ student }) => {
  return (
    <>
      <Typography component="h3" variant="h6" sx={{ mb: 2 }}>
        Student Legal
      </Typography>

      <Grid container spacing={4}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Heading>Canada Resident</Heading>
          {yesNo(student.canResident)}
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Heading>European Union (EU) Resident</Heading>
          {yesNo(student.euResident)}
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Heading>{`Terms Of Use & Privacy Policy Accept`}</Heading>
          {yesNo(student.touppAccept)}
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Heading>{`Partner Terms Of Use & Privacy Policy Accept`}</Heading>
          {yesNo(student.partnerTouppAccept)}
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Heading>Student TCPA Consent</Heading>
          {yesNo(student.tcpaConsent)}
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Heading>Cross Partner Marketing Opt-in</Heading>
          {yesNo(student.crossPartnerMarketingOptIn)}
        </Grid>
      </Grid>

      <br />
      <br />

      <Typography component="h3" variant="h6" sx={{ mb: 2 }}>
        Student Address
      </Typography>

      <Grid container spacing={4}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Heading>Student Zip/Postal Code</Heading>
          {student.address?.zipCode}
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Heading>Student Country</Heading>
          {student.address?.country}
        </Grid>
      </Grid>

      {student.address?.region && (
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow key="studentMailingInternationalRegion">
                <TableCell size="small" sx={{ width: "50%", opacity: 0.7 }}>
                  Student International Region:
                </TableCell>
                <TableCell size="small">{student.address?.region}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default StudentLegalAndAddress;
