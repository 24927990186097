/*
 * Adapted from Ember isEmpty
 * https://github.com/emberjs/ember.js/blob/v2.12.0/packages/ember-metal/lib/is_empty.js
 */

import isNone from "@/lib/is-none";

/**
 * Verifies that a value is `null` or an empty string, empty array,
 * or empty function.
 * Constrains the rules on `isNone` by returning true for empty
 * string and empty arrays.
 * ```
 * isEmpty();               // true
 * isEmpty(null);           // true
 * isEmpty(undefined);      // true
 * isEmpty('');             // true
 * isEmpty([]);             // true
 * isEmpty({});             // false
 * isEmpty('Adam Hawkins'); // false
 * isEmpty([0,1,2]);        // false
 * isEmpty('\n\t');         // false
 * isEmpty('  ');           // false
 * ```
 */

export default function isEmpty(obj) {
  const none = isNone(obj);
  if (none) {
    return none;
  }

  if (typeof obj.size === "number") {
    return !obj.size;
  }

  const objectType = typeof obj;

  if (objectType === "object") {
    const size = obj.size;
    if (typeof size === "number") {
      return !size;
    }
  }

  if (typeof obj.length === "number" && objectType !== "function") {
    return !obj.length;
  }

  if (objectType === "object") {
    const length = obj.length;
    if (typeof length === "number") {
      return !length;
    }
  }

  return false;
}
