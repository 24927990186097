import type { Status } from "./types";

export const getTextColor = (status: Status) => {
  switch (status) {
    case "READY":
      return "text-up-green";
    case "ERROR":
      return "text-up-red";
    default:
      return "text-up-yellow";
  }
};

export const getBGColor = (status: Status) => {
  switch (status) {
    case "READY":
      return "rgb(90, 149, 59)";
    case "ERROR":
      return "rgb(164, 52, 36)";
    default:
      return "#AEAE4B";
  }
};
