import Box from "@mui/material/Box";

import type { PropsWithChildren } from "react";

export default function EmptyBox({
  children,
  sx = {},
}: PropsWithChildren<{
  sx?: Record<string, unknown>;
}>) {
  return (
    <Box
      sx={{
        border: "1px dashed rgba(153,153,153, 0.6)",
        borderRadius: 1.5,
        px: 2,
        opacity: 0.8,
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
