import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { UPChip } from "@/components/UPChip";

import { HeadingLoading } from "./Loading";

const DetailsHeader = ({ inventoryItem }) => {
  const mqSmallAndUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <Box
      sx={[
        mqSmallAndUp
          ? {
              display: "flex",
            }
          : {
              display: "block",
            },
      ]}
    >
      {inventoryItem.isLoading ? <HeadingLoading /> : null}
      {inventoryItem.data && (
        <div style={{ flexGrow: 1 }}>
          <Typography
            variant="body1"
            sx={{ fontWeight: 400, lineHeight: "1.4", opacity: 0.75 }}
          >
            {inventoryItem.data.partner.name}
            <br />
            {`${inventoryItem.data.catalogueItem.name} `}
            {inventoryItem.data.catalogueItem.type === "addOn" ? (
              <UPChip label="addOn" variant="outlined" />
            ) : null}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              wordWrap: "break-word",
              overflowWrap: "break-word",
              padding: 0,
            }}
          >
            {inventoryItem.data.name}
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default DetailsHeader;
