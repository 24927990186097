import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const CopyToClipboardController = function CopyToClipboardController({
  children,
  text,
}: {
  children: (copied: boolean) => JSX.Element;
  text: string;
}) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCopied(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [copied]);

  return (
    <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
      {children(copied)}
    </CopyToClipboard>
  );
};
