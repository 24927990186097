import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Box from "@mui/material/Box";

import { DrawerManager } from "@/components/Drawer";
import { UPTab } from "@/components/UPTab";
import Enrollment from "@/models/enrollment";

import Cart from "./Cart";
import Contact from "./Contact";
import CouponList from "./CouponList";
import EnrollmentsList from "./EnrollmentsList";
import OrderList from "./OrderList";
import Relationship from "./Relationship";

function tabProps(index) {
  return {
    id: `profile-tab-${index}`,
    value: index,
    "aria-controls": `profile-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, keepMounted = false, ...other } = props;

  return (
    <div
      aria-labelledby={`profile-tab-${index}`}
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {keepMounted ? (
        <Box
          sx={[
            {
              p: 2,
            },
            value === index
              ? {
                  visibility: "visible",
                }
              : {
                  visibility: "hidden",
                },
          ]}
        >
          {children}
        </Box>
      ) : (
        <>{value === index ? <Box sx={{ p: 2 }}>{children}</Box> : null}</>
      )}
    </div>
  );
}
const ProfileTabs = ({ email, partnerID, profile }) => {
  const enrollmentsList = useQuery({
    queryKey: Enrollment.findByUser.queryKey(partnerID, email),
    queryFn() {
      return Enrollment.findByUser.queryFn(partnerID, email);
    },
  });
  const [activeTab, setActiveTab] = useState("0");
  const [cartWasClicked, setCartWasClicked] = useState(false);
  return (
    <TabContext value={activeTab}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            allowScrollButtonsMobile
            onChange={(e, newValue) => {
              setActiveTab(newValue);
            }}
            scrollButtons="auto"
            variant="scrollable"
          >
            <UPTab label="Contact" {...tabProps("0")} />
            <UPTab label="Relationships" {...tabProps("1")} />
            <UPTab label="Orders" {...tabProps("2")} />
            <UPTab label="Coupons" {...tabProps("3")} />
            <UPTab
              label="Live Cart"
              onClick={() => {
                setCartWasClicked(true);
              }}
              {...tabProps("4")}
            />
            <UPTab label="Enrollments" {...tabProps("5")} />
          </TabList>
        </Box>
        <br />
        <TabPanel value={activeTab} index="0">
          <Contact profileData={profile.data} />
        </TabPanel>
        <TabPanel value={activeTab} index="1">
          <Relationship profileData={profile.data} />
        </TabPanel>
        <TabPanel value={activeTab} index="2">
          <OrderList
            profileData={profile.data}
            switchFn={() => {
              DrawerManager.show("profile-enrollment-switch");
            }}
          />
        </TabPanel>
        <TabPanel value={activeTab} index="3">
          <CouponList profileData={profile.data} />
        </TabPanel>
        <TabPanel keepMounted={cartWasClicked} value={activeTab} index="4">
          {profile.data ? <Cart profile={profile.data} /> : null}
        </TabPanel>
        <TabPanel value={activeTab} index="5">
          <EnrollmentsList
            enrollmentsList={enrollmentsList}
            email={email}
            partnerID={partnerID}
          />
        </TabPanel>
      </Box>
    </TabContext>
  );
};
export default ProfileTabs;
