import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import type { ElementType, ReactNode } from "react";

export default function UPDialog({
  close,
  Actions,
  Content,
  Icon,
  open,
  Title,
}: {
  close: () => void;
  Actions?: ReactNode;
  Content?: ReactNode;
  Icon?: ElementType;
  open?: boolean;
  Title?: ReactNode;
}) {
  return (
    <Dialog
      open={open === undefined ? true : open}
      onClose={close}
      maxWidth="xs"
    >
      {Title ? (
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            alignItems: "center",
            textAlign: "center",
            lineHeight: 1.3,
            fontWeight: "bold",
          }}
        >
          {Icon ? (
            <div className="dialog-icon">
              <Icon sx={{ opacity: 0.8, mt: 1, mb: 1, fontSize: "80px" }} />
            </div>
          ) : null}
          {Title}
        </DialogTitle>
      ) : null}
      {Content ? (
        <DialogContent sx={{ textAlign: "center" }}>{Content}</DialogContent>
      ) : null}
      {Actions ? (
        <DialogActions
          sx={{
            justifyContent: "center",
            pb: 2.5,
            flexWrap: "wrap",
          }}
        >
          {Actions}
        </DialogActions>
      ) : null}
    </Dialog>
  );
}
