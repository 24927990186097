import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";

import { CopyToClipboardButton } from "@/components/CopyToClipboardButton";
import clientRoutes from "@/lib/client-routes";
import { formatDate } from "@/lib/helpers";
import { useOrder } from "@/models/order/hooks";

export default function Order({ orderID }) {
  const orderData = useOrder(orderID);

  return (
    <>
      {orderData.isLoading ? <div>Loading...</div> : null}
      {orderData.data && (
        <Card variant="outlined">
          <CardHeader
            title={`Status: ${orderData.data.status}`}
            subheader={`ID: ${orderData.data._id}`}
            action={<CopyToClipboardButton text={orderData.data._id} />}
          />
          <CardContent>
            <Typography variant="body2">{`Created: ${formatDate(
              orderData.data.createdAt,
            )}`}</Typography>

            <Typography variant="body2">{`Price to pay: ${orderData.data.summary.formattedPriceToPay}`}</Typography>

            <Typography variant="body2">{`Retail Price: ${orderData.data.summary.formattedRetailPrice}`}</Typography>

            <br />

            <div>
              <Typography variant="body2">
                {orderData.data.partner.name}
              </Typography>
              {orderData.data.lineItems.map((lineItem, index) => {
                return (
                  <div key={index}>
                    <Typography variant="body1">
                      <strong>{lineItem.catalogueItem.name}</strong>
                    </Typography>
                    <Typography variant="body1">
                      <strong>{lineItem.name}</strong>
                    </Typography>
                    <Typography variant="body2" marginBottom={1}>
                      {`Price to pay: ${lineItem.formattedPriceToPay}`}
                    </Typography>
                  </div>
                );
              })}
            </div>
            <Button
              component={Link}
              to={clientRoutes.orders.show(orderData.data._id)}
              variant="outlined"
              size="small"
            >
              Order Details
            </Button>
          </CardContent>
        </Card>
      )}
      <br />
    </>
  );
}
