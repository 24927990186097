const BASE = import.meta.env.VITE_API_ENDPOINT;

const routes = {
  index() {
    return `${BASE}/v2/q/partners/all`;
  },

  post() {
    return `${BASE}/v2/c/partners/create`;
  },

  show(id) {
    return `${BASE}/v2/q/partners/${id}`;
  },

  patch(id) {
    return `${BASE}/v2/c/partners/${id}`;
  },

  programs(partnerId) {
    return `${BASE}/programs/partner/${partnerId}`;
  },
} as const;

export default routes;
