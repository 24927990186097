import { useLocation, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { CopyToClipboardButton } from "@/components/CopyToClipboardButton";
import { Data } from "@/components/Data";
import { TitleBar } from "@/components/TitleBar";
import { TitleBarButton } from "@/components/TitleBarButton";
import { UPChip } from "@/components/UPChip";
import { INVENTORY_TYPE_MAP } from "@/constants";
import { useCatalogueItem } from "@/models/catalogue/hooks";

export default function CatalogueShow({ edit }) {
  const { _id } = useParams();
  const theme = useTheme();
  const location = useLocation();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const catalogueItem = useCatalogueItem(_id, {
    placeholderData: location.state?.item ?? null,
  });

  const title = catalogueItem.data
    ? catalogueItem.data.name
    : `Catalogue Item ${catalogueItem.status}`;

  return (
    <>
      <TitleBar backRoute={matches ? null : ".."} title={title}>
        <TitleBarButton
          onClick={() => {
            edit(catalogueItem.data);
          }}
          icon="edit"
        >
          Edit
        </TitleBarButton>
      </TitleBar>

      <Box sx={{ px: 2 }}>
        {catalogueItem.isLoading && <div>Loading...</div>}
        {catalogueItem.error && <div>Error loading Catalogue item</div>}
        <Typography
          variant="h5"
          sx={{ fontWeight: 400, lineHeight: "1.2", opacity: 0.75 }}
        >
          {catalogueItem.data?.partner?.name}
        </Typography>

        <br />

        {catalogueItem.data && (
          <Data>
            <Data.Item>
              <Data.Label>Catalogue ID:</Data.Label>
              <Data.Content sx={{ flexGrow: 1 }}>
                <code>{catalogueItem.data._id}</code>
              </Data.Content>
              <CopyToClipboardButton text={catalogueItem.data._id} />
            </Data.Item>
            <Data.Item>
              <Data.Label>Type:</Data.Label>
              <Data.Content>
                {INVENTORY_TYPE_MAP[catalogueItem.data.type] ?? ""}
              </Data.Content>
            </Data.Item>
            <Data.Item>
              <Data.Label>Status:</Data.Label>
              <Data.Content>
                <UPChip
                  color={catalogueItem.data.published ? "green" : "grey"}
                  label={
                    catalogueItem.data.published ? "published" : "unpublished"
                  }
                  size="small"
                />
              </Data.Content>
            </Data.Item>
            <Data.Item>
              <Data.Label>MSRP:</Data.Label>
              <Data.Content>${catalogueItem.data.msrp} USD</Data.Content>
            </Data.Item>
            {catalogueItem.data.endOfCourseSurveyURL ? (
              <Data.Item>
                <Data.Label>End of Course Survey URL:</Data.Label>
                <Data.Content sx={{ flexGrow: 1 }}>
                  <Link
                    href={catalogueItem.data.endOfCourseSurveyURL}
                    target="_blank"
                  >
                    {catalogueItem.data.endOfCourseSurveyURL}
                  </Link>
                </Data.Content>
                <CopyToClipboardButton
                  text={catalogueItem.data.endOfCourseSurveyURL}
                />
              </Data.Item>
            ) : null}
          </Data>
        )}
      </Box>
    </>
  );
}
