import Typography from "@mui/material/Typography";

import type { PropsWithChildren } from "react";

export const TableCaption = function TableCaption({
  children,
  title,
}: PropsWithChildren<{
  title: string;
}>) {
  return (
    <Typography variant="h5" component="h2" gutterBottom>
      {children ? children : title}
    </Typography>
  );
};
